import { CommitmentData } from '@flywl/blocks';

export const defaultCommitmentData: CommitmentData = {
  isValid: false,
  providerId:"0",
  data: {
    commitmentValue: 0,
    nativeSpend: 0,
    percentageDiscount: 0,
    percentageDrawdown: 0,
    startDate: null,
    endDate: null,
    included: false,
  },
};
