import { MetricsQueryResponse } from '@flywl/data-acess/graphql';
import { Dataset } from '../types/dataset.type';
import { calculationsFromMetricsQueryResponse } from './calculationsFromMetricsQueryResponse';

export const generateDataForChart = (data: MetricsQueryResponse): Dataset[] => {
  const dataForChart: Dataset[] = [];

  const { NumberOfItems } = calculationsFromMetricsQueryResponse(data);

  const { nttcmpBefore, ttcmpBefore, nttcmpAfter, ttcmpAfter } = NumberOfItems;

  dataForChart.push({
    nttcmp: nttcmpBefore,
    ttcmp: ttcmpBefore,
    period: 'Before',
  });

  dataForChart.push({
    nttcmp: nttcmpAfter,
    ttcmp: ttcmpAfter,
    period: 'After',
  });

  return dataForChart;
};

export function valueFormatter(value: number | null) {
  return `${value} licenses`;
}
