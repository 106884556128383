import { useState } from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';
import {
  RenewalSummaryCard,
  BarChartComponent,
  DataMetricsCard,
  MarketplaceMetrics,
  ProductChart,
} from '@flywl/blocks';
import { useGetMetrics } from './hooks/useGetMetrics';
import { ProductTable } from '../tables/product-table/product-table';
import { RenewalsTableModal } from '../tables/renewals-table/renewals-table-modal';
import { FormatType } from '@flywl/core';
import { useInventoryLineItemQuery } from '@flywl/provider';
import { date } from '@flywl/utils-data';
import { InventoryLineItemQuery_query_root_inventory_line_item_inventory_line_item } from '@flywl/data-acess/graphql';

export type MarketplaceMetricsType = {
  name?: string;
  logo?: string;
  metrics: { format: FormatType; name: string; value: string | number }[];
};

type DataCardsType = {
  name: string;
  iconName: 'cloud' | 'insights' | 'verified' | 'attachMoney';
  data: string | number;
  format?: FormatType;
};

export type BarChartComponentType = {
  series: {
    color: string;
    data: number[];
    label: string;
  }[];
  xAxisData: string[];
};

type RenewalSummaryCardType = {
  value: number;
  name: string;
};

function calculateRenewalCounts(
  items: InventoryLineItemQuery_query_root_inventory_line_item_inventory_line_item[]
) {
  return items.reduce(
    (acc, item) => {
      const renewalDate = item.renewal_date;
      const startDate = item.contract_start_date;
      if (date.isBefore(renewalDate, date.now())) {
        // Expired = renewal date < today
        acc.expired += 1;
      } else if (date.isBetween(date.addDays(-60), date.now(), startDate)) {
        // Recent = -60 days < start date > today
        acc.recent += 1;
      } else if (date.isBetween(date.now(), date.addDays(60), renewalDate)) {
        // Upcoming = renewal date < +60 days
        acc.upcoming += 1;
      }

      return acc;
    },
    { recent: 0, expired: 0, upcoming: 0 }
  );
}

export const MatchingResults = () => {
  const { data, loading, error } = useGetMetrics();
  const [openModal, setOpenModal] = useState(false);
  const { data: inventoryLineData } = useInventoryLineItemQuery();

  if (loading) {
    return (
      <Box sx={{ padding: 6, display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ padding: 6 }}>
        <Typography variant="h6" color="error">
          Error al cargar métricas: {error.message}
        </Typography>
      </Box>
    );
  }

  const totalProductsGiven =
    data?.metrics_total_licences[0]?.number_of_licenses || 0;
  const numberOfProductsMatched =
    data?.metrics_number_of_licenses_match[0]?.matched_inventory_count || 0;
  const percentageOfProductsMatched =
    data?.metrics_number_of_licenses_match[0]?.matched_inventory_percentage ||
    0;
  const totalInventoryCost = data?.metrics_total_cost[0]?.inventory_cost || 0;

  const dataCards: DataCardsType[] = [
    {
      name: 'inventory_line_item_count',
      data: totalProductsGiven,
      iconName: 'cloud',
      format: 'string',
    },
    {
      name: 'matched_inventory_count',
      data: numberOfProductsMatched,
      iconName: 'insights',
      format: 'string',
    },
    {
      name: 'matched_inventory_percentage',
      data: percentageOfProductsMatched,
      iconName: 'verified',
      format: 'percentage',
    },
    {
      name: 'inventory_cost',
      data: totalInventoryCost,
      iconName: 'attachMoney',
      format: 'currency',
    },
  ];

  const pieChartData = [
    ...(data?.metrics_cost_isv_bought_through_the_cmp_before_by_provider.map(
      (item, index) => {
        const providerColors: Record<string, string> = {
          AWS: '#cb6651',
          AZURE: '#4b8fe8',
          GCP: '#63a890',
        };

        return {
          id: index,
          value: item.number_of_items,
          label: item.provider_name,
          color: providerColors[item.provider_name.toUpperCase()] || '#000000',
        };
      }
    ) || []),
    {
      id: data?.metrics_cost_isv_bought_without_a_cmp
        ? data?.metrics_cost_isv_bought_through_the_cmp_before_by_provider
            .length
        : 0,
      value:
        data?.metrics_cost_isv_bought_without_a_cmp[0]?.number_of_items || 0,
      label: 'Non Marketplace',
      color: '#fca126',
    },
  ];

  const vendorsMatchedData =
    data?.metrics_number_of_vendors_matched.map(
      (item) => item.vendors_matched
    ) || [];
  const vendorsMatchedLabels: (string | undefined)[] =
    data?.metrics_number_of_vendors_matched.map((item) => item.provider_name) ||
    [];

  const numberOfMatchesAWS =
    data?.metrics_number_of_matches_in_aws[0]?.result || 0;
  const numberOfMatchesAzure =
    data?.metrics_number_of_matches_in_azure[0]?.result || 0;
  const numberOfMatchesGCP =
    data?.metrics_number_of_matches_in_gcp[0]?.result || 0;

  const matchesData = vendorsMatchedLabels.map((label) => {
    if (label?.toUpperCase() === 'AWS') return numberOfMatchesAWS;
    if (label?.toUpperCase() === 'AZURE') return numberOfMatchesAzure;
    if (label?.toUpperCase() === 'GCP') return numberOfMatchesGCP;
    return 0;
  });

  const barChartData: BarChartComponentType = {
    series: [
      {
        data: matchesData,
        color: '#8A8AE1',
        label: 'Products Matched',
      },
      {
        data: vendorsMatchedData,
        color: '#8AE1E1',
        label: 'Vendors (OEM) Matched',
      },
    ],
    xAxisData: vendorsMatchedLabels as string[],
  };

  const renewalCounts = calculateRenewalCounts(
    inventoryLineData?.inventory_line_item || []
  );

  const renewalsSummary: RenewalSummaryCardType[] = [
    { value: renewalCounts.recent, name: 'recent_renewals' },
    { value: renewalCounts.expired, name: 'expired_renewals' },
    { value: renewalCounts.upcoming, name: 'upcoming_renewals' },
  ];

  const marketplaceData: MarketplaceMetricsType[] | undefined =
    data?.metrics_number_of_vendors_matched
      .sort((a, b) => {
        const order = ['AWS', 'GCP', 'AZURE'];
        return (
          order.indexOf(a.provider_name as string) -
          order.indexOf(b.provider_name as string)
        );
      })
      .map((item) => {
        let percentageMatch = 0;
        let totalProduct = 0;
        if (item.provider_name === 'AWS') {
          percentageMatch = numberOfMatchesAWS / totalProductsGiven;
          totalProduct = numberOfMatchesAWS;
        } else if (item.provider_name === 'AZURE') {
          percentageMatch = numberOfMatchesAzure / totalProductsGiven;
          totalProduct = numberOfMatchesAzure;
        } else if (item.provider_name === 'GCP') {
          percentageMatch = numberOfMatchesGCP / totalProductsGiven;
          totalProduct = numberOfMatchesGCP;
        }

        return {
          name: item.provider_name || 'N/A',
          logo:
            `../../assets/${item.provider_name?.toLowerCase()}-logo.svg` || '',
          metrics: [
            {
              name: 'total_product_found',
              value: totalProduct || 0,
              format: 'int',
            },
            {
              name: 'inventory_percentage',
              value: percentageMatch * 100 || 0,
              format: 'percentage',
            },
          ],
        };
      });

  const handleRenewalsModal = () => {
    setOpenModal(true);
  };

  return (
    <Box sx={{ padding: 6 }}>
      <Typography variant="h1">Matching Results</Typography>
      <Typography variant="body1" mb={4}>
        Matching allows you to quickly see if the products you buy independently
        are available in a marketplace. This helps you make informed decisions,
        purchase through a cloud marketplace, consolidate billing, and enjoy
        savings and discounts.
      </Typography>
      <Box display={'flex'} justifyContent={'space-around'} gap={2}>
        {!loading
          ? dataCards.map((card) => (
              <Box key={card.name + loading}>
                <DataMetricsCard
                  name={card.name}
                  iconName={card.iconName}
                  data={card.data}
                  format={card.format}
                />
              </Box>
            ))
          : null}
      </Box>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        sx={{ height: 'auto', width: 'auto' }}
        gap={10}
        mb={10}
      >
        <Box sx={{ flex: 1 }}>
          <ProductChart pieChartData={pieChartData} />
        </Box>
        <Box sx={{ flex: 6 }}>
          <BarChartComponent
            series={barChartData.series}
            xAxisData={barChartData.xAxisData}
          />
        </Box>
        <Box sx={{ flex: 2 }}>
          <RenewalSummaryCard
            renewalsSummary={renewalsSummary}
            onClickRenewals={handleRenewalsModal}
          />
        </Box>
      </Box>
      <MarketplaceMetrics marketplaceData={marketplaceData || []} />
      <ProductTable />
      <RenewalsTableModal openModal={openModal} setOpenModal={setOpenModal} />
    </Box>
  );
};
