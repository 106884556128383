import React, { useState } from 'react';
import { IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { FilterList } from '@mui/icons-material';

interface ProviderFilterProps {
  value: string;
  filterOptions?: string[];
  onChange: (value: string) => void;
}
export const ProviderFilter: React.FC<ProviderFilterProps> = ({
  value,
  filterOptions,
  onChange,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (selectedValue: string) => {
    onChange(selectedValue);
    handleClose();
  };

  return (
    <>
      <IconButton color="secondary" onClick={handleClick}>
        <FilterList sx={{ marginRight: '8px' }} />
        <Typography>Filter</Typography>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {filterOptions?.map((item, index) => (
          <MenuItem
            key={item || index}
            sx={{ fontSize: '16px', padding: '8px 16px' }}
            onClick={() => handleMenuItemClick(item)}
            selected={value === item}
          >
            {item}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
