import React from 'react';
import Box from '@mui/material/Box';
import ResetPasswordSuccessfully from '../../containers/ResetPasswordSuccessfully/ResetPasswordSuccessfully'; 

const ResetPasswordSuccessfullyPage: React.FC = () => {
  return (
    <Box
      sx={{
        minHeight: '100vh',
        bgcolor: '#FAFAFA', 
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start', 
        maxWidth:'100vw'
      }}
    >
      <ResetPasswordSuccessfully   />
    </Box>
  );
};

export default ResetPasswordSuccessfullyPage;
