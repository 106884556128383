export type FormatOptions =
  | (Intl.NumberFormatOptions & { language?: string })
  | undefined;

export type FormatType =
  | 'currency'
  | 'decimal'
  | 'percentage'
  | 'date'
  | 'int'
  | 'string';

const defaultCurrencyOptions: Intl.NumberFormatOptions & { language: string } =
  {
    style: 'currency',
    currency: 'USD',
    language: 'en-US',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  };

const extractNumberFromCurrencyString = (value: string) => {
  try {
    return Number(value.replace(/[^0-9.-]+/g, ''));
  } catch (e) {
    console.error({ e, value });
    return null;
  }
};

export const currency = {
  toString: (
    value: number | string,
    options: Intl.NumberFormatOptions & {
      language?: string;
    } = defaultCurrencyOptions
  ) => {
    const { language = 'en-US', ...restOptions } = options;

    const _value: number | null =
      typeof value === 'string'
        ? extractNumberFromCurrencyString(value)
        : value;

    if (_value === null || isNaN(_value)) return '-';

    const formattedValue = new Intl.NumberFormat(language, restOptions).format(
      _value
    );

    return formattedValue.includes('$') ? formattedValue : `$${formattedValue}`;
  },
};

const defaultDecimalOptions: Intl.NumberFormatOptions = {
  minimumFractionDigits: 0,
  maximumFractionDigits: 1,
};

export const decimal = {
  toString: (
    value: number | bigint,
    options: Intl.NumberFormatOptions = defaultDecimalOptions
  ) => {
    return new Intl.NumberFormat(undefined, options).format(value);
  },
  toStringParts: (
    value: number | bigint,
    options: Intl.NumberFormatOptions = defaultDecimalOptions
  ) => {
    return new Intl.NumberFormat(undefined, options).formatToParts(value);
  },
};

export const date = {
  display: (value: string | Date) => {
    const input = new Date(value);
    const formatter = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
    return formatter.format(input);
  },
  isFuture: (target?: Date | string | null) => {
    if (!target) return false;
    const targetTime = new Date(target).getTime();
    return targetTime > Date.now();
  },
  isPast: (target?: Date | string | null) => {
    if (!target) return false;
    const targetTime = new Date(target).getTime();
    return targetTime < Date.now();
  },
  isBetween: (
    start: Date | string | null,
    end: Date | string | null,
    value: Date | string | null
  ) => {
    if (!start || !end || !value) return false;
    const startTime = new Date(start).getTime();
    const endTime = new Date(end).getTime();
    const now = new Date(value).getTime() || Date.now();
    return startTime < now && now < endTime;
  },
  inRange: (
    startDate?: string | null | Date,
    endDate?: string | null | Date
  ) => {
    const now = Date.now();
    const start = startDate ? new Date(startDate).getTime() : undefined;
    const end = endDate ? new Date(endDate).getTime() : undefined;

    if (end !== undefined && now > end) return false;
    if (start !== undefined && now < start) return false;
    return true;
  },
  now: () => {
    return new Date();
  },
  addDays: (daysAgo: number, value?: Date | string | null): Date => {
    const targetDate = value ? new Date(value) : new Date();
    targetDate.setDate(targetDate.getDate() + daysAgo);
    return targetDate;
  },
  isBefore: (
    target?: Date | string | null,
    checkDate?: Date | string | null
  ) => {
    if (!target || !checkDate) return false;
    const targetTime = new Date(target).getTime();
    const checkedDateTime = new Date(checkDate).getTime();
    return targetTime < checkedDateTime;
  },
  isAfter: (
    target?: Date | string | null,
    checkDate?: Date | string | null
  ) => {
    if (!target || !checkDate) return false;
    const targetTime = new Date(target).getTime();
    const checkedDateTime = new Date(checkDate).getTime();
    return targetTime > checkedDateTime;
  },
};

export const percentage = {
  toString: (
    value: number,
    options: Intl.NumberFormatOptions = {
      style: 'percent',
      minimumFractionDigits: 0,
      maximumFractionDigits: 1,
    }
  ) => {
    const hasDecimal = value % 1 !== 0;
    const adjustedOptions = {
      ...options,
      minimumFractionDigits: hasDecimal ? 1 : 0,
    };

    const formattedValue = new Intl.NumberFormat(
      'en-US',
      adjustedOptions
    ).format(value / 100);

    return formattedValue.includes('%')
      ? formattedValue.replace('%', ' %')
      : `${formattedValue} %`;
  },

  toStringParts: (
    value: number,
    options: Intl.NumberFormatOptions = {
      style: 'percent',
      minimumFractionDigits: 0,
      maximumFractionDigits: 1,
    }
  ) => {
    const hasDecimal = value % 1 !== 0;
    const adjustedOptions = {
      ...options,
      minimumFractionDigits: hasDecimal ? 1 : 0,
    };

    const parts = new Intl.NumberFormat('en-US', adjustedOptions).formatToParts(
      value / 100
    );

    const modifiedParts = parts.map((part) =>
      part.type === 'percentSign' ? { ...part, value: ` ${part.value}` } : part
    );
    return modifiedParts;
  },
};

const formatters: Record<
  FormatType,
  (value: string | number | Date, options?: FormatOptions) => string
> = {
  currency: (value, options) => {
    if (value instanceof Date) return '-';
    return currency.toString(value, options);
  },
  decimal: (value, options) => {
    if (value instanceof Date) return '-';
    if (typeof value === 'string') {
      const sanitizedValue = parseFloat(value.replace(/[^0-9.-]+/g, ''));
      return decimal.toString(
        isNaN(sanitizedValue) ? 0 : sanitizedValue,
        options
      );
    }
    if (typeof value === 'number') {
      return decimal.toString(value, options);
    }
    return '-';
  },
  percentage: (value, options) => {
    if (value instanceof Date) return '-';
    if (typeof value === 'string') {
      const sanitizedValue = parseFloat(value.replace(/[^0-9.-]+/g, ''));
      return percentage.toString(
        isNaN(sanitizedValue) ? 0 : sanitizedValue,
        options
      );
    }
    if (typeof value === 'number') {
      return percentage.toString(value, options);
    }
    return '-';
  },
  date: (value) => {
    if (value instanceof Date) {
      return date.display(value);
    }
    if (typeof value === 'string') {
      const parsedDate = new Date(value);
      return isNaN(parsedDate.getTime()) ? '-' : date.display(parsedDate);
    }
    return '-';
  },
  int: (value) => {
    if (value instanceof Date) return '-';
    if (typeof value === 'string') {
      const parsedInt = parseInt(value, 10);
      return isNaN(parsedInt) ? '-' : String(parsedInt);
    }
    if (typeof value === 'number') {
      return String(Math.floor(value));
    }
    return '-';
  },
  string: (value) => String(value),
};

export const formatValue = (
  value: number | string | Date | null | undefined,
  format?: FormatType,
  options?: FormatOptions
): string => {
  if (value == null) {
    return '--';
  }

  const formatter = formatters[format || 'string'];
  return formatter(value, options);
};
