import React from 'react';
import { signOut } from 'aws-amplify/auth';
import { redirect, useNavigate } from 'react-router-dom';
import { useAuthUsersQuery } from '@flywl/provider';

const Logout = () => {
  const navigate = useNavigate()
  console.warn({source: "<Logout/>"})
  const { client } = useAuthUsersQuery({skip: true});
  console.warn({source: "<Logout/> sign out"})
  signOut().then(() => {
    console.warn({source: "<Logout/> after signout"})
    client.resetStore()
  }).finally(() => {
    console.warn({source: "<Logout/> finally redirect"})
    navigate('/')
  })
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <span></span>;
};

export default Logout;
