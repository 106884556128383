import { Box, CircularProgress, Divider } from '@mui/material';
import { useQuery } from '@apollo/client';
import { Field } from '@flywl/core';
import { SAVINGS_QUERY, SavingsQueryResponse } from '@flywl/data-acess/graphql';
import Grid from '@mui/material/Grid2';

export const SavingsRow = () => {
  const { data, loading, error } =
    useQuery<SavingsQueryResponse>(SAVINGS_QUERY);

  if (loading) return <CircularProgress />;
  if (error) return <h2> ERROR</h2>;

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container direction="column">
        <Grid
          container
          size={12}
          alignContent="center"
          justifyContent="space-between"
          sx={{ height: 30 }}
        >
          <Grid size={8} alignContent="center">
            <Field.Label name="savings" variant="h5" />
          </Grid>
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              mx: 4,
              alignSelf: 'stretch',
              height: '100%',
            }}
          />
          <Grid size={3} alignContent="center">
            <Field.Value
              value={data?.metrics_savings?.[0]?.result ?? 0}
              format="currency"
              sx={{
                color: '#3AB28A',
                textAlign: 'end',
              }}
              variant="h4"
            />
          </Grid>
        </Grid>

        <Grid
          container
          size={12}
          alignContent="center"
          justifyContent="space-between"
          sx={{ height: 30 }}
        >
          <Grid size={8} alignContent="center">
            <Field.Label name="savings_percentage" variant="h5" />
          </Grid>
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              mx: 4,
              height: '100%',
              alignSelf: 'stretch',
            }}
          />
          <Grid size={3} alignContent="center">
            <Field.Value
              value={data?.metrics_savings_percentage?.[0]?.result ?? 0}
              format="percentage"
              sx={{
                color: '#3AB28A',
                textAlign: 'end',
              }}
              variant="h4"
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
