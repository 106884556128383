import React, { useEffect, useState } from 'react';
import { TopBar, Footer } from '@flywl/blocks';
import { uploadSteps } from '@flywl/copy';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Skeleton, Snackbar, SnackbarCloseReason } from '@mui/material';
import {
  useAuthUsersQuery,
  useEdpCommitmentQuery,
  useInventoryAssignmentQuery,
} from '@flywl/provider';
import { useInventoryLineItemQuery } from '@flywl/provider';
import { Alert } from '@flywl/core';
import { fields } from '@flywl/copy';

export const ApplicationLayout: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState<string>('');

  const { data: iliData, loading: iliLoading } = useInventoryLineItemQuery();

  const { data: edpData, loading: edpLoading } = useEdpCommitmentQuery();

  const { data: iaData, loading: iaLoading } = useInventoryAssignmentQuery({
    variables: {
      where: {
        origin: {
          _eq: 'UI',
        },
      },
    },
  });

  const haslineItem = !iliLoading && iliData?.inventory_line_item?.length;

  const hasEDPAssigned = !edpLoading && edpData?.edp_commitment?.length;

  const hasInventoryAssigned =
    !iaLoading && iaData?.inventory_assignment?.length;

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const { data, loading } = useAuthUsersQuery({});
  const navigate = useNavigate();
  const location = useLocation();
  const company = data?.auth_users[0].company;
  const givenName = data?.auth_users[0]?.given_name.charAt(0).toUpperCase();

  const toSlug = (title: string) => title.toLowerCase().replace(/\s+/g, '-');

  const stepsWithPaths = uploadSteps.map((step) => ({
    ...step,
    path: toSlug(step.title),
  }));

  const [currentStep, setCurrentStep] = useState(1);

  useEffect(() => {
    const currentPath = location.pathname;
    const pathParts = currentPath.split('/');
    const stepPath =
      pathParts[pathParts.length - 1] || toSlug(uploadSteps[0].title);

    const stepIndex = stepsWithPaths.findIndex(
      (step) => step.path === stepPath
    );
    if (stepIndex !== -1) {
      setCurrentStep(stepIndex + 1);
    } else {
      setCurrentStep(1);
    }
  }, [location.pathname, stepsWithPaths]);

  const handleNextClick = () => {
    if (currentStep < stepsWithPaths.length) {
      const nextStep = stepsWithPaths[currentStep];

      if (currentStep === 1) {
        if (haslineItem) {
          navigate(`/compass/${nextStep.path}`);
        } else {
          const labelData = fields['upload_validation'];
          const message = labelData?.tooltip || '';
          setMessage(message);
          setOpen(true);
          return;
        }
      }
      // Step 3 - EDP and inventory assignment check
      else if (currentStep === 3) {
        if (hasEDPAssigned && hasInventoryAssigned) {
          navigate(`/compass/${nextStep.path}`);
        } else {
          const labelData = fields['edp_validation'];
          const message = labelData?.tooltip || '';
          setMessage(message);
          setOpen(true);
          return;
        }
      } else {
        navigate(`/compass/${nextStep.path}`);
      }
    }
  };

  const handleBackClick = () => {
    if (currentStep > 1) {
      const prevStep = stepsWithPaths[currentStep - 2];
      navigate(`/compass/${prevStep.path}`);
    }
  };

  const handleLogout = () => {
    navigate('/logout');
  };
  return (
    <Box
      sx={{
        backgroundColor: '#FAFAFA',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <TopBar
        steps={uploadSteps}
        currentStep={currentStep}
        company={loading ? <Skeleton /> : company}
        givenName={loading ? <Skeleton /> : givenName}
        logOut={handleLogout}
      />
      <Box
        id="application-layout-content-container"
        sx={{
          flex: 1,
          padding: 4,
          paddingTop: '115px',
          paddingBottom: '80px',
          overflowY: 'auto',
          maxWidth: '100vw',
        }}
      >
        {children}
      </Box>

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        sx={{ marginBottom: 13, marginRight: 4 }}
      >
        <Alert
          severity="warning"
          sx={{
            width: '30rem',
          }}
          message={message}
        />
      </Snackbar>

      <Footer
        onNext={handleNextClick}
        onBack={handleBackClick}
        currentStep={currentStep}
        totalSteps={uploadSteps.length}
      />
    </Box>
  );
};
