import React from 'react';
import { TableBody, TableCell, TableRow } from '@mui/material';
import { ProductType, PROVIDER_COLORS } from './types/product-table.types';
import { Field } from '@flywl/core';
import flywlgraylogo from '../../assets/logo-flywl-gray.svg';

type TableBodyComponentProps = {
  data: ProductType[];
  page: number;
  rowsPerPage: number;
};

export const TableBodyComponent: React.FC<TableBodyComponentProps> = ({
  data,
  page,
  rowsPerPage,
}) => {
  return (
    <TableBody>
      {data
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row, index) => (
          <TableRow key={row?.id || index}>
            <TableCell
              sx={{ display: 'flex', fontWeight: 'normal', fontSize: '14px' }}
            >
              <img
                src={typeof row.icon === 'string' ? row.icon : flywlgraylogo}
                alt={row.product || 'Default logo'}
                style={{ width: '24px', height: '24px' }}
              />
              <span style={{ marginLeft: '8px' }}>{row.product}</span>
            </TableCell>

            <TableCell
              sx={{ paddingY: 2, fontWeight: 'normal', fontSize: '14px' }}
            >
              {row.vendor}
            </TableCell>

            {row?.tags && (
              <TableCell
                sx={{ paddingY: 2, fontWeight: 'normal', fontSize: '14px' }}
              >
                {row?.tags?.map((tag, idx) => (
                  <span
                    key={idx}
                    style={{
                      display: 'inline',
                      backgroundColor:
                        tag.colorType === PROVIDER_COLORS.AWS
                          ? '#EBF7F3'
                          : tag.colorType === PROVIDER_COLORS.AZURE
                          ? '#E6F3F9'
                          : tag.colorType === PROVIDER_COLORS.GCP
                          ? '#F8E6E6'
                          : '#d3d3d3',
                      color:
                        tag.colorType === PROVIDER_COLORS.AWS
                          ? '#297E62'
                          : tag.colorType === PROVIDER_COLORS.AZURE
                          ? '#0186C0'
                          : tag.colorType === PROVIDER_COLORS.GCP
                          ? '#BD0000'
                          : '#000',
                      borderRadius: '12px',
                      padding: '4px 8px',
                      marginRight: '8px',
                      fontSize: '12px',
                      fontWeight: 'normal',
                    }}
                  >
                    {tag.name}
                  </span>
                ))}
              </TableCell>
            )}

            {row.cost && (
              <TableCell
                sx={{ paddingY: 2, fontWeight: 'normal', fontSize: '14px' }}
              >
                <Field.Value value={row.cost} format="currency" />
              </TableCell>
            )}
          </TableRow>
        ))}
    </TableBody>
  );
};
