import React, { useState } from 'react';
import {
  MetricsEnterpriseCommitmentModule,
  EnterpriseCommitEdit,
  InventoryAssignment,
  ProductAssignment,
} from '@flywl/modules';
import { Box, Typography } from '@mui/material';

export const Opportunities: React.FC = () => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isInventoryModalOpen, setIsInventoryModalOpen] = useState(false);
  const [selected, setSelected] = useState<number>(0);

  const handleEditEnterpriseCommitment = () => {
    console.log('Edit Enterprise Commitment triggered');
    setIsEditModalOpen(true);
  };



  const handleCloseModals = () => {
    setIsEditModalOpen(false);
    setIsInventoryModalOpen(false);
  };

  return (
    <Box sx={{ padding: "10px 40px 0px 40px" }}>
      <Typography variant="h1">Opportunities</Typography>
      <Typography variant="body1" mb={4}>
        Here you have the ability to move your products to the best Cloud
        marketplace option.
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          gap: 4,
        }}
      >
        <MetricsEnterpriseCommitmentModule
          title="Enterprise Commitment Metrics"
          handleEdit={handleEditEnterpriseCommitment}
        />

        <EnterpriseCommitEdit
          open={isEditModalOpen}
          onClose={handleCloseModals}
        />

        <Box sx={{ width: '100%' }}>


          <ProductAssignment />
        </Box>
      </Box>
    </Box>
  );
};
