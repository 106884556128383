import { ProvidersData } from '@flywl/data-acess/graphql';
import { InventoryAssignmentInput, InventoryFile } from '../types/InventoryFile.type';

const NAME_SUFFIXES = ['INC', 'LLC', 'BIZ', 'CORP'];

export function cleanInventoryFile(
  file: InventoryFile[],
  providers: ProvidersData
): InventoryAssignmentInput[] {
  const uniqueRows = file.map((row) => cleanInventoryRow(row, providers));

  return uniqueRows;
}

function cleanInventoryRow(file: InventoryFile, providers: ProvidersData) {
  const cloud_provider = foundProviderId(
    normalizeCloudProvider(cleanAndCapitalize(file.cloud_provider)),
    providers
  )

  let finalCost = null

  const cost = cleanCost(file.cost);
  
  if (  cost !== null) {
    if (cost > 0) {
      finalCost = cost;
    }
  }
  
  const defaultDate = (new Date()).toISOString().split('T')[0]
  const result: InventoryAssignmentInput = {
    origin: 'IMPORT',
    provider_id: cloud_provider,
    date_assigned: normalizeDate(file.contract_start_date) || defaultDate,
    
    inventory_line_item: {
      data: {
        cost: finalCost,
        contract_start_date: normalizeDate(file.contract_start_date) ,
        renewal_date: normalizeDate(file.renewal_date),
        cloud_provider,
        vendor: cleanAndCapitalize(file.vendor),
        solution_name: cleanAndCapitalize(file.solution_name),
      },
      on_conflict: { constraint: "inventory_line_item_unique", update_columns: ["cost", "contract_start_date", "renewal_date", "cloud_provider", "vendor", "solution_name"] }
    }
  };
  return result;
}

function cleanAndCapitalize(field: string): string {
  if (!field) return '';

  let cleanedField = field
    .trim()
    .replace(/[^a-zA-Z0-9'\s]/g, ' ')
    .replace(/\s+/g, ' ')
    .trim();

  const suffixPattern = new RegExp(
    `\\s+(${NAME_SUFFIXES.join('|')})\\s*$`,
    'i'
  );
  cleanedField = cleanedField.replace(suffixPattern, '');

  return cleanedField
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}

export function normalizeDate(field: string | null): string | null {
  if (field === null || field === '' || field === undefined) return null;

  // Regular expression to identify valid date formats
  const dateRegex = /^(\d{4}[-/](0[1-9]|1[0-2])[-/](0[1-9]|[12]\d|3[01]))|((0[1-9]|1[0-2])[-/](0[1-9]|[12]\d|3[01])[-/](\d{4}|\d{2}))|((\d{1,2})[-/](\d{1,2})[-/](\d{4}|\d{2}))$/;

  const dateField = field.trim();

  const match = dateField.match(dateRegex);
  if (!match) return null;

  let year = '';
  let month = '';
  let day = '';

  // Ensure the date parts (month and day) have two digits
  const normalizeDateParts = (part: string) => part.padStart(2, '0');

  if (dateField.includes('-') || dateField.includes('/')) {
    const parts = dateField.split(/[-/]/);

    if (parts[0].length === 4) {
      // Format: YYYY-MM-DD or YYYY/MM/DD
      year = parts[0];
      month = normalizeDateParts(parts[1]);
      day = normalizeDateParts(parts[2]);
    } else {
      // Format: MM-DD-YYYY or MM/DD/YYYY
      month = normalizeDateParts(parts[0]);
      day = normalizeDateParts(parts[1]);
      year = parts[2];
    }
  }

  // If the year is only 2 digits and less than 100, consider it as 2023
  if (year.length === 2 && parseInt(year) < 100) {
    year = '2023';
  }

  // Validate that the year, month, and day are correct
  const monthInt = parseInt(month);
  const dayInt = parseInt(day);

  // Validate that the month and day are not "00"
  if (!year || !month || !day || monthInt < 1 || monthInt > 12 || dayInt < 1 || dayInt > 31 || month === '00' || day === '00') {
    return null;
  }

  // Validate that the days are correct for the specific month
  if (
    (monthInt === 2 && dayInt > 29) ||
    ([4, 6, 9, 11].includes(monthInt) && dayInt > 30) ||
    (monthInt !== 2 && dayInt > 31)
  ) {
    return null;
  }

  // Return the date in 'YYYY-MM-DD' format
  return `${year}-${month}-${day}`;
}


export function normalizeCloudProvider(provider: string): string {

  if (/([._\- ]+|^)(aws|amazonwebservices|amazon|amzn)([._\- ]+|$)/i.test(provider)) {
    return 'AWS';
  } else if (
    /([._\- ]+|^)(gcp|googlecloudplatform|googlecloud|google|goog|alphabet)([._\- ]+|$)/i.test(provider)
  ) {
    return 'GCP';
  } else if (/([._\- ]+|^)(azure|microsoftazure|azurecloud|azuremp|microsoft|msft)([._\- ]+|$)/i.test(provider)) {
    return 'AZURE';
  }

  return provider;
}

function foundProviderId(name: string, providers: ProvidersData): string {
  const IVSid = providers.provider.find(
    (provider) => provider.name === 'ISV'
  )?.id;
  const provider = providers.provider.find(
    (provider) => provider.name === name
  );
  return provider?.id || IVSid || '';
}

const getDuplicatesInfo = (
  cleaned: InventoryFile[]
): {
  uniqueRows: InventoryFile[];
  duplicatesCount: number;
} => {
  const occurrences = new Map<string, number>();

  cleaned.forEach((row: InventoryFile) => {
    const key = `${row.cloud_provider}_${row.solution_name}_${row.vendor}`;
    occurrences.set(key, (occurrences.get(key) || 0) + 1);
  });

  const duplicatesCount = Array.from(occurrences.values()).filter(
    (count) => count > 1
  ).length;

  const uniqueRows = Array.from(
    new Map(
      cleaned
        .reverse()
        .map((row: InventoryFile) => [
          `${row.cloud_provider}_${row.solution_name}_${row.vendor}`,
          row,
        ])
    ).values()
  ).reverse();

  return {
    uniqueRows,
    duplicatesCount,
  };
};


// I made this function with claude.ai and i rather to keep the comments just in case
export const cleanCost = (cost: string | null): number | null => {
  if (!cost) return null;
  
  // Remove spaces, currency symbols, and any surrounding whitespace
  const cleaned = cost.trim().replace(/\s/g, '').replace(/[$€£]/g, '');
  
  // Return null for empty string after cleaning
  if (!cleaned) return null;

  // Helper function to count occurrences of a character
  const countChar = (str: string, char: string): number => 
    (str.match(new RegExp(char, 'g')) || []).length;

  const dotCount = countChar(cleaned, '\\.');
  const commaCount = countChar(cleaned, ',');

  try {
    // Extract sign if present
    const isNegative = cleaned.startsWith('-');
    const numberPart = isNegative ? cleaned.substring(1) : cleaned;

    // Case 1: No separators - simple number (123 or -123)
    if (dotCount === 0 && commaCount === 0) {
      const result = parseFloat(cleaned);
      return isNaN(result) ? null : result;
    }

    // Case 2: Single separator - could be decimal point in either format
    if (dotCount + commaCount === 1) {
      // American format (123.45)
      if (dotCount === 1) {
        const result = parseFloat(cleaned);
        return isNaN(result) ? null : result;
      }
      // European format (123,45)
      if (commaCount === 1) {
        const result = parseFloat(cleaned.replace(',', '.'));
        return isNaN(result) ? null : result;
      }
    }

    // Case 3: Multiple separators
    // Check the last separator to determine format
    const lastDotIndex = numberPart.lastIndexOf('.');
    const lastCommaIndex = numberPart.lastIndexOf(',');

    let result: number | null = null;

    // American format (1,234,567.89) - last separator is dot
    if (lastDotIndex > lastCommaIndex) {
      // Verify the remaining structure (commas every 3 digits)
      const parts = numberPart.split('.');
      if (parts.length !== 2) return null;
      
      const integerPart = parts[0].replace(/,/g, '');
      // Check if the number is valid after removing commas
      if (!/^\d+$/.test(integerPart)) return null;
      
      result = parseFloat(integerPart + '.' + parts[1]);
    }
    // European format (1.234.567,89) - last separator is comma
    else if (lastCommaIndex > lastDotIndex) {
      // Verify the remaining structure (dots every 3 digits)
      const parts = numberPart.split(',');
      if (parts.length !== 2) return null;
      
      const integerPart = parts[0].replace(/\./g, '');
      // Check if the number is valid after removing dots
      if (!/^\d+$/.test(integerPart)) return null;
      
      result = parseFloat(integerPart + '.' + parts[1]);
    }

    if (result === null || isNaN(result)) return null;
    return isNegative ? -result : result;

  } catch (error) {
    return null;
  }
};
export function ignoreDateValidations(
  validData: InventoryFile[],
  invalidData: InventoryFile[],
): InventoryFile[] {



  invalidData.forEach((row: InventoryFile) => {
    if (row.vendor 
      && row.vendor.trim() !== ""
      && row.solution_name 
      && row.solution_name.trim() !== ""
      ) {
      validData.push(row);
    }
  });

  return validData;
}

