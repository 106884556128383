import { BarChart } from '@mui/x-charts/BarChart';
import { ISV_BEFORE_AND_AFTER_QUERY } from '@flywl/data-acess/graphql';
import { useQuery } from '@apollo/client';
import { Box, CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { Dataset } from '../types/dataset.type';
import {
  generateDataForChart,
  valueFormatter,
} from '../utils/generataDataForChart';

export const ChartBarFromDelta = () => {
  const [calculated, setCalculated] = useState(false);
  const [dataset, setDataset] = useState<Dataset[]>([]);

  const {
    data: isv_before_data,
    loading: isv_loading,
    error,
  } = useQuery(ISV_BEFORE_AND_AFTER_QUERY);

  useEffect(() => {
    if (!isv_loading && !error && isv_before_data) {
      const processedData = generateDataForChart(isv_before_data);
      setDataset(processedData);
      setCalculated(true);
    }
  }, [error, isv_before_data, isv_loading]);

  if (error) return <h2>ERROR</h2>;
  if (!calculated) return <CircularProgress />;

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <BarChart
        dataset={isv_before_data ? dataset : []}
        slotProps={{
          legend: {
            itemGap: 20, 
            direction: 'row',
            position: {vertical: 'top', horizontal: 'middle'}
          },
        }}
        xAxis={[{ scaleType: 'band', dataKey: 'period' }]}
        series={[
          {
            dataKey: 'nttcmp',
            label: 'Not Transacted Through CMP',
            valueFormatter,
            color: '#C38274',
          },
          {
            dataKey: 'ttcmp',
            label: 'Transacted Through CMP',
            valueFormatter,
            color: '#FAAB50',
          },
        ]}
        margin={{ left: 50, right: 50, top: 80, bottom: 25 }}
        height={216}
      />
    </Box>
  );
};
