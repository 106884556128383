import React from 'react';
import {
  Accordion as MuiAccordion,
  AccordionSummary,
  AccordionDetails,
  AccordionProps as MuiAccordionProps,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export interface AccordionItem {
  id: string;
  summary: React.ReactNode;
  details: React.ReactNode;
}

export interface CustomAccordionProps {
  items: AccordionItem[];
  defaultExpanded?: string[];
  onChange?: (id: string, expanded: boolean) => void;
  accordionProps?: MuiAccordionProps;
}

export const Accordion: React.FC<CustomAccordionProps> = ({
  items,
  defaultExpanded = [],
  onChange,
  accordionProps,
}) => {
  const handleChange =
    (id: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      if (onChange) {
        onChange(id, isExpanded);
      }
    };

  return (
    <div>
      {items.map((item) => (
        <MuiAccordion
          key={item.id}
          defaultExpanded={defaultExpanded.includes(item.id)}
          onChange={handleChange(item.id)}
          {...accordionProps}
          sx={{
            border: 'none',
            width: '100%',
            boxShadow: 'none',
            '&:before': {
              display: 'none',
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${item.id}-content`}
            id={`${item.id}-header`}
          >
            <Typography variant="h4">{item.summary}</Typography>
          </AccordionSummary>
          <AccordionDetails>{item.details}</AccordionDetails>
        </MuiAccordion>
      ))}
    </div>
  );
};
