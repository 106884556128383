import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { MetricsEnterpriseCommitment } from '@flywl/blocks';
import { Card, Divider } from '@flywl/core';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { useGetMetricsEnterpriseCommitment } from './hooks/useGetMetricsEnterpriseCommitment';
import { generateMetricsEnterpriseCommitment } from './utils/MetricsEnterpriseCommitmentFunctions';

interface EnterpriseCommitmentModuleProps {
  title: string;
  handleEdit: () => void;
}

export const MetricsEnterpriseCommitmentModule: React.FC<
  EnterpriseCommitmentModuleProps
> = ({ title, handleEdit }) => {
  const { data, loading } = useGetMetricsEnterpriseCommitment();
  const marketplaces = data ? generateMetricsEnterpriseCommitment(data) : [];

  return (
    <Card
      sx={{
        borderColor: '#D7DED7',
        padding: '24px',
        maxWidth: '344px',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h4">{title}</Typography>
        <IconButton
          color="secondary"
          sx={{ display: 'flex', gap: 1 }}
          onClick={handleEdit}
        >
          <CreateOutlinedIcon />
          <Typography variant="body1">Edit</Typography>
        </IconButton>
      </Box>
      {marketplaces.map((marketplace, index) => (
        <React.Fragment key={index}>
          <MetricsEnterpriseCommitment {...marketplace} loading={loading} />
          {index < marketplaces.length - 1 && <Divider />}
        </React.Fragment>
      ))}
    </Card>
  );
};
