import React, { useState, useEffect } from 'react';
import { Input } from '@flywl/core';
import { Box, FormControlLabel, SvgIconProps, Switch } from '@mui/material';
import { enterpriseCommit as TEXT_COPIES } from '@flywl/copy';
import { TitleBox } from './TitleBox';
import { CommitmentData } from './EnterpriseCommit';
import {
  FieldName,
  getError,
  isFormValid,
  TouchedFields,
} from './validators/getError';

export interface EnterpriseCommitBaseProps {
  providerLogo: string | React.ComponentType<SvgIconProps>;
  providerName: string;
  data: CommitmentData;
  onChange: (data: Partial<CommitmentData>) => void;
  included: boolean;
}

const errorStyle = {
  '& .MuiFormHelperText-root': {
    fontSize: '0.7rem',
    padding: 0,
    lineHeight: 1,
    zIndex: 2,
  },
};

export const Base: React.FC<EnterpriseCommitBaseProps> = ({
  providerLogo,
  providerName,
  data,
  onChange,
  included,
}) => {
  const [touchedFields, setTouchedFields] = useState<TouchedFields>({
    commitmentValue: false,
    nativeSpend: false,
    // percentageDiscount: false,
    percentageDrawdown: false,
    startDate: false,
    endDate: false,
  });

  useEffect(() => {
    const formValid = isFormValid(data.data, touchedFields);
    if (formValid !== data.isValid) {
      onChange({
        ...data,
        isValid: formValid,
      });
    }
  }, [data, onChange, touchedFields]);

  const handleValueChange = (fieldName: FieldName, value: unknown) => {
    let processedValue = value;

    if (typeof value === 'string') {
      processedValue = isFinite(Number(value)) ? Number(value) : 0;
    }

    const newData = {
      data: {
        ...data.data,
        [fieldName]: processedValue,
      },
    };

    const formValid = isFormValid(newData.data, touchedFields);

    onChange({
      ...newData,
      isValid: formValid,
    });
  };

  const handleBlur = (fieldName: FieldName) => {
    const newTouchedFields = {
      ...touchedFields,
      [fieldName]: true,
    };
    setTouchedFields(newTouchedFields);
  };

  const getFieldError = (fieldName: FieldName) =>
    getError(fieldName, data.data, touchedFields);

  return (
    <Box
      sx={{
        display: 'flex',
        padding: '1.5rem',
        flexDirection: 'column',
        paddingBottom: '2rem',
        gap: '2rem',
        borderRadius: '0.75rem',
        border: '1px solid #BEBDBE',
        flex: '1 0 0',
      }}
    >
      <Box display={'flex'} gap={'2rem'}>
        <TitleBox icon={providerLogo} title={providerName} />
      </Box>

      <Input.Currency
        error={getFieldError('commitmentValue').hasError}
        errorMessage={getFieldError('commitmentValue').message}
        name={TEXT_COPIES.enterpriseCommitment}
        label={TEXT_COPIES.enterpriseCommitment}
        value={data.data.commitmentValue}
        sx={errorStyle}
        onChange={(value) => handleValueChange('commitmentValue', value)}
        onBlur={() => handleBlur('commitmentValue')}
        disabled={!included}
      />

      <Input.Currency
        error={getFieldError('nativeSpend').hasError}
        errorMessage={getFieldError('nativeSpend').message}
        name={TEXT_COPIES.nativeSpend}
        label={TEXT_COPIES.nativeSpend}
        value={data.data.nativeSpend}
        sx={errorStyle}
        onChange={(value) => handleValueChange('nativeSpend', value)}
        onBlur={() => handleBlur('nativeSpend')}
        disabled={!included}
      />

        {/* <Input.Percentage
          error={getFieldError('percentageDiscount').hasError}
          errorMessage={getFieldError('percentageDiscount').message}
          name={TEXT_COPIES.commitmentDiscount}
          label={TEXT_COPIES.commitmentDiscount}
          value={data.data.percentageDiscount}
          onChange={(value) => handleValueChange('percentageDiscount', value)}
          onBlur={() => handleBlur('percentageDiscount')}
          disabled={!included}
          /> */}

          <Box display={'flex'} gap={'2rem'} sx={{width:"48%"}}>
        <Input.Percentage
          error={getFieldError('percentageDrawdown').hasError}
          errorMessage={getFieldError('percentageDrawdown').message}
          name={TEXT_COPIES.drawdown}
          label={TEXT_COPIES.drawdown}
          value={data.data.percentageDrawdown}
          onChange={(value) => handleValueChange('percentageDrawdown', value)}
          onBlur={() => handleBlur('percentageDrawdown')}
          disabled={!included}
        />
      </Box>

      <Box display={'flex'} gap={'2rem'}>
        <Input.Date
          error={getFieldError('startDate').hasError}
          errorMessage={getFieldError('startDate').message}
          label={TEXT_COPIES.startDate}
          value={data.data.startDate}
          sx={errorStyle}
          onChange={(value) => handleValueChange('startDate', value)}
          onBlur={() => handleBlur('startDate')}
          disabled={!included}
        />

        <Input.Date
          error={getFieldError('endDate').hasError}
          errorMessage={getFieldError('endDate').message}
          label={TEXT_COPIES.endDate}
          value={data.data.endDate}
          sx={errorStyle}
          onChange={(value) => handleValueChange('endDate', value)}
          onBlur={() => handleBlur('endDate')}
          disabled={!included}
        />
      </Box>

      <FormControlLabel
        control={
          <Switch
            color="secondary"
            checked={data.data.included}
            onChange={(event) => {
              const newData = {
                data: {
                  ...data.data,
                  included: event.target.checked,
                },
              };

              const formValid = isFormValid(newData.data, touchedFields);

              onChange({
                ...newData,
                isValid: formValid,
              });
            }}
          />
        }
        label={`${TEXT_COPIES.enable1} ${providerName} ${TEXT_COPIES.enable2}`}
      />
    </Box>
  );
};
