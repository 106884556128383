import React, { useState } from 'react';
import { AuthCard } from '@flywl/blocks';
import { Button } from '@flywl/core';
import { useNavigate } from 'react-router-dom';
import { TextInputField } from '@flywl/core';
import { Box } from '@mui/material';
import { useAuth } from '@flywl/provider';

const ForgotPasswordContainer: React.FC = () => {
  const [formData, setFormData] = useState<{ email: string }>({ email: '' });
  const [errors, setErrors] = useState<{ email?: string }>({});
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { resetPassword } = useAuth();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    if (errors[name as keyof typeof errors]) {
      setErrors((prev) => {
        const newErrors = { ...prev };
        delete newErrors[name as keyof typeof errors];
        return newErrors;
      });
    }
  };

  const validateFields = (): boolean => {
    const newErrors: { email?: string } = {};
    let valid = true;

    if (!formData.email) {
      newErrors.email = 'Email is required';
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Invalid email address';
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = async () => {
    if (!validateFields()) return;

    setLoading(true);
    try {
      await resetPassword(formData.email);
      // Navigate to the OTP entry step, passing the email as state
      navigate('/reset-password', { state: { email: formData.email } });
    } catch (error) {
      console.error('Error resetting password:', error);
      if (error instanceof Error && 'code' in error) {
        if (error.code === 'UserNotFoundException') {
          setErrors({ email: 'No account found with this email.' });
        } else {
          setErrors({
            email: 'Failed to reset password. Please try again later.',
          });
        }
      } else {
        setErrors({
          email:
            "That email address doesn't match any user accounts. Are you sure you've registered?",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthCard
      title="Reset Your Password"
      subtitle="Enter your email, and we’ll send a verification code if it matches an existing Flywl account to recover your password."
      showBackButton={false}
      titleVariant="h2"
    >
      <Box>
        <TextInputField
          name="email"
          label="Email"
          value={formData.email}
          error={!!errors.email}
          helperText={errors.email}
          onChange={handleChange}
        />

        <Button
          fullWidth
          variant="contained"
          onClick={handleSubmit}
          disabled={loading}
          sx={{ mt: 2, mb: 2, bgcolor: '#963F2B', color: '#F6FAF6' }}
        >
          {loading ? 'Sending...' : 'Send'}
        </Button>
      </Box>
    </AuthCard>
  );
};

export default ForgotPasswordContainer;
