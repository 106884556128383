import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthCard } from '@flywl/blocks';
import { createAccount } from '@flywl/copy';
import { Button } from '@flywl/core';
import { PasswordRequirements } from '@flywl/core';
import { TermsContent } from '@flywl/core';
import { BottomLink } from '@flywl/core';
import { CheckboxField } from '@flywl/core';
import { RadioField } from '@flywl/core';
import { PasswordField } from '@flywl/core';
import { TextInputField } from '@flywl/core';
import { useAuth, getSession, AuthUsersDocument } from '@flywl/provider';
import { useApolloClient, gql } from '@apollo/client';

const UPDATE_USER_DATA = gql`
  mutation updateUser(
    $family_name: String!
    $client_type: String!
    $company: String!
    $terms_accepted: Boolean!
    $user_id: String!
    $given_name: String!
  ) {
    update_auth_users(
      where: { user_id: { _eq: $user_id } }
      _set: {
        client_type: $client_type
        company: $company
        family_name: $family_name
        given_name: $given_name
        terms_accepted: $terms_accepted
      }
    ) {
      affected_rows
    }
  }
`;

interface ValidationMessages {
  length?: string;
  uppercase?: string;
  lowercase?: string;
  number?: string;
  specialChar?: string;
}

interface FormField {
  label?: string;
  name?: string;
  type?: string;
  options?: [];
  content?: string | string[];
  errorMessage?: string;
  validationMessages?: ValidationMessages;
  requirementsTitle?: string;
  mismatchErrorMessage?: string;
  text?: string;
  linkText?: string;
  linkUrl?: string;
}

interface FormStep {
  title: string;
  buttonText: string;
  showBackButton: boolean;
  formFields: FormField[];
  subtitle?: string;
  pharagraph?: string;
}

interface FormCopy {
  messages: { [key: string]: string };
  steps: FormStep[];
}

const AuthContainer: React.FC = () => {
  const client = useApolloClient();
  const [timeLeft, setTimeLeft] = useState(300);
  const [formData, setFormData] = useState<{
    [step: number]: { [key: string]: string | boolean };
  }>({});
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [showPassword, setShowPassword] = useState<{ [key: string]: boolean }>(
    {}
  );

  const email = (formData[0]?.companyEmail as string) || '';

  const navigate = useNavigate();
  const { messages, steps } = createAccount as unknown as FormCopy;

  const { signUp, signIn, confirmSignUp, resendCode, updateUserAttributes } =
    useAuth();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;

    if (!name) return;

    setFormData((prevState) => ({
      ...prevState,
      [currentStep]: {
        ...prevState[currentStep],
        [name]: type === 'checkbox' ? checked : value,
      },
    }));

    if (errors[name]) {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[name];
        return newErrors;
      });
    }
  };

  const validateFields = (externalErrors?: {
    [key: string]: string;
  }): boolean => {
    const currentForm = steps[currentStep];
    let valid = true;
    const newErrors: { [key: string]: string } = {};
    const emailRegex =
      /^(?!.*\.\.)(?!.*@@)(?!.*\s)(?!.*[#])(?!.*@$)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    currentForm.formFields.forEach((field) => {
      const value = field.name
        ? formData[currentStep]?.[field.name]
        : undefined;

      if (field.name === 'companyEmail') {
        if (!value || typeof value !== 'string') {
          newErrors[field.name] =
            field.errorMessage || 'Company email is required';
          valid = false;
        } else if (!emailRegex.test(value.trim())) {
          newErrors[field.name] = 'Invalid email address';
          valid = false;
        }
      }

      if (field.name === 'confirmPassword') {
        const password = formData[currentStep]?.password as string;
        if (value !== password) {
          newErrors[field.name] =
            field.mismatchErrorMessage || 'Passwords do not match';
          valid = false;
        }
      }

      if (!value && field.name && !newErrors[field.name]) {
        newErrors[field.name] =
          field.errorMessage || `${field.label || 'This field'} is required`;
        valid = false;
      }
    });

    if (externalErrors) {
      Object.assign(newErrors, externalErrors);
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = async () => {
    if (validateFields()) {
      if (currentStep === 0) {
        setCurrentStep(1);
      } else if (currentStep === 1) {
        try {
          const { companyEmail } = formData[0] as { companyEmail: string };
          const { password } = formData[1] as { password: string };
          await signUp({ email: companyEmail, password });
          setCurrentStep(2);
        } catch (error) {
          console.error('Error:', error);
          const externalErrors: { [key: string]: string } = {};

          if (error instanceof Error) {
            const errorCode = 'code' in error ? error.code : error.name;
            const errorMessage = error.message || '';

            switch (errorCode) {
              case 'UsernameExistsException':
                externalErrors['confirmPassword'] = 'This email already exist';
                break;
              case 'InvalidPasswordException':
                externalErrors['password'] = 'Wrong password';
                break;
              case 'InvalidParameterException':
                externalErrors['confirmPassword'] = errorMessage;
                break;
              default:
                externalErrors['confirmPassword'] = errorMessage;
                break;
            }
          } else {
            externalErrors['confirmPassword'] = 'Unexpected error';
          }

          validateFields(externalErrors);
        }
      } else if (currentStep === 2) {
        try {
          const { companyEmail } = formData[0] as { companyEmail: string };
          const { verificationCode } = formData[2] as {
            verificationCode: string;
          };

          await confirmSignUp(companyEmail, verificationCode);

          setCurrentStep(3);
        } catch (error) {
          const externalErrors: { [key: string]: string } = {};

          if (error instanceof Error) {
            const errorCode = 'code' in error ? error.code : error.name;

            const errorMessage = error.message || '';

            switch (errorCode) {
              case 'CodeMismatchException':
                externalErrors['verificationCode'] =
                  'Incorrect code. Please try again or request a new one.';
                break;
              case 'ExpiredCodeException':
                externalErrors['verificationCode'] =
                  'Code expired. Please request a new one.';
                break;
              case 'UserNotFoundException':
                externalErrors['verificationCode'] = 'User dosent exist.';
                break;
              default:
                externalErrors['verificationCode'] =
                  errorMessage || 'Error, try again';
                break;
            }
          } else {
            externalErrors['verificationCode'] = 'Unexpected error';
          }

          validateFields(externalErrors);
        }
      } else if (currentStep === 3) {
        setCurrentStep(4);
      } else if (currentStep === 4) {
        setCurrentStep(5);
      } else if (currentStep === 5) {
        try {
          const { companyEmail } = formData[0] as { companyEmail: string };
          const { password } = formData[1] as { password: string };
          const { firstName, family_name } = formData[3] as {
            firstName: string;
            family_name: string;
          };
          const { company, companyType } = formData[4] as {
            company: string;
            companyType: string;
          };
          const { termsAccepted } = formData[5] as { termsAccepted: string };

          await signIn(companyEmail, password);

          const { userSub } = getSession();

          await updateUserAttributes({
            email: `${companyEmail}`,
            given_name: `${firstName}`,
            family_name: `${family_name}`,
            'custom:company': `${company}`,
            'custom:companyType': `${companyType}`,
            'custom:termsAccepted': termsAccepted,
          });

          await client.mutate({
            mutation: UPDATE_USER_DATA,
            variables: {
              user_id: userSub,
              client_type: `${companyType}`,
              company: `${company}`,
              family_name: `${family_name}`,
              given_name: `${firstName}`,
              terms_accepted: termsAccepted,
            },
            refetchQueries: [{ query: AuthUsersDocument }],

          });

          navigate('/user-status');
        } catch (error) {
          console.error('Error during final data submission:', error);

          const externalErrors: { [key: string]: string } = {};

          if (error instanceof Error) {
            const errorCode = 'code' in error ? error.code : error.name;
            const errorMessage = error.message || '';

            switch (errorCode) {
              case 'UserNotFoundException':
                externalErrors['companyEmail'] = 'Email address not found.';
                break;
              case 'NotAuthorizedException':
                externalErrors['password'] =
                  'Incorrect password. Please try again.';
                break;
              case 'UserNotConfirmedException':
                externalErrors['companyEmail'] =
                  'Your account is not confirmed.';
                break;
              case 'InvalidParameterException':
                externalErrors['companyEmail'] = errorMessage;
                break;
              default:
                externalErrors['general'] =
                  errorMessage ||
                  'An unexpected error occurred. Please try again.';
                break;
            }
          } else {
            externalErrors['general'] =
              'An unexpected error occurred. Please try again.';
          }
          validateFields(externalErrors);
        }
      }
    }
  };

  const handleBackClick = () => {
    if (currentStep > 0) {
      setCurrentStep((prevStep) => prevStep - 1);
    }
  };

  const togglePasswordVisibility = (fieldName: string) => {
    setShowPassword((prevState) => ({
      ...prevState,
      [fieldName]: !prevState[fieldName],
    }));
  };

  // This will be temporarily hidden
  // const handleOAuthLogin = (provider: string) => {
  //   console.log(`Iniciar sesión con ${provider}`);
  // };

  const handleLoginRedirect = () => {
    navigate('/');
  };

  const handleCustomApiCall = () => {
    const { companyEmail } = formData[0] as { companyEmail: string };
    resendCode(companyEmail)
      .then(() => {
        console.log('OTP resend');
        setTimeLeft(300);
      })
      .catch((error) => console.error('Error OTP:', error));
  };

  const currentForm = steps[currentStep];
  const currentFormData = formData[currentStep] || {};

  const renderFields = () => {
    const fields = [];

    for (let index = 0; index < currentForm.formFields.length; index++) {
      const field = currentForm.formFields[index];
      const passwordValue = (currentFormData['password'] as string) || '';
      const showCurrentPassword = showPassword[field.name || ''] || false;

      let fieldComponent;

      //This will be temporarily hidden
      // if (field.type === 'divider') {
      //   fieldComponent = <Divider text="or" sx={{ m: 4 }} />;
      // } else if (field.type === 'oauthButtons' && field.options) {
      //   fieldComponent = (
      //     <OAuthButtons
      //       options={field.options || []}
      //       handleOAuthLogin={handleOAuthLogin}
      //     />
      //   );
      // } else
      if (field.type === 'link') {
        fieldComponent = (
          <BottomLink
            text={field.text || ''}
            linkText={field.linkText || ''}
            disabled={currentStep === 2 && timeLeft > 0}
            onLinkClick={
              currentStep === 0 ? handleLoginRedirect : handleCustomApiCall
            }
            align="left"
          />
        );
      } else if (field.type === 'terms' && field.content) {
        fieldComponent = <TermsContent content={field.content} />;
      } else if (field.type === 'checkbox') {
        fieldComponent = (
          <CheckboxField
            name={field.name || ''}
            label={field.label || ''}
            checked={Boolean(currentFormData[field.name || ''])}
            onChange={handleChange}
            error={errors[field.name || '']}
          />
        );
      } else if (field.type === 'radio' && field.options) {
        fieldComponent = (
          <RadioField
            name={field.name || ''}
            label={field.label || ''}
            options={field.options}
            value={(currentFormData[field.name || ''] as string) || ''}
            onChange={handleChange}
            error={errors[field.name || '']}
            color="secondary"
          />
        );
      } else if (
        field.type === 'password' ||
        field.name === 'confirmPassword'
      ) {
        fieldComponent = (
          <>
            <PasswordField
              name={field.name || ''}
              label={field.label || ''}
              value={(currentFormData[field.name || ''] as string) || ''}
              error={!!errors[field.name || '']}
              helperText={errors[field.name || '']}
              showPassword={showCurrentPassword}
              onChange={handleChange}
              toggleShowPassword={() =>
                togglePasswordVisibility(field.name || '')
              }
            />
            {field.name === 'password' && field.validationMessages && (
              <PasswordRequirements
                passwordValue={passwordValue}
                validationMessages={
                  field.validationMessages as ValidationMessages
                }
                requirementsTitle={field.requirementsTitle}
                messages={messages}
              />
            )}
          </>
        );
      } else {
        fieldComponent = (
          <TextInputField
            name={field.name || ''}
            label={field.label || ''}
            value={(currentFormData[field.name || ''] as string) || ''}
            type={field.type || 'text'}
            error={!!errors[field.name || '']}
            helperText={errors[field.name || '']}
            onChange={handleChange}
          />
        );
      }

      fields.push(<div key={index}>{fieldComponent}</div>);

      if (currentStep === 0 && index === 0) {
        fields.push(
          <Button
            key="button"
            fullWidth
            variant="contained"
            onClick={handleSubmit}
            sx={{
              mt: 2,
              mb: 2,
              bgcolor: '#963F2B',
              color: '#F6FAF6',
              marginBottom: 5,
              marginTop: 5,
            }}
          >
            {currentForm.buttonText}
          </Button>
        );
      }
    }

    return fields;
  };

  useEffect(() => {
    if (currentStep === 2 && timeLeft > 0) {
      const timerId = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timerId);
    }
  }, [currentStep, timeLeft]);

  return (
    <AuthCard
      title={currentForm.title}
      subtitle={currentForm.subtitle}
      email={email}
      pharagraph={currentForm.pharagraph}
      showBackButton={currentForm.showBackButton}
      onBackClick={handleBackClick}
      titleVariant={currentStep === 0 ? 'h1' : currentStep === 2 ? 'h3' : 'h2'}
      timeLeft={timeLeft}
    >
      {renderFields()}

      {currentStep !== 0 && (
        <Button
          fullWidth
          variant="contained"
          onClick={handleSubmit}
          sx={{ mt: 5, bgcolor: '#963F2B', color: '#F6FAF6' }}
        >
          {currentForm.buttonText}
        </Button>
      )}
    </AuthCard>
  );
};

export default AuthContainer;
