import React from 'react';
import { TableProductAssignment } from '@flywl/blocks';
import { GET_USER_PRODUCTS_WITH_ALL_PROVIDERS_QUERY } from '@flywl/data-acess/graphql';
import { useQuery } from '@apollo/client';
import { Box } from '@mui/material';

interface ProviderOption {
  name: string;
  id: string;
  matchId: string;
}

interface MatchingItem {
  id: string;
  provider: {
    name: string;
    id: string;
  };
  logo?: string;
  product_name?: string;
}

interface InventoryAssignment {
  id: string;
  origin: string;
  provider: {
    name: string;
    id: string;
  };
  match_id?: string;
}

interface InventoryItem {
  id: string;
  matching: MatchingItem[];
  inventory_assignments: InventoryAssignment[];
  solution_name?: string;
  vendor: string;
  cost: number;
}

interface Product {
  id: string;
  name: string;
  company: string;
  cost: string;
  currentSupplier: string;
  currentSupplierId: string;
  currentMatchId: string;
  standardSupplier: string;
  standardSupplierId: string;
  standardMatchId: string;
  providers: ProviderOption[];
  logo: string;
}

export const ProductAssignment: React.FC = () => {
  const { loading, error, data } = useQuery<{ inventory_line_item: InventoryItem[] }>(
    GET_USER_PRODUCTS_WITH_ALL_PROVIDERS_QUERY,
    { fetchPolicy: 'no-cache' }
  );

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const inventoryItems = data?.inventory_line_item || [];

  const transformData = (inventoryItems: InventoryItem[]): Product[] => {
    return inventoryItems
      .filter((item) => item.matching.length > 0)
      .map((item) => {
        const providers: ProviderOption[] = item.matching.map((match) => ({
          name: match.provider.name.toUpperCase(),
          id: match.provider.id,
          matchId: match.id,
        }));
        const uiAssignment = item.inventory_assignments.find(
          (assignment) => assignment.origin === 'UI'
        );
        const importAssignment = item.inventory_assignments.find(
          (assignment) => assignment.origin === 'IMPORT'
        );

        const currentSupplier = uiAssignment?.provider.name.toUpperCase() || '';
        const currentSupplierId = uiAssignment?.provider.id || '';
        const currentMatchId = uiAssignment?.match_id || '';

        const standardSupplier = importAssignment?.provider.name.toUpperCase() || '';
        const standardSupplierId = importAssignment?.provider.id || '';
        const standardMatchId = importAssignment?.match_id || '';

        if (currentSupplier && !providers.some((p) => p.name === currentSupplier)) {
          providers.push({
            name: currentSupplier,
            id: currentSupplierId,
            matchId: currentMatchId,
          });
        }

        if (standardSupplier && !providers.some((p) => p.name === standardSupplier)) {
          providers.push({
            name: standardSupplier,
            id: standardSupplierId,
            matchId: standardMatchId,
          });
        }

        let logo = 'https://placeholder.pics/svg/40x40';

        const currentMatching = item.matching.find(
          (match) => match.provider.name.toUpperCase() === currentSupplier
        );
        const standardMatching = item.matching.find(
          (match) => match.provider.name.toUpperCase() === standardSupplier
        );

        if (currentMatching?.logo) {
          logo = currentMatching.logo;
        } else if (standardMatching?.logo) {
          logo = standardMatching.logo;
        } else if (item.matching.length > 0 && item.matching[0].logo) {
          logo = item.matching[0].logo;
        }

        const name =
          item.solution_name ||
          item.matching[0]?.product_name ||
          item.vendor ||
          'Product without a name';

        return {
          id: item.id,
          name,
          company: item.vendor,
          cost: item?.cost?.toString(),
          currentSupplier,
          currentSupplierId,
          currentMatchId,
          standardSupplier,
          standardSupplierId,
          standardMatchId,
          providers,
          logo,
        };
      });
  };

  const products = transformData(inventoryItems);

  const isvProduct = products.find(
    (product) => product.standardSupplier === 'ISV'
  );
  const isvProviderId = isvProduct ? isvProduct.standardSupplierId : null;

  const sortedProducts = products.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <Box sx={{ width: '100%' }}>
      <TableProductAssignment
        products={sortedProducts}
        isvprovider={isvProviderId}
      />
    </Box>
  );
};
