import React from 'react';
import { Card, CardContent, Box, Typography, TextField } from '@mui/material';
import Polyline from '../../assets/Polyline.svg';

export const ComingSoonIntegrations: React.FC = () => {
  return (
    <Card
      elevation={0}
      sx={{
        backgroundColor: '#E6F3F9',
        borderRadius: 2,
        border: '1px solid #B0D9EB',
        padding: 2,
        flex: 1,
      }}
    >
      <CardContent
        sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
          <img src={Polyline} alt="Polyline Logo" width={48} height={48} />
          <Typography variant="h3">Integrations</Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
          <Typography variant="body1">
            Let us know which integration service you would like to have on the
            platform to quickly connect your product information from the cloud.
          </Typography>
          <TextField
            fullWidth
            variant="standard"
            label="Preferred system"
            placeholder="Type here your preferred system for integration"
          />
        </Box>
      </CardContent>
    </Card>
  );
};
