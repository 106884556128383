// src/components/TermsContent/TermsContent.tsx
import React from 'react';
import { Box, Typography } from '@mui/material';

export interface TermsContentProps {
  content: string | string[];
}

export const TermsContent: React.FC<TermsContentProps> = ({ content }) => (
  <Box
    sx={{
      mt: 2,
      mb: 2,
      maxHeight: '300px',
      overflowY: 'auto',
      padding: '8px 0',
    }}
  >
    {Array.isArray(content)
      ? content.map((paragraph, idx) => (
          <Typography key={idx} variant="h6" >
            {paragraph}
          </Typography>
        ))
      : content.split('\n').map((paragraph, idx) => (
          <Typography key={idx} variant="h6" >
            {paragraph}
          </Typography>
        ))}
  </Box>
);

export default TermsContent;
