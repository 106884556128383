import { StrictMode, useEffect } from 'react';
import * as ReactDOM from 'react-dom/client';
import { ApolloProvider } from '@apollo/client';
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';

import LoginPage from './pages/LoginPage/LoginPage';
import ForgotPasswordContainerPage from './pages/ForgotPasswordContainerPage/ForgotPasswordContainerPage';
import ForgotPasswordSentPage from './pages/ForgotPasswordSentPage/ForgotPasswordSentPage';
import RequestPasswordResetPage from './pages/RequestPasswordResetPage/RequestPasswordResetPage';
import ResetPasswordSuccessfullyPage from './pages/ResetPasswordSuccessfullyPage/ResetPasswordSuccessfullyPage';
import AuthPage from './pages/AuthPage/AuthPage';
import LoginSuccessfully from './pages/LoginSuccessfully/LoginSuccessfully';
import UserStatusPage from './pages/UserStatusPage/UserStatusPage';
import { theme } from '@flywl/core';
import './styles/_global.scss';
import {
  CognitoProvider,
  getGraphqlClient,
  getSession,
  useAuth,
  useAuthUsersQuery,
} from '@flywl/provider';
import CompassPage from './pages/CompassPage/CompassPage';
import Logout from './pages/LogoutPage/LogoutPage';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const App = () => {
  const { getCurrentSession } = useAuth();
  const { isLoggedIn, expired } = getSession();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  console.warn({source: "<App/>", pathname, isLoggedIn, expired})
  const { data, refetch } = useAuthUsersQuery({
    skip: !isLoggedIn || expired,
  });
  const userStatus = data?.auth_users[0].status;

  useEffect(() => {
    if (isLoggedIn && expired) {
      console.warn({source: "<App/> refresh session", pathname, isLoggedIn, expired})
      getCurrentSession()
        .then(() => refetch())
        .catch((e) => console.log(e));
    }
    else if (
      !isLoggedIn &&
      (pathname.includes('/compass') || pathname.includes('/user-status'))
    ) {
      console.warn({source: "<App/> navigate to /", pathname, isLoggedIn, expired});
      navigate('/');
    } else if (isLoggedIn && (userStatus !== 1 || pathname === '/')) {
      console.warn({source: "<App/> navigate to /user-status", pathname, isLoggedIn, expired});
      navigate('/user-status');
    }
  }, [
    getCurrentSession,
    expired,
    isLoggedIn,
    userStatus,
    refetch,
    pathname,
    navigate,
  ]);

  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/signup" element={<AuthPage />} />
      <Route
        path="/forgot-password"
        element={<ForgotPasswordContainerPage />}
      />
      <Route path="/sent-reset-pasword" element={<ForgotPasswordSentPage />} />
      <Route path="/reset-password" element={<RequestPasswordResetPage />} />
      <Route
        path="/reset-password-successfully"
        element={<ResetPasswordSuccessfullyPage />}
      />
      <Route path="/login-successfully" element={<LoginSuccessfully />} />
      <Route path="/compass/*" element={<CompassPage />} />
      <Route path="/user-status" element={<UserStatusPage />} />
    </Routes>
  );
};

root.render(
  <StrictMode>
    <CognitoProvider>
      <BrowserRouter basename="/">
        <ApolloProvider
          client={getGraphqlClient({
            linkOptions: {
              uri: import.meta.env.VITE_HASURA_URL,
              credentials: {
                type: 'bearer',
                Authorization: `bearer ${getSession().tokens.idToken}`,
              },
            },
          })}
        >
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </ApolloProvider>
      </BrowserRouter>
    </CognitoProvider>
  </StrictMode>
);
