export type Tag = {
  name: string;
  colorType: string;
};

export type ProductType = {
  product: string;
  vendor: string;
  tags?: Tag[];
  icon?: React.ReactNode;
  id?: string;
  cost?: number;
};

export type HeadCell = {
  id: keyof ProductType;
  label: string;
  sortable: boolean;
};

export const PROVIDER_COLORS = {
  AWS: 'green',
  AZURE: 'blue',
  GCP: 'red',
};
