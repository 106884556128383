import React, { ReactNode } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Avatar,
  Box,
  Stack,
  Menu,
  MenuItem,
} from '@mui/material';
import flywlLogo from '../../assets/flywl-logotype.svg';
import CheckIcon from '@mui/icons-material/Check';

interface Step {
  number: string | number;
  title: string;
}

interface TopBarProps {
  steps: Step[];
  currentStep: number;
  company: ReactNode;
  givenName: ReactNode;
  logOut?: () => void;

}

export const TopBar: React.FC<TopBarProps> = ({
  steps,
  currentStep,
  company,
  givenName,
  logOut
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
 

  return (
    <Box id="application-layout-topbar">
      <AppBar
        position="fixed"
        color="transparent"
        elevation={1}
        sx={{
          backgroundColor: '#fdfefd',
          borderBottom: '1px solid #EAEAEA',
          borderRadius: '12px',
          transform: 'translateX(-2.5VW)',
          maxWidth: '95vw',
          top: 20,
        }}
      >
        <Toolbar sx={{ justifyContent: 'space-between', padding: 2 }}>
          <img src={flywlLogo} alt="Logo" style={{ marginRight: '4vw' }} />
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            flexGrow={1}
            justifyContent="space-around"
          >
            {steps.map((step, index) => {
              const stepNumber = Number(step.number);

              let stepState: 'completed' | 'current' | 'pending';
              if (stepNumber < currentStep) {
                stepState = 'completed';
              } else if (stepNumber > currentStep) {
                stepState = 'pending';
              } else {
                stepState = 'current';
              }
              let backgroundColor: string;
              let iconContent: React.ReactNode;
              switch (stepState) {
                case 'completed':
                  backgroundColor = '#B16B1A';
                  iconContent = (
                    <CheckIcon sx={{ color: '#fff', fontSize: '1rem' }} />
                  );
                  break;
                case 'current':
                  backgroundColor = '#963F2B';
                  iconContent = step.number;
                  break;
                case 'pending':
                  backgroundColor = '#A5452F52';
                  iconContent = step.number;
                  break;
              }

              return (
                <Box key={index} display="flex" alignItems="center" flex={1}>
                  <Box
                    sx={{
                      backgroundColor: backgroundColor,
                      borderRadius: '50%',
                      width: 24,
                      height: 24,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: '#fff',
                    }}
                  >
                    {iconContent}
                  </Box>
                  <Typography variant="body1" sx={{ marginLeft: 3 }}>
                    {step.title}
                  </Typography>
                  {index < steps.length - 1 && (
                    <Box
                      component="span"
                      sx={{
                        flex: 1,
                        height: 2,
                        marginLeft: 1,
                        backgroundColor: '#EAEAEA',
                      }}
                    />
                  )}
                </Box>
              );
            })}
          </Stack>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ marginRight: 2 }}>
              {`${company} - ${givenName}`}
            </Typography>
            <Avatar
              sx={{ backgroundColor: 'orange', cursor: 'pointer' }}
              onClick={(event) => setAnchorEl(event.currentTarget)}
            >
              {givenName}
            </Avatar>
            <Menu
              anchorEl={anchorEl}
              open={!!anchorEl}
              onClose={() => setAnchorEl(null)}
              sx={{
                marginTop: '5px',
                left: '-1.5rem',
                '& .MuiList-root': {
                  padding: 0,
                },
              }}
            >
              <MenuItem
                onClick={logOut}
                sx={{ width: '165px', height: '40px', margin: '0px' }}
              >
                Log out
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
