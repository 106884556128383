import { EdpMetricsData } from '@flywl/data-acess/graphql';
import { Column } from '../types/Column.type';

export const transformData = (data: EdpMetricsData): Column[] => {
  const edp = data.edp_commitment.reduce((acc, item) => {
    return acc + parseInt(item.amount.replace(/[^0-9-.]+/g, ''));
  }, 0);

  const utilizedSpend = data.edp_commitment.reduce((acc, item) => {
    return acc + parseInt(item.direct_spend.replace(/[^0-9-.]+/g, ''));
  }, 0);

  const ISVMarketplace =
    data.metrics_cost_isv_bought_through_the_cmp_before_by_provider.reduce(
      (acc, item) => {
        return acc + Number(item.total_cost);
      },
      0
    );

  const ISVMarketplaceAfter =
    data.metrics_cost_isv_bought_through_the_cmp_after_by_provider.reduce(
      (acc, item) => {
        return acc + Number(item.total_cost);
      },
      0
    );

  const deadweightLossBefore =
    data.metrics_deadweight_loss_before_by_provider.reduce((acc, item) => {
      return acc + parseInt(item.result.replace(/[^0-9-.]+/g, ''));
    }, 0);

  const deadweightLossAfter =
    data.metrics_deadweight_loss_after_by_provider.reduce((acc, item) => {
      return acc + parseInt(item.result.replace(/[^0-9-.]+/g, ''));
    }, 0);

  const info: Column[] = [
    {
      title: 'Before',
      fields: [
        {
          name: 'enterprise_commitment',
          value: edp,
          isHighlighted: false,
          format: 'currency',
        },
        {
          name: 'utilized_spend',
          value: utilizedSpend,
          isNegative: false,
          format: 'currency',
        },
        {
          name: 'isv_through_marketplace',
          value: ISVMarketplace,
          format: 'currency',
        },

      ],
      summary: {
        name: 'deadweight_loss',
        value: deadweightLossBefore,
        format: 'currency',
      },
    },
    {
      title: 'After',
      fields: [

        {
          name: '',
          isHighlighted: true,
          value: edp,
          format: 'currency',
        },
        {
          name: ' ',
          value: utilizedSpend,
          // valueColor: '#3AB28A',
          format: 'currency',
        },
        {
          name: ' ',
          value: ISVMarketplaceAfter + ISVMarketplace,
          // valueColor: '#3AB28A',
          format: 'currency',
        },
      ],
      summary: {
        name: '',
        value: deadweightLossAfter,
        valueColor: deadweightLossAfter > 0 ? '#3AB28A' : undefined,
        format: 'currency',
      },
    },
  ];

  return info;
};
