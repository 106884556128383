import { useState } from 'react';
import {
  TableComponent,
  ProductType,
  HeadCell,
  PROVIDER_COLORS,
  Tag,
} from '@flywl/blocks';
import { Skeleton } from '@mui/material';
import { useInventoryLineItemQuery, useProviderQuery } from '@flywl/provider';

type SortOptions = keyof Omit<Omit<Omit<ProductType, 'tags'>, 'icon'>, 'cost'>;
const ProviderOrdering = ['AWS', 'GCP', 'AZURE'];
const sortItemFirst = (a: Tag, b: Tag, first = 'AWS') => {
  const order = [first, ...ProviderOrdering.filter((e) => e !== first)];
  return order.indexOf(a.name as string) - order.indexOf(b.name as string);
};
export const ProductTable = () => {
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<keyof ProductType>('product');
  const [filter, setFilter] = useState<string>('All');
  const { data: providersData } = useProviderQuery();
  const names = [
    'All',
    ...(providersData?.provider
      ?.map((item) => item.name)
      .filter((name) => name !== 'ISV') || []),
  ];

  const { loading, data } = useInventoryLineItemQuery();

  if (!data || loading) return <Skeleton />;

  const formattedData = data?.inventory_line_item
    ?.map((item) => {
      return {
        icon: item.matching.find(({ logo }) => !!logo)?.logo,
        product: item.solution_name || '',
        vendor: item.vendor || '',
        tags: item.matching
          .map(({ provider }) => ({
            name: provider?.name,
            colorType:
              filter === provider?.name || filter === 'All'
                ? PROVIDER_COLORS[
                    provider?.name as keyof typeof PROVIDER_COLORS
                  ]
                : 'gray',
          }))
          .sort((a, b) =>
            sortItemFirst(a, b, filter !== 'All' ? filter : undefined)
          ),
        id: item.id,
      } as ProductType;
    })
    .filter(({ tags }) => {
      const tagNames = tags?.map((tag) => tag.name);
      return (
        tags?.length &&
        tags?.[0]?.name !== 'No Cloud Marketplace' &&
        (filter === 'All' || tagNames?.includes(filter))
      );
    })
    .sort((a, b) => {
      const valueA = a[orderBy] as string | undefined;
      const valueB = b[orderBy] as string | undefined;
      return (valueA || '').localeCompare(valueB || '');
    });

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property as SortOptions);
  };

  const headCells: HeadCell[] = [
    { id: 'product', label: 'Product ', sortable: true },
    { id: 'vendor', label: 'Vendor', sortable: true },
    { id: 'tags', label: 'Cloud Marketplace', sortable: false },
  ];

  return (
    <TableComponent
      name="list_of_products"
      orderBy={orderBy}
      order={order}
      filterOptions={names}
      handleRequestSort={handleRequestSort}
      filteredData={formattedData}
      filter={filter}
      setFilter={setFilter}
      isLoading={loading}
      headCells={headCells}
    />
  );
};
