import AwsIcon from '../../../assets/aws-logo.svg';
import AzureIcon from '../../../assets/azure-logo.svg';
import GcpIcon from '../../../assets/gcp-logo.svg';

export const getPlatformLogo = (platform: string): React.ReactNode => {
  const style = { width: '24px', height: '24px' };
  switch (platform.toLowerCase()) {
    case 'aws':
      return <img src={AwsIcon} alt="AWS Logo" style={style} />;
    case 'azure':
      return <img src={AzureIcon} alt="Azure Logo" style={style} />;
    case 'gcp':
      return <img src={GcpIcon} alt="GCP Logo" style={style} />;
    default:
      return null;
  }
};
