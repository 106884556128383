import { gql } from '@apollo/client';
import {
  Provider,
  MetricsAvailableDrawdownByProvider,
  EdpCommitment,
  MetricsTotalLicences,
  MetricsDrawdownOverage,
  MetricsCostIsvBoughtThroughTheCmpAfterByProvider,
} from './types';
import {
  MetricsNumberOfLicensesMatch,
  MetricsNumberOfVendorsMatched,
  MetricsTotalCost,
} from '../lib/types';
import {
  ISV_BEFORE_AND_AFTER_QUERY,
  TALBLE_METRICS_QUERY,
  OVERALL_RESULTS_QUERY,
  SAVINGS_QUERY,
} from './metrics/queries';

export const GET_PROVIDERS = gql`
  query GetProviders {
    provider {
      id
      name
    }
  }
`;

export interface ProvidersData {
  provider: Provider[];
}
export interface UserStatus {
  status: number;
  given_name: string;
  company: string;
}

export const GET_USER_PRODUCTS_WITH_ALL_PROVIDERS_QUERY = gql`
  query GetUserProductsWithAllProviders {
    inventory_line_item(
      order_by: [
        { cost: desc_nulls_last }
        { vendor: desc_nulls_last }
        { id: desc }
      ]
    ) {
      id
      vendor
      solution_name
      cloud_provider
      inventory_assignments {
        id
        origin
        provider {
          name
          id
        }
      }
      cost
      contract_start_date
      renewal_date
      # Get all matches for this inventory item across all providers
      matching {
        id
        listing_id
        vendor_name
        product_name
        max_score
        logo
        link
        created_at
        provider {
          id
          name
        }
      }
    }
  }
`;
// export const GET_USER_PRODUCTS_WITH_ALL_PROVIDERS_QUERY = gql`
//    query GetUserProductsWithAllProviders {
//     matching {
//     vendor_name
//     product_name
//     logo
//     inventory_line_item {
//       solution_name
//     }
//     provider_id
//     provider {
//       id
//       name
//     }
//   }

// `;

export interface UserProductProviderMatches {
  inventory_line_item: {
    id: string;
    vendor: string;
    solution_name: string;
    cloud_provider: string;
    inventory_assignment: {
      id: string;
      provider: {
        name: string;
      };
    };
    cost: number;
    contract_start_date: string;
    renewal_date: string;
    matching: Array<{
      id: string;
      listing_id: string;
      vendor_name: string;
      product_name: string;
      max_score: number;
      logo: string;
      link: string;
      created_at: string;
      provider: {
        id: string;
        name: string;
      };
    }>;
  }[];
}

export interface UserStatusResponse {
  auth_users: UserStatus[];
}

export const GET_USER_STATUS = gql`
  query GetUserStatus {
    auth_users {
      status
      given_name
      company
    }
  }
`;

export type MetricsAvailableDrawdown = MetricsAvailableDrawdownByProvider;

// export interface MetricsDrawdownOverage {
//   __typename: string;
//   result: string;
//   provider_name: 'AWS' | 'GCP' | 'AZURE';
// }

export const GET_METRICS_ENTERPRISE_COMMITMENT = gql`
  query GetMetricsEnterpriseCommitment {
    metrics_available_drawdown_by_provider {
      available_drawdown
      name
    }
    metrics_drawdown_overage {
      result
      provider_name
    }
    metrics_cost_isv_bought_through_the_cmp_after_by_provider {
      total_cost
      provider_name
      number_of_items
    }
  }
`;

export interface MatchingItem {
  logo: string;
  product_name: string;
  vendor_name: string;
  provider_id: string;
}

export interface ProviderItem {
  id: string;
  name: string;
}

export type MatchingDataObject = {
  matching: MatchingItem[];
  provider: ProviderItem[];
};

export const GET_INVENTORY_LINE_ITEM = gql`
  query GetInventoryLineItem {
    inventory_line_item {
      id
      created_by
      cost
      contract_start_date
      cloud_provider
      assignment_id
      renewal_date
      solution_name
      vendor
      matching {
        id
        created_at
        inventory_line_item_id
        link
        listing_id
        max_score
        logo
        product_name
        provider_id
        user_id
        vendor_name
      }
    }
  }
`;

export const GET_MATCHING_VALUES = gql`
  query GetMatchingValues {
    matching {
      id
      created_at
      inventory_line_item_id
      link
      listing_id
      max_score
      logo
      product_name
      provider_id
      user_id
      vendor_name
    }
    provider {
      id
      name
    }
  }
`;

export const GET_METRICS = gql`
  query GetMetrics {
    metrics_number_of_licenses_match {
      matched_inventory_count
      matched_inventory_percentage
    }
    metrics_total_cost {
      inventory_cost
    }
    metrics_number_of_vendors_matched {
      vendors_matched
      provider_name
    }
    metrics_cost_isv_bought_through_the_cmp_before_by_provider {
      number_of_items
      provider_name
    }
    metrics_cost_isv_bought_without_a_cmp {
      number_of_items
    }
    metrics_renewals_upcoming_aggregate {
      nodes {
        solution_name
      }
      aggregate {
        count
      }
    }
    metrics_renewals_upcoming {
      solution_name
      vendor
    }
    metrics_renewals_expired_aggregate {
      nodes {
        solution_name
      }
      aggregate {
        count
      }
    }
    metrics_renewals_recent_aggregate {
      nodes {
        solution_name
      }
      aggregate {
        count
      }
    }
    metrics_renewals_expired {
      solution_name
      vendor
    }
    metrics_renewals_recent {
      solution_name
      vendor
    }
    metrics_number_of_matches_in_aws {
      result
    }
    metrics_number_of_matches_in_azure {
      result
    }
    metrics_number_of_matches_in_gcp {
      result
    }
    metrics_percentage_of_match_in_aws {
      percentage
    }
    metrics_percentage_of_match_in_azure {
      percentage
    }
    metrics_percentage_of_match_in_gcp {
      percentage
    }
    metrics_total_licences {
      number_of_licenses
    }
  }
`;

// export interface MetricsNumberOfLicensesMatch {
//   matched_inventory_count: number;
//   matched_inventory_percentage: number;
// }

// export interface MetricsTotalCost {
//   inventory_cost: number;
// }

// export interface MetricsNumberOfVendorsMatched {
//   vendors_matched: number;
//   provider_name: string;
// }

export interface MetricsCostISVBoughtThroughCMPByProvider {
  number_of_items: number;
  provider_name: string;
}

export interface MetricsCostISVBoughtWithoutCMP {
  number_of_items: number;
}

export interface MetricsRenewalsAggregate {
  aggregate: {
    count: number;
  };
}

export interface MetricsRenewals {
  solution_name: string;
  vendor: string;
}

export interface MetricsNumberOfMatches {
  result: number;
}

export interface MetricsPercentageOfMatch {
  percentage: number;
}

// export interface MetricsTotalLicences {
//   number_of_licenses: number;
// }

export interface EnterpriseCommitmentData {
  metrics_available_drawdown_by_provider: MetricsAvailableDrawdown[];
  metrics_drawdown_overage: MetricsDrawdownOverage[];
  metrics_cost_isv_bought_through_the_cmp_after_by_provider: MetricsCostIsvBoughtThroughTheCmpAfterByProvider[];
}

export interface MetricsData {
  metrics_number_of_licenses_match: MetricsNumberOfLicensesMatch[];
  metrics_total_cost: MetricsTotalCost[];
  metrics_number_of_vendors_matched: MetricsNumberOfVendorsMatched[];
  metrics_cost_isv_bought_through_the_cmp_before_by_provider: MetricsCostISVBoughtThroughCMPByProvider[];
  metrics_cost_isv_bought_without_a_cmp: MetricsCostISVBoughtWithoutCMP[];
  metrics_renewals_upcoming_aggregate: MetricsRenewalsAggregate;
  metrics_renewals_upcoming: MetricsRenewals[];
  metrics_renewals_expired_aggregate: MetricsRenewalsAggregate;
  metrics_renewals_expired: MetricsRenewals[];
  metrics_renewals_recent_aggregate: MetricsRenewalsAggregate;
  metrics_renewals_recent: MetricsRenewals[];
  metrics_number_of_matches_in_aws: MetricsNumberOfMatches[];
  metrics_number_of_matches_in_azure: MetricsNumberOfMatches[];
  metrics_number_of_matches_in_gcp: MetricsNumberOfMatches[];
  metrics_percentage_of_match_in_aws: MetricsPercentageOfMatch[];
  metrics_percentage_of_match_in_azure: MetricsPercentageOfMatch[];
  metrics_percentage_of_match_in_gcp: MetricsPercentageOfMatch[];
  metrics_total_licences: MetricsTotalLicences[];
}

export const EDO_METRICS_QUERY = gql`
  query edpReport($provider: String!) {
    edp_commitment(where: { provider: { name: { _eq: $provider } } }) {
      amount
      provider_id
      direct_spend
      custom_drawdown
      provider {
        name
      }
      provider_id
    }
    metrics_cost_isv_bought_through_the_cmp_before_by_provider(
      where: { provider_name: { _eq: $provider } }
    ) {
      total_cost
    }
    metrics_cost_isv_bought_through_the_cmp_after_by_provider(
      where: { provider_name: { _eq: $provider } }
    ) {
      total_cost
    }
    metrics_deadweight_loss_before_by_provider(
      where: { provider_name: { _eq: $provider } }
    ) {
      result
    }
    metrics_deadweight_loss_after_by_provider(
      where: { provider_name: { _eq: $provider } }
    ) {
      result
    }
  }
`;
// Not sure if i have to delete at all
// interface Provider {
//   name: string;
// }

interface EdpCommitmentItem {
  amount: string;
  provider_id: string;
  direct_spend: string;
  custom_drawdown: number;
  provider: Provider;
}

interface MetricsCostItem {
  total_cost: number;
}

export interface MetricsDeadweightLossItem {
  result: string;
}

export interface EdpMetricsData {
  edp_commitment: EdpCommitmentItem[];
  metrics_cost_isv_bought_through_the_cmp_before_by_provider: MetricsCostItem[];
  metrics_cost_isv_bought_through_the_cmp_after_by_provider: MetricsCostItem[];
  metrics_deadweight_loss_before_by_provider: MetricsDeadweightLossItem[];
  metrics_deadweight_loss_after_by_provider: MetricsDeadweightLossItem[];
}

export const EDP_COMPLETE_QUERY = gql`
  query edp_complete($provider: String!) {
    edp_commitment(where: { provider: { name: { _eq: $provider } } }) {
      amount
      provider_id
      direct_spend
      custom_drawdown
      provider_id
      discount
    }
    metrics_drawdown_overage(where: { provider_name: { _eq: $provider } }) {
      result
    }
    metrics_duration_of_commitment_years_by_platform(
      where: { provider_name: { _eq: $provider } }
    ) {
      duration_in_years
    }
  }
`;

interface EdpCommitment2 {
  amount: string;
  provider_id: string;
  direct_spend: string;
  custom_drawdown: number;
  discount: number;
}

interface DrawdownOverage {
  result: string;
}

interface YearsOfCommitment {
  duration_in_years: number;
}

export interface EdpCommitmentResponse {
  edp_commitment: EdpCommitment2[];
  metrics_drawdown_overage: DrawdownOverage[];
  metrics_duration_of_commitment_years_by_platform: YearsOfCommitment[];
}

export const PENDING_ASSIGNMENT_BY_PROVIDER_QUERY = gql`
  query getPendingByProvider($providerId: uuid!) {
    inventory_assignment(
      where: { is_pending: { _eq: true }, provider_id: { _eq: $providerId } }
    ) {
      inventory_id
    }
  }
`;

export interface PendingAssigmentByProviderResponse {
  inventory_assignment: PendingAssignmentByProvider[];
}

interface PendingAssignmentByProvider {
  inventory_id: string;
}

export const GET_PRODUCT_BY_ID_WITH_LOGO = gql`
  query getProductNamesAndMatching($items: [uuid!]!) {
    inventory_line_item(where: { id: { _in: $items } }) {
      vendor
      solution_name
      cost
      id
    }
    matching(where: { inventory_line_item_id: { _in: $items } }, limit: 1) {
      logo

      inventory_line_item_id
    }
  }
`;

interface InventoryLineItem {
  vendor: string;
  solution_name: string;
  cost: number;
  assignment_id?: string;
  id: string;
}

interface MatchingResponse {
  logo: string;
  inventory_line_item_id: string;
}

export interface InventoryLineItemResponse {
  inventory_line_item: InventoryLineItem[];
  matching: MatchingResponse[];
}
export type RenewalItem = {
  vendor: string;
  solution_name: string;
  cloud_provider: string;
};

export type RenewalsMetrics = {
  metrics_renewals_expired: RenewalItem[];
  metrics_renewals_recent: RenewalItem[];
  metrics_renewals_upcoming: RenewalItem[];
};

export const GET_RENEWALS_VALUES = gql`
  query GetRenewalsValues {
    metrics_renewals_expired {
      vendor
      solution_name
      cloud_provider
    }
    metrics_renewals_recent {
      vendor
      solution_name
      cloud_provider
    }
    metrics_renewals_upcoming {
      vendor
      solution_name
      cloud_provider
    }
  }
`;

type ProductsData = {
  product_name: string;
  logo: string;
};

export type AllProductsData = {
  matching: ProductsData[];
};

export const GET_ALL_PRODUCTS = gql`
  query GetAllProducts {
    matching {
      product_name
      logo
    }
  }
`;


export const REFETCH_ON_INV_ASSIGN_OR_EDP_CHANGE = [
  'inventory_assignment',
  'edp_commitment',
  EDO_METRICS_QUERY,
  EDP_COMPLETE_QUERY,
  TALBLE_METRICS_QUERY,
  GET_METRICS,
  GET_METRICS_ENTERPRISE_COMMITMENT,
  GET_PRODUCT_BY_ID_WITH_LOGO,
  GET_USER_PRODUCTS_WITH_ALL_PROVIDERS_QUERY,
  ISV_BEFORE_AND_AFTER_QUERY,
  OVERALL_RESULTS_QUERY,
  PENDING_ASSIGNMENT_BY_PROVIDER_QUERY,
  SAVINGS_QUERY,
];