import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

interface ValidationMessages {
  length?: string;
  uppercase?: string;
  lowercase?: string;
  number?: string;
  specialChar?: string;
}

interface Requirement {
  label: string | undefined;
  isValid: boolean | undefined;
}

export interface PasswordRequirementsProps {
  passwordValue: string;
  validationMessages: ValidationMessages;
  requirementsTitle?: string;
  messages?: { [key: string]: string };
}

export const PasswordRequirements: React.FC<PasswordRequirementsProps> = ({
  passwordValue,
  validationMessages,
  requirementsTitle,
  messages,
}) => {
  const theme = useTheme();

  const passwordRequirements: Requirement[] = [
    {
      label: validationMessages.length,
      isValid: passwordValue.length >= 8,
    },
    {
      label: validationMessages.uppercase,
      isValid: /[A-Z]/.test(passwordValue),
    },
    {
      label: validationMessages.lowercase,
      isValid: /[a-z]/.test(passwordValue),
    },
    {
      label: validationMessages.number,
      isValid: /\d/.test(passwordValue),
    },
    {
      label: validationMessages.specialChar,
      isValid: /[!@#$%^&*(),.?":{}|<>[\]\\;"'_`~=+\-/]/.test(passwordValue),
    },
  ];

  const getRequirementState = (isValid: boolean | undefined) => {
    if (passwordValue.length === 0) return 'neutral';
    return isValid ? 'valid' : 'invalid';
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h4" gutterBottom>
        {requirementsTitle ||
          messages?.passwordRequirementsTitle ||
          'Password Requirements:'}
      </Typography>
      {passwordRequirements.map((requirement, i) => {
        if (!requirement.label) return null;
        const state = getRequirementState(requirement.isValid);
        let IconComponent;
        let iconColor;

        if (state === 'neutral') {
          IconComponent = CircleOutlinedIcon;
          iconColor = theme.palette.text.disabled;
        } else if (state === 'valid') {
          IconComponent = CheckCircleOutlinedIcon;
          iconColor = theme.palette.success.main;
        } else {
          IconComponent = CancelOutlinedIcon;
          iconColor = theme.palette.error.main;
        }
        return (
          <Box key={i} display="flex" alignItems="center" mb={1}>
            <IconComponent sx={{ mr: 1, color: iconColor }} />
            <Typography
              variant="body1"
              sx={{
                color:
                  state === 'invalid'
                    ? theme.palette.error.main
                    : theme.palette.text.secondary,
              }}
            >
              {requirement.label}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};
