import { useQuery } from '@apollo/client';
import {
  EnterpriseCommitmentData,
  GET_METRICS_ENTERPRISE_COMMITMENT,
} from '@flywl/data-acess/graphql';

export const useGetMetricsEnterpriseCommitment = () => {
  const { data, error, loading } = useQuery<EnterpriseCommitmentData>(
    GET_METRICS_ENTERPRISE_COMMITMENT, {notifyOnNetworkStatusChange:true, fetchPolicy:"no-cache"} 

  );

  return {
    data,
    loading,
    error,
  };
};
