import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { AuthCard } from '@flywl/blocks';
import { Button } from '@flywl/core';
import { BottomLink } from '@flywl/core';
import { PasswordField } from '@flywl/core';
import { TextInputField } from '@flywl/core';
import { useAuth } from '@flywl/provider';

const MAX_ATTEMPTS = 3;
const LOCKOUT_DURATION = 60;

const LoginContainer: React.FC = () => {
  const [formData, setFormData] = useState<{ email: string; password: string }>(
    {
      email: '',
      password: '',
    }
  );
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [failedAttempts, setFailedAttempts] = useState(0);
  const [isLocked, setLocked] = useState(false);
  const [lockoutTimeLeft, setLockoutTimeLeft] = useState(0);

  const navigate = useNavigate();
  const { signIn } = useAuth();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (errors[name]) {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[name];
        return newErrors;
      });
    }
  };

  const validateFields = (externalErrors?: {
    [key: string]: string;
  }): boolean => {
    const { email, password } = formData;
    const newErrors: { [key: string]: string } = {};
    let valid = true;

    if (!email) {
      newErrors['email'] = 'Email is required';
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors['email'] = 'Invalid email address';
      valid = false;
    }

    if (!password) {
      newErrors['password'] = 'Password is required';
      valid = false;
    }

    if (externalErrors) {
      valid = false;
      Object.assign(newErrors, externalErrors);
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isLocked) return;
    if (validateFields()) {
      try {
        const { email, password } = formData;
        setLoading(true);

        await signIn(email, password);
        setFailedAttempts(0);
        navigate('/user-status');
      } catch (error) {
        setFailedAttempts((prev) => prev + 1);

        if (failedAttempts + 1 >= MAX_ATTEMPTS) {
          setLocked(true);
          setLockoutTimeLeft(LOCKOUT_DURATION);
        }

        const externalErrors: { [key: string]: string } = {};

        if (error instanceof Error) {
          const errorCode = 'code' in error ? error.code : error.name;
          const errorMessage = error.message || '';

          switch (errorCode) {
            case 'UserNotFoundException':
              externalErrors['email'] = 'The email address does not exist.';
              break;
            case 'NotAuthorizedException':
              externalErrors['password'] =
                'Incorrect password. Please try again.';
              break;
            case 'UserNotConfirmedException':
              externalErrors['email'] =
                'Your account is not confirmed. Please check your email to confirm your account.';
              break;
            case 'InvalidParameterException':
              externalErrors['email'] = errorMessage || 'Invalid parameter.';
              break;
            default:
              externalErrors['general'] =
                errorMessage ||
                'An unexpected error occurred. Please try again.';
              break;
          }
        } else {
          externalErrors['general'] =
            'An unexpected error occurred. Please try again.';
        }

        validateFields(externalErrors);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleForgotPassword = () => {
    navigate('/forgot-password');
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSignUpRedirect = () => {
    navigate('/signup');
  };

  useEffect(() => {
    if (isLocked && lockoutTimeLeft > 0) {
      const timer = setTimeout(
        () => setLockoutTimeLeft((time) => time - 1),
        1000
      );
      return () => clearTimeout(timer);
    } else if (lockoutTimeLeft === 0) {
      setLocked(false);
      setFailedAttempts(0);
    }
  }, [isLocked, lockoutTimeLeft]);

  return (
    <form onSubmit={handleSubmit}>
      <AuthCard title="Log in" subtitle="" showBackButton={false}>
        <TextInputField
          disabled={isLoading || isLocked}
          name="email"
          label="Company Email"
          value={formData.email}
          error={!!errors['email']}
          helperText={errors['email']}
          onChange={handleChange}
        />

        <PasswordField
          disabled={isLoading || isLocked}
          name="password"
          label="Password"
          value={formData.password}
          error={!!errors['password']}
          helperText={
            isLocked
              ? `Too many failed attempts. Try again in ${lockoutTimeLeft}s.`
              : errors['password']
          }
          showPassword={showPassword}
          onChange={handleChange}
          toggleShowPassword={togglePasswordVisibility}
        />

        <Box sx={{ textAlign: 'left', mb: 2 }}>
          <BottomLink
            disabled={isLoading || isLocked}
            linkText={'Forgot my password'}
            onLinkClick={handleForgotPassword}
            sx={{ color: isLocked ? 'gray' : '#963F2B' }}
            align="left"
          >
            Forgot my password
          </BottomLink>
        </Box>

        <Button
          type="submit"
          disabled={isLoading || isLocked}
          fullWidth
          variant="contained"
          sx={{
            mt: 2,
            mb: 2,
            bgcolor: isLocked ? 'gray' : '#963F2B',
            color: '#F6FAF6',
          }}
        >
          Continue
        </Button>

        {/* This will be temporarily hidden */}
        {/* <Divider text="or" sx={{ m: 4 }} /> */}
        {/* <OAuthButtons
          disabled={isLoading}
          options={[
            { provider: 'Google', label: 'Continue with Google' },
            { provider: 'Microsoft', label: 'Continue with Microsoft' },
          ]}
          handleOAuthLogin={(provider) =>
            console.log(`Logging in with ${provider}`)
          }
        /> */}

        <BottomLink
          disabled={isLoading || isLocked}
          text="I don't have an account"
          linkText="Sign up"
          onLinkClick={handleSignUpRedirect}
          align="left"
        />
      </AuthCard>
    </form>
  );
};

export default LoginContainer;
