import React, { forwardRef } from 'react';
import {
  Alert as MuiAlert,
  AlertTitle,
  AlertProps as MuiAlertProps,
} from '@mui/material';

interface CustomAlertProps extends MuiAlertProps {
  severity?: 'success' | 'error' | 'warning' | 'info';
  title?: string;
  message: string;
}

export const Alert = forwardRef<HTMLDivElement, CustomAlertProps>(
  ({ severity = 'info', title, message, ...props }, ref) => {
    return (
      <MuiAlert
        ref={ref}
        sx={{}}
        severity={severity}
        {...props}
      >
        {title && <AlertTitle>{title}</AlertTitle>}
        {message}
      </MuiAlert>
    );
  }
);

Alert.displayName = 'Alert';