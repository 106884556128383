// src/components/PasswordField/PasswordField.tsx
import React from 'react';
import {
  TextField,
  InputAdornment,
  IconButton,
  Typography,
  TextFieldProps,
} from '@mui/material';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

export type PasswordFieldProps = TextFieldProps & {
  name: string;
  label: string;
  value: string;
  helperText?: string;
  showPassword: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  toggleShowPassword: () => void;
}

export const PasswordField: React.FC<PasswordFieldProps> = ({
  name,
  label,
  value,
  helperText,
  showPassword,
  onChange,
  toggleShowPassword,
  ...rest
}) => (
  <TextField
    {...rest}
    fullWidth
    label={label}
    margin="normal"
    name={name}
    type={showPassword ? 'text' : 'password'}
    onChange={onChange}
    value={value}
    helperText={
      helperText && (
        <Typography variant="caption" >
          {helperText}
        </Typography>
      )
    }
    variant="standard"
    color="secondary"
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <IconButton onClick={toggleShowPassword} edge="end">
            {showPassword ? (
              <VisibilityOutlinedIcon />
            ) : (
              <VisibilityOffOutlinedIcon />
            )}
          </IconButton>
        </InputAdornment>
      ),
    }}
  />
);

export default PasswordField;