import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import flywlogo from '../../assets/flywl-logotype.svg';
import ChevronLeft from '@mui/icons-material/ChevronLeft';

export interface AuthCardProps {
  title: string;
  subtitle?: string;
  email?: string;
  pharagraph?: string;
  children?: React.ReactNode;
  showBackButton?: boolean;
  onBackClick?: () => void;
  titleVariant?: 'h1' | 'h2' | 'h3';
  subtitleVariant?: 'subtitle1' | 'subtitle2';
  header?: string;
  backButton?: boolean;
  centerAllTheContent?: boolean;
  timeLeft?: number;
}

export const AuthCard: React.FC<AuthCardProps> = ({
  title,
  subtitle,
  email,
  pharagraph,
  children,
  showBackButton = false,
  onBackClick,
  titleVariant = 'h1',
  subtitleVariant = 'subtitle1',
  header,
  backButton = true,
  centerAllTheContent = false,
  timeLeft = 300,
}) => {
  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secondsLeft = seconds % 60;
    return `${minutes}:${secondsLeft < 10 ? '0' : ''}${secondsLeft}`;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        bgcolor: '#FAFAFA',
        pt: '6.25rem',
        width: '100%',
      }}
    >
      <Box
        sx={{
          maxWidth: '484px',
          width: '100%',
          bgcolor: '#fdfefd',
          borderRadius: 3,
          padding: '3rem',
          border: '1px solid #EAEAEA',
          textAlign: centerAllTheContent ? 'center' : 'left',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            mb: '2rem',
          }}
        >
          {backButton && showBackButton && (
            <IconButton
              sx={{
                position: 'absolute',
                left: 0,
              }}
              onClick={onBackClick}
            >
              <ChevronLeft />
            </IconButton>
          )}

          <Box sx={{ textAlign: 'center' }}>
            <img src={flywlogo} alt="Flywl Logotype" width={136} height={44} />
          </Box>
        </Box>

        <Box sx={{ mb: 2 }}>
          {header && (
            <Typography variant="body1" sx={{ mb: 3 }}>
              {header}
            </Typography>
          )}

          <Typography sx={{ mb: 5 }} variant={titleVariant}>
            {title}
          </Typography>

          <Typography variant="body1" sx={{ mt: 1, mb: 2 }} component={'div'}>
            <Typography variant={subtitleVariant}>{subtitle}</Typography>
            {email && titleVariant === 'h3' && (
              <Typography component="span" variant="body2">
                {email}
              </Typography>
            )}
          </Typography>

          {pharagraph && (
            <Typography variant="body1" sx={{ mt: 4, mb: 2 }}>
              {pharagraph}
              <Typography component="span" variant="body2" sx={{ mb: 2 }}>
                {formatTime(timeLeft)}
              </Typography>
            </Typography>
          )}

          {children}
        </Box>
      </Box>
    </Box>
  );
};
