import Grid from '@mui/material/Grid2';
import { Box, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { theme } from '@flywl/core';
import { EnterpriseCommitmentInfo } from './EnterpriseCommitmentInfo';

export const TitleAndExit = ({ onClose }: { onClose: () => void }) => {
  return (
    <Grid
      container
      sx={{
        px: 3,
      }}
    >
      <Grid size={10}>
        <Box display={'flex'} flexDirection={'column'} gap={'1rem'}>
          <Typography
            fontFamily={theme.typography.fontFamily}
            fontWeight={theme.typography.h1.fontWeight}
            fontSize={theme.typography.h1.fontSize}
            lineHeight={theme.typography.h1.lineHeight}
            color={theme.typography.h1.color}
          >
            Enterprise Commitment <EnterpriseCommitmentInfo />
          </Typography>
          <Typography>
            Please add your enterprise commitment information so we can
            calculate and recommend the best offers for you.
          </Typography>
        </Box>
      </Grid>
      <Grid
        size={2}
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'flex-start',
          paddingTop: 1,
        }}
      >
        <IconButton
          onClick={onClose}
          size="small"
          sx={{
            color: '#667085',
            '&:hover': {
              color: '#101828',
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};
