import React from 'react';
import { formatValue } from '@flywl/utils-data';
import { SxProps, Theme, Typography, TypographyProps } from '@mui/material';

export type FormatOptions =
  | (Intl.NumberFormatOptions & { language?: string })
  | undefined;

export type FormatType =
  | 'currency'
  | 'decimal'
  | 'percentage'
  | 'date'
  | 'int'
  | 'string';

interface ValueProps {
  value?: number | string | Date;
  format?: FormatType;
  options?: FormatOptions;
  variant?: TypographyProps['variant'];
  sx?: SxProps<Theme>;
}

export const Value: React.FC<ValueProps> = ({
  value,
  format,
  options,
  variant,
  sx,
}) => {
  const formattedValue = formatValue(value, format, options);

  return (
    <Typography sx={{ ...sx }} variant={variant || 'body1'}>
      {formattedValue}
    </Typography>
  );
};
