
import { useQuery } from '@apollo/client';
import { GET_METRICS, MetricsData } from '@flywl/data-acess/graphql';

export const useGetMetrics = () => {
  const { data, error, loading } = useQuery<MetricsData>(GET_METRICS, {fetchPolicy:"no-cache"} );

  return {
    data,
    loading,
    error,
  };
};
