export type MetricInfo = {
  id: string;
  label: string;
  tooltip?: string;
};

export const fields: Record<string, MetricInfo> = {
  matched_inventory_count: {
    id: 'matched_inventory_count',
    label: 'Number of Products Matched',
    tooltip:
      'This is the total number of products identified in a cloud marketplace.',
  },
  matched_inventory_percentage: {
    id: 'matched_inventory_percentage',
    label: 'Percentage of Products Matched',
    tooltip: 'This is the percentage of products found in a cloud marketplace.',
  },
  inventory_percentage: {
    id: 'inventory_percentage',
    label: 'Percentage of Products Matched',
  },
  inventory_line_item_count: {
    id: 'inventory_line_item_count',
    label: 'Total Products Given',
    tooltip: "This is the total number of products you've provided.",
  },
  inventory_cost: {
    id: 'inventory_cost',
    label: 'Total Inventory Cost',
    tooltip:
      "This is the sum of the total value of the products you've provided.",
  },
  vendors_matched: {
    id: 'vendors_matched',
    label: 'Matching Per Marketplace',
    tooltip:
      'This is the total number of products found in the top three cloud marketplaces.',
  },
  products_bought: {
    id: 'products_bought',
    label: 'Products Bought (Marketplace and Non-Marketplace)',
    tooltip:
      "This chart shows the number of products you're currently purchasing through and outside of cloud marketplaces.",
  },
  recent_renewals: {
    id: 'recent_renewals',
    label: 'Recent Renewals',
    tooltip:
      'These are the products you have recently renewed, meaning within the last 60 days.',
  },
  expired_renewals: {
    id: 'expired_renewals',
    label: 'Expired Renewals',
    tooltip: "These are the products with end dates before today's date",
  },
  upcoming_renewals: {
    id: 'upcoming_renewals',
    label: 'Upcoming Renewals',
    tooltip:
      'These are the products with contract end dates within the next 60 days',
  },
  aws_azure_gcp: {
    id: 'aws_azure_gcp',
    label: 'AWS, AZURE, GCP',
    tooltip:
      'Here you will find the total number of products found in this cloud marketplace and their percentage of the total identified',
  },
  enterprise_commitment: {
    id: 'enterprise_commitment',
    label: 'Enterprise Commitment',
    tooltip:
      'This is the amount of money you’ve committed to a cloud marketplace.',
  },
  utilized_spend: {
    id: 'utilized_spend',
    label: 'Utilized Spend',
    tooltip:
      'This is the spending on native products in each cloud marketplace.',
  },
  years_of_commitment: {
    id: 'years_of_commitment',
    label: 'Years of Commitment',
    tooltip:
      'This is the length of your commitment with each cloud marketplace, in years.',
  },
  drawdown_percentage: {
    id: 'drawdown_percentage',
    label: 'Drawdown Percentage',
    tooltip:
      'This is the maximum percentage you can deduct from your enterprise commit by using independent software products.',
  },
  drawdown_overage: {
    id: 'drawdown_overage',
    label: 'Drawdown Overage',
    tooltip:
      'This is the cost you are transferring to a marketplace that exceeds your drawdown amount.',
  },
  isv_through_marketplace: {
    id: 'isv_through_marketplace',
    label: 'ISV through Marketplace',
    tooltip:
      'This is the value of ISV products you’ve provided that are being purchased on cloud marketplace and without marketplace.',
  },
  deadweight_loss: {
    id: 'deadweight_loss',
    label: 'Deadweight Loss',
    tooltip:
      'Deadweight loss is the difference between your enterprise commit and your direct spend.',
  },
  total_spend_cmp: {
    id: 'total_spend_cmp',
    label: 'Total Spend CMP',
    tooltip:
      'Sum of the enterprise commit and ISV purchases through cloud marketplaces.',
  },
  private_isv: {
    id: 'private_isv',
    label: 'Private ISV',
    tooltip:
      "Total value of ISV products you're purchasing outside of cloud marketplaces.",
  },
  total_spend: {
    id: 'total_spend',
    label: 'Total Spend',
    tooltip:
      'Sum of the total value purchased through Cloud marketplaces and outside of Cloud marketplaces.',
  },
  savings: {
    id: 'savings',
    label: 'Savings value',
    tooltip: 'Total of your savings by using Flywl.',
  },
  total_drawdown_amount: {
    id: 'total_drawdown_amount',
    label: 'Total Drawdown Amount',
    tooltip:
      'This is the maximum amount you can reduce from your enterprise commit using private software.',
  },
  migratable_spend: {
    id: 'migratable_spend',
    label: 'Migratable Spend',
    tooltip: 'This is the total cost you are moving to this cloud marketplace.',
  },
  number_of_products_moved: {
    id: 'number_of_products_moved',
    label: 'Number of Products Moved',
    tooltip:
      'This is the total number of products you are moving to this cloud marketplace.',
  },
  savings_percentage: {
    id: 'savings_percentage',
    label: 'Savings percentage',
  },
  total_product_found: {
    id: 'total_product_found',
    label: 'Total products found',
  },
  list_of_products: {
    id: 'list_of_products',
    label: 'List of all products found in cloud marketplaces',
  },
  renewals_table: {
    id: 'renewals_table',
    label: 'Renewals',
  },
  GCP: {
    id: 'gcp',
    label: 'GCP',
    tooltip:
      'Here you will find the total number of products found in this cloud marketplace and their percentage of the total identified',
  },
  AZURE: {
    id: 'azure',
    label: 'Azure',
    tooltip:
      'Here you will find the total number of products found in this cloud marketplace and their percentage of the total identified',
  },
  AWS: {
    id: 'aws',
    label: 'AWS',
    tooltip:
      'Here you will find the total number of products found in this cloud marketplace and their percentage of the total identified',
  },
  isv_drawdown: {
    id: 'isv_drawdown',
    label: 'ISV Drawdown',
    tooltip: 'This is the total amount of money obtained from the drawdown',
  },
  total_commitment: {
    id: 'total_commitment',
    label: 'Total Enterprise Commitment',
    tooltip:
      'Sum of the enterprise commitments and ISV purchases through cloud marketplaces.',
  },
  upload_validation: {
    id: 'upload_validation',
    label: 'validation',
    tooltip: 'Please upload a file to continue.'
  },
  edp_validation: {
    id: 'edp_validation',
    label: 'validation',
    tooltip: 'Add your enterprise Commitment and manage your \n products to continue.'
  }

};
