import React from 'react';
import { Card, Box, Typography, IconButton } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Field } from '@flywl/core';

type RenewalSummaryCardType = {
  value: number;
  name: string;
};

type RenewalSummaryData = {
  renewalsSummary: RenewalSummaryCardType[];
  onClickRenewals: () => void;
};

export const RenewalSummaryCard: React.FC<RenewalSummaryData> = ({
  renewalsSummary,
  onClickRenewals,
}) => (
  <Card
    sx={{
      padding: 5,
      borderRadius: 2,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'space-around',
      maxWidth: 230,
      height: 300,
    }}
    variant="outlined"
  >
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
      <Typography variant="body1" sx={{ fontWeight: 600, mr: 1 }}>
        Renewals
      </Typography>
      <IconButton size="small" color="inherit" onClick={onClickRenewals}>
        <OpenInNewIcon fontSize="small" />
      </IconButton>
    </Box>

    {renewalsSummary.map((item: RenewalSummaryCardType, index) => (
      <Box key={index} sx={{ mb: 2 }}>
        <Field.Value value={item.value} format="int" variant="h1" />
        <Field.Label name={item.name} variant="h5" />
      </Box>
    ))}
  </Card>
);
