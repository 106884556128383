import { Card } from '@flywl/core';
import { ChartBarFromDelta } from './components/ChartBarFromDelta';
import { Title } from './components/Title';
import { Box } from '@mui/material';
import { SavingsRow } from './components/SavingsRow';
import { DeltaOverallTable } from './components/DeltaOverallTable';
import { cardStyle, boxStyle } from './constant/styles';
export const DeltaOverallResults = () => {
  return (
    <Card id="delta-overall-results" sx={cardStyle}>
      <Title />
      <ChartBarFromDelta />
      <Box sx={boxStyle}>
        <DeltaOverallTable />
        <SavingsRow />
      </Box>
    </Card>
  );
};
