import React from 'react';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { AppBar, Box, Button } from '@mui/material';

interface FooterProps {
  onNext?: () => void;
  onBack?: () => void;
  currentStep: number;
  totalSteps: number;
}

export const Footer: React.FC<FooterProps> = ({
  onNext,
  onBack,
  currentStep,
  totalSteps,
}) => {
  const isLastStep = currentStep === totalSteps;

  return (
    <AppBar
      color="transparent"
      elevation={1}
      position="fixed"
      sx={{
        top: 'unset',
        backgroundColor: '#fdfefd',
        border: '1px solid #EAEAEA',
        borderRadius: '12px',
        transform: 'translateX(-2.5VW)',
        maxWidth: '95vw',
        bottom: 20,
      }}
    >
      <Box
        sx={{
          paddingY: 2,
          display: 'flex',
          justifyContent: 'end',
        }}
      >
        {currentStep > 1 ? (
          <Button
            variant="text"
            startIcon={<ChevronLeft />}
            disableRipple
            disableFocusRipple
            onClick={onBack}
            sx={{
              color: '#963f2b',
              fontWeight: 700,
              padding: '8px 16px',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
          >
            Back
          </Button>
        ) : (
          <div />
        )}
        <Button
          variant="text"
          endIcon={<ChevronRight />}
          disableRipple
          disableFocusRipple
          onClick={onNext}
          disabled={isLastStep}
          sx={{
            color: '#963f2b',
            fontWeight: 700,
            padding: '8px 16px',
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
        >
          {'Next'}
        </Button>
      </Box>
    </AppBar>
  );
};
