import { TextField, InputAdornment } from '@mui/material';
import { BaseInputProps } from '../Input';

export interface ValueProps extends BaseInputProps {
  value: string | number;
  onChange: (value: string) => void;
}

const CurrencyField = ({
  value,
  onChange,
  error,
  errorMessage,
  required,
  disabled,
  label,
  helperText,
  ...props
}: ValueProps) => (
  <TextField
    {...props}
    label={label}
    value={new Intl.NumberFormat('en-US').format(Number(value) || 0)}
    onChange={(e) => onChange(e.target.value.replace(/[^0-9]/g, ''))}
    variant="standard"
    color="secondary"
    required={required}
    disabled={disabled}
    error={error}
    helperText={error ? errorMessage : helperText}
    fullWidth
    InputProps={{
      startAdornment: <InputAdornment position="start">$</InputAdornment>,
    }}
  />
);

export { CurrencyField };
