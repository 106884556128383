import { ServerError, ServerParseError } from "@apollo/client";
import { onError } from "@apollo/client/link/error";

function handleNetworkErrors(networkError: ServerError | ServerParseError) {
    let ignoreErrors = false;
    switch (networkError.statusCode) {
        case 401:
        case 403:
            ignoreErrors = true;
            console.error("Error: Authorization",
                networkError.name,
                networkError.statusCode,
                networkError.response.url,
                networkError.message,
            );
            break;
        case 404:
            ignoreErrors = true;
            console.error(
                "Error: NotFound",
                networkError.name,
                networkError.statusCode,
                networkError.response.url,
                networkError.message,
            );
            break;
        case 500:
            ignoreErrors = true;
            console.error("Error: Authorization",
                networkError.name,
                networkError.statusCode,
                networkError.response.url,
                networkError.message,
            );
            break;
        default:
            console.error(
                "Error: UnknownNetworkError",
                networkError.name,
                networkError.statusCode,
                networkError.response.url,
                networkError.message,
            );
    }
    return ignoreErrors;
}

export const errorLink = onError(({ networkError, graphQLErrors, operation, response }) => {
    graphQLErrors?.forEach(({
        message,
        locations,
        path,
    }) => {
        const errorMessage = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        console.error(errorMessage);
    });
    if (
        networkError &&
        'statusCode' in networkError
    ) {
        handleNetworkErrors(networkError)
    }
});
