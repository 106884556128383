import React from 'react';
import { Box } from '@mui/material';
import { Field, FormatType } from '@flywl/core';

interface DataFieldProps {
  name?: string;
  value: string | number;
  orientation?: 'horizontal' | 'vertical';
  valueVariant?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | 'caption'
    | 'button'
    | 'overline'
    | undefined;
  valueColor?: string;
  format?: FormatType;
}

export const DataField: React.FC<DataFieldProps> = ({
  name = '',
  value,
  orientation = 'horizontal',
  valueVariant = 'body2',
  valueColor = '#4f4f4f',
  format,
}) => {
  return (
    <Box
      id={name}
      sx={{
        display: 'flex',
        flexDirection: orientation === 'horizontal' ? 'row' : 'column',
        alignItems: orientation === 'horizontal' ? 'center' : 'flex-start',
        justifyContent: 'space-between',
        margin: '8px 0',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          mb: orientation === 'vertical' ? 1 : 0,
          mr: orientation === 'horizontal' ? 1 : 0,
        }}
      >
        <Field.Label name={name} variant="h6" />
      </Box>

      <Field.Value
        value={value}
        variant={valueVariant}
        sx={{ color: valueColor }}
        format={format}
      />
    </Box>
  );
};
