import { setContext } from '@apollo/client/link/context';
import { getSession } from '../../../CognitoProvider/CognitoProvider';

type JWTAuth = {
  type: 'bearer';
  Authorization: string;
};

type HasuraAdminAuth = {
  type: 'hasura';
  'x-hasura-admin-secret': string;
  'x-hasura-role'?: string;
  'x-hasura-user-id'?: string;
};

export type AuthLinkOptions = {
  credentials: JWTAuth | HasuraAdminAuth;
};

export function createAuthLink({ credentials }: AuthLinkOptions) {
  return setContext((_, { headers }) => {
    const { type, ...authHeaders } = credentials;
    return {
      headers: {
        ...authHeaders,
        Authorization: `Bearer ${getSession().tokens.idToken}`,
        'Content-Type': 'application/json',
        ...headers,
      },
    };
  });
}
