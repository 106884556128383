// src/components/TextInputField/TextInputField.tsx
import React from 'react';
import { TextField, TextFieldProps } from '@mui/material';

export type TextInputFieldProps = TextFieldProps & {
  name: string;
  label: string;
  type?: string;
  value: string;
  helperText?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const TextInputField: React.FC<TextInputFieldProps> = ({
  name,
  label,
  type = 'text',
  value,
  helperText,
  onChange,
  ...rest
}) => (
  <TextField
    {...rest}
    fullWidth
    label={label}
    margin="normal"
    name={name}
    type={type}
    onChange={onChange}
    value={value}
    helperText={helperText}
    variant="standard"
    color="secondary"
  />
);
