import { Typography } from '@mui/material';

export const Title = () => {
  return (
    <Typography
      fontFamily={'Inter'}
      fontWeight={'600'}
      fontSize={'0.875rem'}
      lineHeight={'1.375rem'}
    >
      Overall Results
    </Typography>
  );
};
