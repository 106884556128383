import React from 'react';
import { CardContent } from '@mui/material';
import CloudIcon from '@mui/icons-material/CloudDownloadOutlined';
import InsightsIcon from '@mui/icons-material/Insights';
import VerifiedIcon from '@mui/icons-material/VerifiedOutlined';
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import { Card, Field, FormatType } from '@flywl/core';

interface DataMetricsCardProps {
  name: string;
  iconName: 'cloud' | 'insights' | 'verified' | 'attachMoney';
  data: string | number;
  format?: FormatType;
}

export const DataMetricsCard: React.FC<DataMetricsCardProps> = ({
  name,
  iconName,
  data,
  format,
}) => {
  const getIcon = (name: DataMetricsCardProps['iconName']) => {
    const iconStyle = { width: 38, height: 38, color: 'secondary.main' };
    switch (name) {
      case 'cloud':
        return <CloudIcon data-testid="cloud-icon" sx={iconStyle} />;
      case 'insights':
        return <InsightsIcon data-testid="insights-icon" sx={iconStyle} />;
      case 'verified':
        return <VerifiedIcon data-testid="verified-icon" sx={iconStyle} />;
      case 'attachMoney':
        return (
          <LocalAtmOutlinedIcon data-testid="attachMoney-icon" sx={iconStyle} />
        );
      default:
        return null;
    }
  };

  return (
    <Card
      sx={{
        maxHeight: 104,
        height: '100%',
        borderRadius: '12px',
        borderColor: '#eaeaea',
        backgroundColor: '#FDFEFD',
        padding: 2,
        marginBottom: 5,
        width: '300px',
      }}
    >
      <CardContent>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: 16,
          }}
        >
          <Field.Label name={name} variant="h5" />
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 16,
          }}
        >
          {getIcon(iconName)}
          <Field.Value value={data} format={format} variant="h1" />
        </div>
      </CardContent>
    </Card>
  );
};
