import { useState } from 'react';
import { TableComponent, ProductType, HeadCell } from '@flywl/blocks';
import { Skeleton } from '@mui/material';
import { useInventoryAssignmentQuery, useProviderQuery } from '@flywl/provider';

interface ISVTableProps {
  provider: 'AWS' | 'GCP' | 'AZURE';
}

type SortOptions = keyof Omit<Omit<Omit<ProductType, 'tags'>, 'icon'>, 'cost'>;

export const ISVTable = ({ provider }: ISVTableProps) => {
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<SortOptions>('product');
  const { data: providerData } = useProviderQuery();
  const providerId = providerData?.provider.find(
    (p) => p.name === provider
  )?.id;
  const { loading, data } = useInventoryAssignmentQuery({
    variables: { where: { provider_id: { _eq: providerId } } },
    skip: !providerId,
  });

  if (!data || loading) return <Skeleton />;

  const formattedData = data?.inventory_assignment?.map((item) => {
    return {
      icon: item.inventory_line_item.matching.find(({ logo }) => !!logo)?.logo,
      product: item.inventory_line_item.solution_name || '',
      vendor: item.inventory_line_item.vendor || '',
      cost: item.inventory_line_item.cost,
      id: item.id,
    } as ProductType;
  });

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property as SortOptions);
  };

  const headCells: HeadCell[] = [
    { id: 'product', label: 'Product ', sortable: true },
    { id: 'vendor', label: 'Vendor', sortable: true },
    { id: 'cost', label: 'Cost', sortable: false },
  ];

  return (
    <TableComponent
      orderBy={orderBy}
      order={order}
      handleRequestSort={handleRequestSort}
      filteredData={formattedData || []}
      isLoading={loading}
      headCells={headCells}
    />
  );
};
