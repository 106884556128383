import React from 'react';
import {
  Box,
  Table,
  TableContainer,
  TablePagination,
  Paper,
  CircularProgress,
} from '@mui/material';
import { ProviderFilter } from './ProviderFilter';
import { TableHeadComponent } from './TableHeadComponent';
import { TableBodyComponent } from './TableBodyComponent';
import { HeadCell, ProductType } from './types/product-table.types';
import { Field } from '@flywl/core';

type ProductTableProps = {
  name?: string;
  orderBy: keyof ProductType;
  order: 'asc' | 'desc';
  filterOptions?: string[];
  handleRequestSort: (value: string) => void;
  filteredData?: ProductType[];
  filter?: string;
  setFilter?: React.Dispatch<React.SetStateAction<string>>;
  isLoading?: boolean;
  headCells?: HeadCell[];
};

export const TableComponent: React.FC<ProductTableProps> = ({
  name,
  orderBy,
  order,
  filterOptions,
  handleRequestSort,
  filteredData,
  filter,
  setFilter,
  isLoading,
  headCells,
}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const sortedData =
    filteredData &&
    filteredData
      .filter((item) => item !== null && item !== undefined)
      .sort((a, b) => {
        const valueA = a[orderBy] ?? '';
        const valueB = b[orderBy] ?? '';

        if (valueA < valueB) {
          return order === 'asc' ? -1 : 1;
        }
        if (valueA > valueB) {
          return order === 'asc' ? 1 : -1;
        }
        return 0;
      });

  return (
    <Paper
      sx={{
        padding: '24px',
        borderRadius: '12px',
        width: 'full',
        marginTop: 3,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 2,
        }}
      >
        {name && <Field.Label name={name} variant="h4" />}
        {filter && setFilter && (
          <ProviderFilter
            value={filter}
            filterOptions={filterOptions}
            onChange={setFilter}
          />
        )}
      </Box>
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '300px',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <TableContainer>
            <Table sx={{ minWidth: '100%' }}>
              <TableHeadComponent
                orderBy={orderBy}
                order={order}
                handleRequestSort={handleRequestSort}
                headCells={headCells}
              />
              <TableBodyComponent
                data={sortedData || []}
                page={page}
                rowsPerPage={rowsPerPage}
              />
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={filteredData?.length || 0}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
            sx={{
              marginTop: 2,
              fontSize: '12px',
              '& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows':
                {
                  fontWeight: 'normal',
                  fontSize: '12px',
                },
            }}
          />
        </>
      )}
    </Paper>
  );
};

export default TableComponent;
