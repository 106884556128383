import { ApolloClient } from '@apollo/client';
import { InventoryFile } from '../types/InventoryFile.type';
import { cleanInventoryFile, ignoreDateValidations } from './cleanInventorFile';
import {
  ADD_MULTIPLE_INVENTORY_LINE_ITEMS,
  DELETE_INVENTORY_LINE_ITEM,
  GET_PROVIDERS,
  ProvidersData,
  AddMultipleInventoryLineItemsResponse,
} from '@flywl/data-acess/graphql';
import { chopData } from './chopData';
import { getSession } from '@flywl/provider';

export const handleFileInformation = async (
  data: InventoryFile[],
  invalidData: InventoryFile[],
  apolloClient: ApolloClient<object>
) => {
  const { data: providers, error } = await apolloClient.query<ProvidersData>({
    query: GET_PROVIDERS,
    fetchPolicy: 'cache-first',
  });

  if (error) {
    console.error('Error fetching providers:', error);
    throw error;
  }

  // Remove the next call when date validations become mandatory
  const ignoreValidations = ignoreDateValidations(data, invalidData);

  const cleanedData = cleanInventoryFile(ignoreValidations, providers);

  try {
    const session = getSession();
    // There is no other way to get the user id
    const userId = session.userSub;
    await apolloClient.mutate({
      mutation: DELETE_INVENTORY_LINE_ITEM,
      variables: {
        id: userId,
      },
    });
  } catch (error) {
    console.error('Error deleting inventory line items:', error);
  }

  try {
    const chopedData = chopData(cleanedData);
    const inventoryBatch = []
    for (const smallBatch of chopedData) {
      inventoryBatch.push(apolloClient.mutate<AddMultipleInventoryLineItemsResponse>({
        mutation: ADD_MULTIPLE_INVENTORY_LINE_ITEMS,
        variables: { items: smallBatch },
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
      }));

    }
    await Promise.all(inventoryBatch);
  } catch (error) {
    console.error('Operation failed:', error);
    throw error;
  }
};
