import React from 'react';
import { Box } from '@mui/material';
import { Field } from '@flywl/core';
import { FormatType } from '@flywl/utils-data';

type MetricsSectionType = {
  metrics: {format:FormatType , name:string, value: string | number  }[];
};

export const MetricsSection: React.FC<MetricsSectionType> = ({ metrics }) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
    {metrics.map((metric, index) => (
      <Box key={index}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
          <Field.Value value={metric.value} format={metric.format} variant="h1"/>
          <Field.Label name={metric.name} variant="subtitle1" />
        </Box>
      </Box>
    ))}
  </Box>
);
