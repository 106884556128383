import { Box } from '@mui/material';
import { MarkteplaceCard } from './components/MarkteplaceReport';

export const MetricsMarketplace = () => {
  return (
    <Box
      sx={{
        padding: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: '0.1rem',
        borderColor: '#D7DED7',
        borderRadius: '12px',
        width: '100%',
      }}
    >
      <MarkteplaceCard marketplace="AWS" />
      <MarkteplaceCard marketplace="GCP" />
      <MarkteplaceCard marketplace="AZURE" />
    </Box>
  );
};
