import React from 'react';
import { Card } from '@mui/material';
import { PieChart } from '@mui/x-charts/PieChart';
import { Field } from '@flywl/core';

type PieChartDataType = {
  value: number;
  label: string;
  color: string;
}[];

type ProductChartProps = {
  pieChartData: PieChartDataType;
};

export const ProductChart: React.FC<ProductChartProps> = ({ pieChartData }) => {
  return (
    <Card
      sx={{
        padding: 3,
        borderRadius: 2,
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        maxHeight: '316px',
        width: '100%',
        height: '100%',
        boxShadow: 'none',
        border: '1px solid #EAEAEA',
      }}
    >
      <Field.Label name="products_bought" variant="h5" />

      <PieChart
        series={[
          {
            data: pieChartData,
            innerRadius: 50,
          },
        ]}
        width={450}
        height={200}
      />
    </Card>
  );
};
