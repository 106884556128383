import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  Typography,
  Box,
  Radio,
  TableFooter,
  TablePagination,
  Chip,
} from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { useMutation } from '@apollo/client';
import {
  ADD_ASSIGNMENT_MUTATION,
  REFETCH_ON_INV_ASSIGN_OR_EDP_CHANGE,
  REMOVE_ASSIGNMENT_MUTATION,
} from '@flywl/data-acess/graphql';
import { Field } from '@flywl/core';

interface ProviderOption {
  name: string;
  id: string;
  matchId: string;
}

interface Product {
  id: string;
  name: string;
  company: string;
  cost: string;
  currentSupplier: string;
  currentSupplierId: string;
  currentMatchId: string;
  standardSupplier: string;
  standardSupplierId: string;
  standardMatchId: string;
  providers: ProviderOption[];
  logo: string;
}

interface ProductTableProps {
  products: Product[];
  isvprovider: string | null;
}

const headerCellStyles = {
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '24px',
  letterSpacing: '0.17px',
  padding: '16px',
  borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
};

export const TableProductAssignment: React.FC<ProductTableProps> = ({
  products,
  isvprovider,
}) => {
  const [productList, setProductList] = useState<Product[]>([]);
  const [mutatingProductIds, setMutatingProductIds] = useState<{
    [productId: string]: boolean;
  }>({});

  const [selectedProviders, setSelectedProviders] = useState<{
    [productId: string]: string | null;
  }>({});

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [addAssignment, { loading: addLoading }] = useMutation(
    ADD_ASSIGNMENT_MUTATION,
    { refetchQueries: REFETCH_ON_INV_ASSIGN_OR_EDP_CHANGE }
  );
  const [removeAssignment, { loading }] = useMutation(
    REMOVE_ASSIGNMENT_MUTATION,
    { refetchQueries: REFETCH_ON_INV_ASSIGN_OR_EDP_CHANGE }
  );

  useEffect(() => {
    setProductList(products);

    const initialSelectedProviders = products.reduce((acc, product) => {
      acc[product.id] =
        product.currentSupplier || product.standardSupplier || null;
      return acc;
    }, {} as { [productId: string]: string | null });

    setSelectedProviders(initialSelectedProviders);
  }, [products]);

  const handleProviderChange = async (
    product: Product,
    providerName: string
  ) => {
    try {
      setMutatingProductIds((prev) => ({ ...prev, [productId]: true }));

      const productId = product.id;
      const currentSelectedProvider = selectedProviders[productId];

      let newSelectedProvider: string | null;

      if (currentSelectedProvider === providerName) {
        newSelectedProvider = null;
      } else {
        newSelectedProvider = providerName;
      }
      const previousProvider = product.providers?.find(
        (p) => p.name === currentSelectedProvider
      );
      if (
        currentSelectedProvider !== 'ISV' &&
        currentSelectedProvider &&
        currentSelectedProvider === providerName
      ) {
        if (previousProvider && previousProvider.matchId) {
          removeAssignment({
            variables: {
              inventoryId: productId,
              matchId: previousProvider.matchId,
              origin: 'UI',
            },
            refetchQueries: REFETCH_ON_INV_ASSIGN_OR_EDP_CHANGE,
          });
          if (
            product.standardSupplier !== 'ISV' &&
            product.currentMatchId === ''
          ) {
            addAssignment({
              variables: {
                inventoryId: productId,
                matchId: null,
                origin: 'UI',
                providerId: isvprovider,
              },
              refetchQueries: REFETCH_ON_INV_ASSIGN_OR_EDP_CHANGE,
            });
          }
        }
      } else if (product.currentMatchId || product.currentSupplier) {
        await removeAssignment({
          variables: {
            inventoryId: productId,
            matchId: product.currentMatchId || null,
            origin: 'UI',
          },
          refetchQueries: REFETCH_ON_INV_ASSIGN_OR_EDP_CHANGE,
        });
      }

      if (newSelectedProvider) {
        const newProvider = product.providers?.find(
          (p) => p.name === newSelectedProvider
        );
        if (
          newProvider &&
          newProvider.matchId &&
          newProvider.id !== product.standardSupplierId
        ) {
          await addAssignment({
            variables: {
              inventoryId: productId,
              matchId: newProvider.matchId,
              origin: 'UI',
              providerId: newProvider.id,
            },
            refetchQueries: REFETCH_ON_INV_ASSIGN_OR_EDP_CHANGE,
          });
        }
      }

      setSelectedProviders((prevState) => ({
        ...prevState,
        [productId]: newSelectedProvider,
      }));
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setMutatingProductIds((prev) => {
        const updated = { ...prev };
        delete updated[product.id];
        return updated;
      });
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer
      component={Paper}
      sx={{ borderRadius: '8px', overflow: 'hidden' }}
    >
      <Box sx={{ padding: 2 }}>
        <Box
          sx={{
            padding: 2,
          }}
        >
          <Typography variant="body2" sx={{ mt: 6, mb: 4 }}>
            Product assignment
          </Typography>
        </Box>
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={headerCellStyles}>
              <Typography variant="body2" sx={{ fontWeight: 500 }}>
                Product
              </Typography>
            </TableCell>
            <TableCell sx={headerCellStyles}>
              <Typography variant="body2" sx={{ fontWeight: 500 }}>
                Cost
              </Typography>
            </TableCell>
            <TableCell sx={headerCellStyles}>AWS</TableCell>
            <TableCell sx={headerCellStyles}>GCP</TableCell>
            <TableCell sx={headerCellStyles}>Azure</TableCell>
            <TableCell sx={{ ...headerCellStyles, textAlign: 'center' }}>
              Current supplier
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {productList
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((product) => (
              <TableRow key={product.id}>
                <TableCell>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar
                      src={product.logo}
                      alt={product.name}
                      onError={(e) => {
                        e.currentTarget.onerror = null;
                      }}
                    />{' '}
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginLeft: 2,
                      }}
                    >
                      <Typography variant="body2" sx={{ fontWeight: 600 }}>
                        {product.name}
                      </Typography>
                      <Typography variant="caption" color="textSecondary">
                        {product.company}
                      </Typography>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell>
                  <Field.Value
                    value={product.cost}
                    format={'currency'}
                    variant="body2"
                  />
                </TableCell>
                <TableCell>
                  <Radio
                    color="secondary"
                    checked={selectedProviders[product.id] === 'AWS'}
                    onClick={() => handleProviderChange(product, 'AWS')}
                    disabled={
                      mutatingProductIds[product.id] ||
                      !product.providers.some((p) => p.name === 'AWS')
                    }
                  />
                </TableCell>
                <TableCell>
                  <Radio
                    color="secondary"
                    checked={selectedProviders[product.id] === 'GCP'}
                    onClick={() => handleProviderChange(product, 'GCP')}
                    disabled={
                      mutatingProductIds[product.id] ||
                      !product.providers.some((p) => p.name === 'GCP')
                    }
                  />
                </TableCell>
                <TableCell>
                  <Radio
                    color="secondary"
                    checked={selectedProviders[product.id] === 'AZURE'}
                    onClick={() => handleProviderChange(product, 'AZURE')}
                    disabled={
                      mutatingProductIds[product.id] ||
                      !product.providers.some((p) => p.name === 'AZURE')
                    }
                  />
                </TableCell>
                <TableCell>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Chip
                      label={product.standardSupplier}
                      variant="outlined"
                      style={{
                        backgroundColor: '#ebf7f3',
                        color: '#297e62',
                        borderRadius: 12,
                      }}
                    />
                  </Box>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              count={productList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};
