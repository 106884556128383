import React from 'react';
import { Typography, Link, LinkOwnProps } from '@mui/material';
import { styled } from '@mui/material/styles';

type BottomLinkProps = LinkOwnProps & {
  children?: React.ReactNode;
  text?: string;
  disabled?: boolean;
  linkText: string;
  onLinkClick: () => void;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
};

const StyledButton = styled('button')(
  ({ disabled }: { disabled?: boolean }) => ({
    cursor: disabled ? 'default' : 'pointer',
    color: disabled ? '#2C292E7A' : 'secondary.main',
    textDecoration: disabled ? 'none' : 'underline',
    background: 'none',
    border: 'none',
    padding: 0,
    fontSize: 'inherit',
    fontFamily: 'inherit',
    textAlign: 'left',
  })
);

export const BottomLink: React.FC<BottomLinkProps> = ({
  text,
  linkText,
  onLinkClick,
  disabled = false,
  align = 'center',
  ...rest
}) => {
  return (
    <Typography variant="body1" align={align}>
      {text}{' '}
      {disabled ? (
        <StyledButton type="button" disabled={disabled}>
          {linkText}
        </StyledButton>
      ) : (
        <Link
          component="button"
          onClick={onLinkClick}
          type="button" 
          {...rest}
          sx={{
            cursor: 'pointer',
            color: 'secondary.main',
            textDecoration: 'underline',
          }}
        >
          {linkText}
        </Link>
      )}
    </Typography>
  );
};
