import { useQuery } from '@apollo/client';
import {
  EdpCommitmentResponse,
  EDP_COMPLETE_QUERY,
} from '@flywl/data-acess/graphql';
import { Box, CircularProgress } from '@mui/material';
import { TablePoint } from './TablePoint';

interface EdpData {
  amount: string | number;
  custom_drawdown: string | number;
  direct_spend: string | number;
}

export const ECTable = ({
  provider,
}: {
  provider: 'AWS' | 'GCP' | 'AZURE';
}) => {
  const { data, loading, error } = useQuery<EdpCommitmentResponse>(
    EDP_COMPLETE_QUERY,
    {
      variables: { provider },
    }
  );

  if (loading) return <CircularProgress />;
  if (error) return <h2>ERROR</h2>;

  const edpCommitment: EdpData = {
    amount: data?.edp_commitment?.[0]?.amount ?? 0,
    custom_drawdown: data?.edp_commitment?.[0]?.custom_drawdown ?? 0,
    direct_spend: data?.edp_commitment?.[0]?.direct_spend ?? 0,
  };

  const durationYears =
    data?.metrics_duration_of_commitment_years_by_platform?.[0]
      ?.duration_in_years ?? 0;
  const drawdownOverage = data?.metrics_drawdown_overage?.[0]?.result ?? 0;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        padding: '1rem',
        backgroundColor: 'white',
        borderRadius: '8px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          paddingBottom: '1rem',
        }}
      >
        <TablePoint
          name="enterprise_commitment"
          value={edpCommitment.amount}
          format="currency"
        />
        <TablePoint
          name="years_of_commitment"
          value={durationYears}
          format="int"
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          paddingBottom: '1rem',
        }}
      >
        <TablePoint
          name="drawdown_percentage"
          value={edpCommitment.custom_drawdown}
          format="percentage"
        />
        <TablePoint
          name="utilized_spend"
          value={edpCommitment.direct_spend}
          format="currency"
        />
      </Box>

      <Box sx={{ display: 'flex' }}>
        <TablePoint
          name="drawdown_overage"
          value={drawdownOverage}
          format="currency"
        />
      </Box>
    </Box>
  );
};
