import { DeltaOverallResults, MetricsMarketplace } from '@flywl/modules';
import { Box, Typography } from '@mui/material';
import { text } from './reportsFlowText';

export function Report() {
  return (
    <Box sx={{ gap: '1.5rem', padding: '0.5rem' }}>
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'flex-start'}
        width={'100%'}
      >
        <Typography variant="h1">Reports</Typography>
        <Typography variant="body1" mb={4}>
          {text}
        </Typography>
      </Box>
      <Box display={'flex'} width={'100%'}>
        <Box display={'flex'} width={'50%'}>
          <MetricsMarketplace />
        </Box>
        <Box display={'flex'} width={'50%'}>
          <DeltaOverallResults />
        </Box>
      </Box>
    </Box>
  );
}

export default Report;
