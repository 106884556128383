import { ApolloLink } from "@apollo/client/core";
import { AuthLinkOptions, createAuthLink } from "./auth";
import { createHttpLink, HttpLinkOptions } from "./http";
import { errorLink } from "./error";
import loggerLink from "./logger";

export type LinkOptions = HttpLinkOptions & AuthLinkOptions

export function createLinks(options: LinkOptions) {
    return ApolloLink.from([
        loggerLink,
        errorLink,
        createAuthLink({ credentials: options.credentials }),
        createHttpLink(options),
    ]);
}