import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { DataField } from '../DataField/DataField';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { FormatType } from '@flywl/core';
import Grid from '@mui/material/Grid2';
interface SummaryTableProps {
  columns: {
    title?: string;
    fields: {
      name: string;
      value: string | number;
      isHighlighted?: boolean;
      isNegative?: boolean;
      valueColor?: string;
      format?: FormatType;
    }[];
    summary?: {
      name: string;
      value: string | number;
      isDropdownIcon?: boolean;
      valueColor?: string;
      format?: FormatType;
    };
  }[];
}

export const SummaryTable: React.FC<SummaryTableProps> = ({ columns }) => {
  return (
    <Grid container>
      <Grid size={12} container justifyContent="space-between">
        <Grid size={8} container direction="column">
          {columns[0].title && (
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: 'bold', mb: 1, textAlign: 'end' }}
            >
              {columns[0].title}
            </Typography>
          )}
          {columns[0].fields.map((field, index) => (
            <DataField
              key={index}
              name={field.name}
              value={field.value}
              orientation="horizontal"
              valueVariant="body2"
              valueColor={field.valueColor}
              format={field.format}
            />
          ))}
        </Grid>

        <Divider
          orientation="vertical"
          flexItem
          sx={{
            mx: 4,
            alignSelf: 'stretch',
          }}
        />

        <Grid size={3} container direction="column">
          {columns[1].title && (
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: 'bold',
                mb: 1,
                textAlign: 'end',
              }}
            >
              {columns[1].title}
            </Typography>
          )}
          {columns[1].fields.map((field, index) => (
            <DataField
              key={index}
              name={field.name}
              value={field.value}
              orientation="horizontal"
              valueVariant="body2"
              valueColor={field.valueColor}
              format={field.format}
            />
          ))}
        </Grid>
      </Grid>

      <Grid size={12}>
        <Divider sx={{ my: 4 }} flexItem />
      </Grid>

      <Grid container size={12} justifyContent="space-between">
        {columns[0].summary && (
          <Grid size={8}>
            <DataField
              name={columns[0].summary.name}
              value={columns[0].summary.value}
              orientation="horizontal"
              valueVariant="body2"
              valueColor={columns[0].summary.valueColor}
              format={columns[0].summary.format}
            />
          </Grid>
        )}
        <Divider
          orientation="vertical"
          flexItem
          sx={{
            mx: 4,
            alignSelf: 'stretch',
          }}
        />
        {columns[1].summary && (
          <Grid size={3} alignItems="flex-end">
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              {columns[1].summary.isDropdownIcon && (
                <ArrowDropDownIcon sx={{ color: 'secondary.main', mr: 0.5 }} />
              )}
              <DataField
                name={columns[1].summary.name}
                value={columns[1].summary.value}
                orientation="horizontal"
                valueVariant="body2"
                valueColor={columns[1].summary.valueColor}
                format={columns[1].summary.format}
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
