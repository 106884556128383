import { IconButton, Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export const EnterpriseCommitmentInfo = () => {
  return (
    <Tooltip
      title={
        <div style={{ padding: '8px 0' }}>
          <div>
            <strong>Enterprise Commitment (EC):</strong> The total spend
            outlined in your contract with a cloud marketplace.
          </div>
          <div style={{ marginTop: '8px' }}>
            <strong>Native Spend: </strong> The total amount spent on your
            native services.
          </div>
          <div style={{ marginTop: '8px' }}>
            <strong>Commitment Discount:</strong> The discount rate offered by
            each cloud marketplace.
          </div>
          <div style={{ marginTop: '8px' }}>
            <strong>Drawdown:</strong> The maximum percentage that can be
            deducted from your EC through independent software products.
          </div>
          <div style={{ marginTop: '8px' }}>
            <strong>Start/End Date:</strong> Use these dates to determine the
            duration of your EC contract.
          </div>
        </div>
      }
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: '#2c2a2e',
            '& .MuiTooltip-arrow': {
              color: '#2c2a2e',
            },
            maxWidth: '20vw',
            p: 2,
            fontSize: '12px',
            borderRadius: 2,
          },
        },
      }}
    >
      <IconButton
        size="small"
        sx={{
          p: 0,
          '&:hover': { bgcolor: 'transparent' },
        }}
      >
        <InfoOutlinedIcon
          sx={{
            fontSize: 18,
            color: 'text.secondary',
          }}
        />
      </IconButton>
    </Tooltip>
  );
};
