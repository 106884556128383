import React from 'react';
import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { ProductType, HeadCell } from './types/product-table.types';

type TableHeadComponentProps = {
  orderBy: keyof ProductType;
  order: 'asc' | 'desc';
  handleRequestSort: (property: keyof ProductType) => void;
  headCells?: HeadCell[];
};

export const TableHeadComponent: React.FC<TableHeadComponentProps> = ({
  orderBy,
  order,
  handleRequestSort,
  headCells,
}) => {
  return (
    <TableHead sx={{ width: '100%' }}>
      <TableRow>
        {headCells?.map((headCell) => (
          <TableCell
            key={headCell.id}
            sx={{
              color: '#333',
              borderBottom: '2px solid #e0e0e0',
              fontSize: '14px',
              padding: '16px',
              fontWeight: 'bold',
            }}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={() => handleRequestSort(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
