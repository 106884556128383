import { SummaryTable } from '@flywl/blocks';

import { useQuery } from '@apollo/client';
import {
  ISV_BEFORE_AND_AFTER_QUERY,
  MetricsQueryResponse,
  OVERALL_RESULTS_QUERY,
  OverallResultQueryResponse,
} from '@flywl/data-acess/graphql';
import { CircularProgress } from '@mui/material';
import { dataToColumns } from '../utils/dataToColumns';

export const DeltaOverallTable = () => {
  const { data, loading, error } = useQuery<OverallResultQueryResponse>(
    OVERALL_RESULTS_QUERY
  );

  const {
    data: isv_before_after_data,
    loading: isv_before_after_loading,
    error: isv_before_after_error,
  } = useQuery<MetricsQueryResponse>(ISV_BEFORE_AND_AFTER_QUERY);

  if (loading || isv_before_after_loading) return <CircularProgress />;

  if (error || isv_before_after_error)
    return <div>Error: {error?.message}</div>;

  if (data && isv_before_after_data) {
    return (
      <SummaryTable columns={dataToColumns(data, isv_before_after_data)} />
    );
  }
};
