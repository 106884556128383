import { TextFieldProps } from '@mui/material';
import { CurrencyField } from './components/CurrencyField';
import { DateField } from './components/DateField';
import { PercentageField } from './components/PercentageField';

export interface BaseInputProps
  extends Omit<TextFieldProps, 'onChange' | 'value'> {
  label: string;
  error: boolean;
  errorMessage: string;
  helperText?: string;
  disabled?: boolean;
  required?: boolean;
}
// EXPORTER
type InputType = {
  Currency: typeof CurrencyField;
  Date: typeof DateField;
  Percentage: typeof PercentageField;
};

export const Input: InputType = {
  Currency: CurrencyField,
  Date: DateField,
  Percentage: PercentageField,
};
