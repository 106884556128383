import { CommitmentData } from '@flywl/blocks';
import { Box, Button } from '@mui/material';
import { useSaveAllCommitments } from '../hooks/useSaveCommitment';
import { useMutation } from '@apollo/client';
import { DeleteEdpCommitmentDocument } from '@flywl/provider';
import {
  GET_METRICS_ENTERPRISE_COMMITMENT,
  REFETCH_ON_INV_ASSIGN_OR_EDP_CHANGE,
} from '@flywl/data-acess/graphql';

interface CancelAndExitButtonsProps {
  awsData: CommitmentData;
  gcpData: CommitmentData;
  azureData: CommitmentData;
  close: () => void;
}

export const SaveAndCancelButtons = ({
  awsData,
  gcpData,
  azureData,
  close,
}: CancelAndExitButtonsProps) => {
  const { saveAllCommitments } = useSaveAllCommitments();

  const [deleteCommitment] = useMutation(DeleteEdpCommitmentDocument, {
    refetchQueries: REFETCH_ON_INV_ASSIGN_OR_EDP_CHANGE,
  });

  const performInitialDelete = async (ids: string[]) => {
    try {
      await deleteCommitment({
        variables: {
          where: { provider_id: { _nin: ids } },
        },
        refetchQueries: REFETCH_ON_INV_ASSIGN_OR_EDP_CHANGE,
      });
      console.log('Records deleted successfully');
    } catch (error) {
      console.error('Error performing initial delete:', error);
    }
  };

  const SaveInformation = async () => {
    if (
      !awsData.data.included &&
      !gcpData.data.included &&
      !azureData.data.included
    ) {
      return;
    }

    if (
      (awsData.data.included && !awsData.isValid) ||
      (gcpData.data.included && !gcpData.isValid) ||
      (azureData.data.included && !azureData.isValid)
    ) {
      return;
    }

    try {
      await saveAllCommitments(awsData, gcpData, azureData);
      const ids: string[] = [];

      if (awsData.data.included) {
        ids.push(awsData.providerId);
      }
      if (gcpData.data.included) {
        ids.push(gcpData.providerId);
      }
      if (azureData.data.included) {
        ids.push(azureData.providerId);
      }

      await performInitialDelete(ids);
      close();
    } catch (error) {
      console.error('Error saving commitment:', error);
    }
  };

  return (
    <Box
      gap={2}
      sx={{
        paddingRight: '1rem',
        gap: '1rem',
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      <Button variant="outlined" color="secondary" onClick={close}>
        Cancel
      </Button>
      <Button variant="contained" color="secondary" onClick={SaveInformation}>
        Save
      </Button>
    </Box>
  );
};
