// src/components/RadioField/RadioField.tsx
import React from 'react';
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  FormHelperText,
  
} from '@mui/material';

export interface RadioFieldProps {
  name: string;
  label: string;
  options: string[];
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error?: string;
  color?:   'secondary'| 'primary';
}

export const RadioField: React.FC<RadioFieldProps> = ({
  name,
  label,
  options,
  value,
  onChange,
  error,
  color,
}) => (
  <FormControl error={Boolean(error)} component="fieldset" sx={{ mb: 2 }}>
    <Typography variant="h6" sx={{ mb: 1 }}>
      {label}
    </Typography>
    <RadioGroup name={name} value={value} onChange={onChange}>
      {options.map((option, idx) => (
        <FormControlLabel
          key={idx}
          value={option}
          control={<Radio color={color} />}
          label={option}
        />
      ))}
    </RadioGroup>
    {error && <FormHelperText>{error}</FormHelperText>}
  </FormControl>
);