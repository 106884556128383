import { Box } from '@mui/material';
import React from 'react';
import { ReactSpreadsheetImport } from 'react-spreadsheet-import';
import { Result } from 'react-spreadsheet-import/types/types';
import fields from './constants/fields';
import { handleFileInformation } from './utils/handleFileInformation';
import { InventoryFile } from './types/InventoryFile.type';
import { useApolloClient } from '@apollo/client';
import { uploadFileToS3 } from './utils/uploadFileToS3';
import { DeleteEdpCommitmentDocument } from '@flywl/provider';

type InventoryUploadProps = {
  open: boolean;
  onClose: () => void;
  onComplete: (...args: unknown[]) => void;
  activeStep: (...args: unknown[]) => void;
};

export function InventoryUpload({
  open,
  onClose: handleClose,
  onComplete,
  activeStep,
}: InventoryUploadProps) {
  const client = useApolloClient();



  return (
    <Box>
      <ReactSpreadsheetImport
        isOpen={open}
        onClose={handleClose}
        fields={fields}
        maxRecords={10000}
        autoMapDistance={3}
        maxFileSize={50000000}
        isNavigationEnabled
        autoMapSelectValues
        onSubmit={async (data: Result<string>, file: File): Promise<void> => {
          const dataToHandle = data.validData as unknown as InventoryFile[];
          const invalidData = data.invalidData as unknown as InventoryFile[];

          const isValid = dataToHandle.length > 0 ? true : false;
          await uploadFileToS3(file, isValid, client);
          await handleFileInformation(dataToHandle, invalidData, client);
          const result = await client.mutate({
            mutation: DeleteEdpCommitmentDocument,
            variables: { where: {} },
            refetchQueries: ["inventory_line_item"],
            
          });          
          onComplete();
          activeStep();
        }}
      />
    </Box>
  );
}

export default InventoryUploadProps;
