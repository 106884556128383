import React from 'react';
import { Card } from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import { Field } from '@flywl/core';

type BarChartComponentType = {
  series: { color: string; data: number[]; label?: string }[];
  xAxisData: string[];
};

export const BarChartComponent: React.FC<BarChartComponentType> = ({
  series,
  xAxisData,
}) => (
  <Card
    sx={{
      padding: 3,
      borderRadius: 2,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      maxHeight: '316px',
      width: '100%',
      height: '100%',
      boxShadow: 'none',
      border: '1px solid #EAEAEA',
    }}
  >
    <Field.Label name={'vendors_matched'} variant="h5" />

    <BarChart
      series={series}
      height={290}
      xAxis={[{ data: xAxisData, scaleType: 'band' }]}
      margin={{ top: 60, bottom: 30, left: 40, right: 10 }}
    />
  </Card>
);
