import { Field } from 'react-spreadsheet-import/types/types';

export const vendorTerms = [
  "ISV",
  "Reseller",
  "Supplier",
  "Partner",
  "Independent Software Vendor (ISV)",
  "Service Provider",
  "Vendor Name",
  "Technology Partner",
  "Provider",
  "OEM",
  "Vendor",
  "Supplier Record"
];

export const solutionNamesTerms = [
  "Software",
  "Service",
  "Solution",
  "Product Name",
  "Application Name",
  "Offering Title",
  "Software Solution",
  "Service Offering",
  "Technology Solution",
  "Service Name",
  "Product"
];

export const costTerms = [
  "Total",
  "Total Spend",
  "cost",
  "Price",
  "Estimated Spend",
  "Spend",
  "Amount",
  "Pricing Tier",
  "Billing Amount",
  "Vendor Bills"
];

export const startDateTerms = [
  "Start Date",
  "Start",
  "Contract Start Date",
  "Subscription Start Date",
  "Agreement Start Date",
  "Contract Effective Date",
  "Contract Start Date"
];

export const endDateTerms = [
  "End Date",
  "End",
  "Contract End Date",
  "Subscription End Date",
  "Agreement Expiry Date",
  "Renewal Date",
  "Expiration Date",
  "Contract End Date"
];

export const cloudProviderTerms = [
  "Vendor",
  "Marketplace",
  "Cloud Provider",
  "Cloud",
  "Provider",
  "Cloud Platform",
  "Cloud Marketplace",
  "Provider Name",
  "Cloud Vendor",
  "Vendor Type",
  "Supplier Type"
];


const fields: Field<string>[] = [
  {
    label: 'Cloud Provider',
    key: 'cloud_provider',
    alternateMatches: cloudProviderTerms,
    fieldType: {
      type: 'input',
    },
    example: 'AWS',
  },
  {
    label: 'Vendor',
    key: 'vendor',
    alternateMatches: vendorTerms,
    fieldType: {
      type: 'input',
    },
    example: 'My amazing vendor',
    validations: [
      {
        rule: 'required',
        errorMessage: 'Vendor name is required',
      },
    ],
  },
  {
    label: 'Product',
    key: 'solution_name',
    alternateMatches: solutionNamesTerms,
    fieldType: {
      type: 'input',
    },
    example: 'My amazing solution',
    validations: [
      {
        rule: 'required',
        errorMessage: 'Solution name is required',
      },
    ],
  },
  {
    label: 'Cost',
    key: 'cost',
    alternateMatches: costTerms,
    fieldType: {
      type: 'input',
    },
    example: '100',
    validations: [
      {
        rule: 'regex',
        errorMessage:
          'Cost must be a number with no negative signs',
          value: '^\\s*\\$?\\s*\\d{1,3}(?:[.,]\\d{3})*(?:[,.]\\d{2})|\\d{1,3}(?:[.,]\\d{3})*(?:[,.]\\d{2})?$'    
        },
    ],
  },
  {
    label: 'Contract Start Date',
    key: 'contract_start_date',
    alternateMatches: startDateTerms,
    fieldType: {
      type: 'input',
    },
    example: '2023-14-01',
    validations: [
      {
        rule: 'regex',
        value: '^(?:\\d{4}[-/](0[1-9]|1[0-2])[-/](0[1-9]|[12]\\d|3[01]))|((0[1-9]|1[0-2])[-/](0[1-9]|[12]\\d|3[01])[-/](\\d{4}|\\d{2}))|((\\d{1,2})[-/](\\d{1,2})[-/](\\d{4}|\\d{2}))$',
        errorMessage: 'Contract start date must be in the format YYYY-MM-DD, MM-DD-YYYY, YYYY/MM/DD, MM/DD/YYYY ',
      },
    ],
  },
  {
    label: 'Renewal Date',
    key: 'renewal_date',
    alternateMatches: endDateTerms,
    fieldType: {
      type: 'input',
    },
    example: '2023-14-01',
    validations: [
      {
        rule: 'regex',
        value: '^(?:\\d{4}[-/](0[1-9]|1[0-2])[-/](0[1-9]|[12]\\d|3[01]))|((0[1-9]|1[0-2])[-/](0[1-9]|[12]\\d|3[01])[-/](\\d{4}|\\d{2}))|((\\d{1,2})[-/](\\d{1,2})[-/](\\d{4}|\\d{2}))$',
        errorMessage: 'Renewal date must be in the format YYYY-MM-DD, MM-DD-YYYY, YYYY/MM/DD, MM/DD/YYYY ',
      },
    ],
  },
];

export default fields;
