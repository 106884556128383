import { TextField } from '@mui/material';
import { BaseInputProps } from '../Input';

export interface PercentageFieldProps extends BaseInputProps {
  value: string | number;
  onChange: (value: string) => void;
}

const PercentageField = ({
  value,
  onChange,
  error,
  errorMessage,
  required,
  disabled,
  label,
  helperText,
  ...props
}: PercentageFieldProps) => (
  <TextField
    {...props}
    label={label}
    error={error}
    helperText={error ? errorMessage : helperText}
    required={required}
    disabled={disabled}
    value={`${Number(value) || 0} %`}
    onChange={(e) => {
      const num = parseInt(e.target.value.replace(/[^0-9]/g, ''));
      onChange(num > 100 ? '100' : String(num || ''));
    }}
    variant="standard"
    color="secondary"
    fullWidth
  />
);

export { PercentageField };
