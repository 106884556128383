import { MetricsQueryResponse } from '@flywl/data-acess/graphql';

type functionResponse = {
  NumberOfItems: {
    nttcmpBefore: number;
    ttcmpBefore: number;
    nttcmpAfter: number;
    ttcmpAfter: number;
  };
  Amount: {
    nttcmpBefore: number;
    ttcmpBefore: number;
    nttcmpAfter: number;
    ttcmpAfter: number;
  };
};

export const calculationsFromMetricsQueryResponse = (
  data: MetricsQueryResponse
): functionResponse => {
  let nttcmpBeforeItems = 0;
  let ttcmpBeforeItems = 0;
  let nttcmpAfterItems = 0;
  let ttcmpAfterItems = 0;

  let nttcmpBeforeAmount = 0;
  let ttcmpBeforeAmount = 0;
  let nttcmpAfterAmount = 0;
  let ttcmpAfterAmount = 0;

  if (data.metrics_cost_isv_bought_without_a_cmp.length > 0) {
    nttcmpBeforeItems =
      data.metrics_cost_isv_bought_without_a_cmp[0].number_of_items;

    nttcmpBeforeAmount =
      data.metrics_cost_isv_bought_without_a_cmp[0].total_cost;
  }

  if (data.metrics_cost_isv_bought_through_the_cmp_before.length > 0) {
    ttcmpBeforeItems =
      data.metrics_cost_isv_bought_through_the_cmp_before[0].number_of_items;

    ttcmpBeforeAmount =
      data.metrics_cost_isv_bought_through_the_cmp_before[0].total_cost;
  }

  if (data.metrics_cost_isv_bought_through_the_cmp_after.length > 0) {
    ttcmpAfterItems =
      data.metrics_cost_isv_bought_through_the_cmp_after[0].number_of_items;

    ttcmpAfterAmount =
      data.metrics_cost_isv_bought_through_the_cmp_after[0].total_cost;

    nttcmpAfterItems = nttcmpBeforeItems - (ttcmpAfterItems - ttcmpBeforeItems);

    nttcmpAfterAmount =
      nttcmpBeforeAmount - (ttcmpAfterAmount - ttcmpBeforeAmount);
  }

  return {
    NumberOfItems: {
      nttcmpBefore: nttcmpBeforeItems,
      ttcmpBefore: ttcmpBeforeItems,
      nttcmpAfter: nttcmpAfterItems,
      ttcmpAfter: ttcmpAfterItems,
    },
    Amount: {
      nttcmpBefore: nttcmpBeforeAmount,
      ttcmpBefore: ttcmpBeforeAmount,
      nttcmpAfter: nttcmpAfterAmount,
      ttcmpAfter: ttcmpAfterAmount,
    },
  };
};
