import { ApolloLink } from "@apollo/client"
import { OperationDefinitionNode } from "graphql"
import formatMessage from "./formatter"
import logging from "./logging"

const loggerLink = new ApolloLink((operation, forward) => {
  const startTime = new Date().getTime()

  return forward(operation).map((result) => {
    const operationType = (operation.query.definitions[0] as OperationDefinitionNode).operation

    const ellapsed = new Date().getTime() - startTime

    const group = formatMessage(operationType, operation, ellapsed)

    logging.groupCollapsed(...group)

    logging.log("INIT", operation.operationName, operation)
    logging.log("RESULT", operation.operationName, result)

    logging.groupEnd(...group)
    return result
  })
})

export default loggerLink
