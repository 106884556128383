import React, { useState, useEffect } from 'react';
import { AuthCard } from '@flywl/blocks';
import { BottomLink, Button, PasswordField, TextInputField } from '@flywl/core';
import { PasswordRequirements } from '@flywl/core';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { useAuth } from '@flywl/provider';

const RequestPasswordReset: React.FC = () => {
  const [formData, setFormData] = useState<{ confirmationCode: string; newPassword: string; confirmPassword: string }>({
    confirmationCode: '',
    newPassword: '',
    confirmPassword: '',
  });

  const [errors, setErrors] = useState<{
    confirmationCode?: string;
    newPassword?: string;
    confirmPassword?: string; 
  }>({});
    const [showPassword, setShowPassword] = useState<boolean>(false);
  const [message, setMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [step, setStep] = useState<1 | 2>(1);
  const navigate = useNavigate();
  const location = useLocation();
  const { confirmResetPassword, resetPassword  } = useAuth();
  const email = (location.state as { email: string })?.email || '';

  useEffect(() => {
    if (!email) {
      navigate('/forgot-password');
    }
  }, [email, navigate]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (errors[name as keyof typeof errors]) {
      setErrors((prev) => {
        const newErrors = { ...prev };
        delete newErrors[name as keyof typeof errors];
        return newErrors;
      });
    }
    if (message) setMessage(null);
  };

  const validateFields = (): boolean => {
    const newErrors: { confirmationCode?: string; newPassword?: string; confirmPassword?: string } = {};
    let valid = true;

    if (step === 1) {
      if (!formData.newPassword) {
        newErrors.newPassword = 'New password is required';
        valid = false;
      } else if (formData.newPassword !== formData.confirmPassword) {
        newErrors.confirmPassword = 'Passwords do not match';
        valid = false;
      }
      else {
        const passwordErrors = [];
        if (formData.newPassword.length < 8) passwordErrors.push('8 characters');
        if (!/[A-Z]/.test(formData.newPassword)) passwordErrors.push('1 uppercase letter');
        if (!/[a-z]/.test(formData.newPassword)) passwordErrors.push('1 lowercase letter');
        if (!/\d/.test(formData.newPassword)) passwordErrors.push('1 number');
        if (!/[!@#$%^&*(),.?":{}|<>]/.test(formData.newPassword)) passwordErrors.push('1 special character');
        if (passwordErrors.length > 0) {
          newErrors.newPassword = `Password must contain ${passwordErrors.join(', ')}`;
          valid = false;
        }
      }
    } else if (step === 2 && !formData.confirmationCode) {
      newErrors.confirmationCode = 'Confirmation code is required';
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };
  const handleResendCode = async () =>{
    await resetPassword(email); 
  }

  const handleSubmit = async () => {
    if (validateFields()) {
      setLoading(true);
      try {
        
        if (step === 1) {
          setStep(2)
        }
        if (step === 2) {
          
        
          await confirmResetPassword(email, formData.confirmationCode, formData.newPassword);
          navigate('/reset-password-successfully')
       
        }
      } catch (error) {
        console.error('Error during password reset process:', error);
      
        if (step === 2 && typeof error === 'object' && error !== null && 'code' in error) {
          const errorCode = (error).code;
          
          if (errorCode === 'CodeMismatchException') {
            setErrors({ confirmationCode: 'Invalid confirmation code. Please try again.' });
          } else if (errorCode === 'ExpiredCodeException') {
            setErrors({ confirmationCode: 'Confirmation code has expired. Please request a new one.' });
          } else {
            setErrors({ confirmationCode: 'An unexpected error occurred. Please try again.' });
          }
        } else {
          setErrors({ confirmationCode: 'An unexpected error occurred. Please try again.' });
        }
      
      
      } finally {
        setLoading(false);
      }
    }
  };

  const renderStepContent = () => {
    if (step === 1) {
      return (
        <>
          <PasswordField
            label="New Password"
            name="newPassword"
            value={formData.newPassword}
            error={!!errors.newPassword}
            helperText={errors.newPassword || ''}
            showPassword={showPassword}
            onChange={handleChange}
            toggleShowPassword={() => setShowPassword((prev) => !prev)}
          />
          <PasswordRequirements
            passwordValue={formData.newPassword}
            validationMessages={{
              length: '8 characters',
              uppercase: '1 uppercase letter',
              lowercase: '1 lowercase letter',
              number: '1 number',
              specialChar: '1 special character',
            }}
            requirementsTitle="Must contain at least:"
          />
            <PasswordField
            label="Confirm New Password"
            name="confirmPassword"
            value={formData.confirmPassword}
            error={!!errors?.confirmPassword }
            helperText={errors?.confirmPassword || ''}
            showPassword={showPassword}
            onChange={handleChange}
            toggleShowPassword={() => setShowPassword((prev) => !prev)}
          />
        </>
      );
    } else if (step === 2) {
      return (
        <>
          <TextInputField
            label="Confirmation Code"
            name="confirmationCode"
            value={formData.confirmationCode}
            error={!!errors.confirmationCode}
            helperText={errors.confirmationCode || ''}
            onChange={handleChange}
          />
          <BottomLink
            text={"Didn’t received the email?"}
            linkText={"Resend Code"}
            onLinkClick={handleResendCode}
            align="left"
          />
        </>
      );
    }
  };

  return (
    <AuthCard
      title={step === 1 ? 'Set a New Password' : 'Enter Confirmation Code'}
      subtitle={step === 1 ? 'Enter your new password below.' : `We sent a verification code to ${email}.`}
      showBackButton={step === 2}
      onBackClick={() => setStep(1)}
      titleVariant="h2"
      pharagraph={step === 2 ? "Enter the code within the next: " : undefined}
      backButton={false}
    >
      <Box>
        {message && (
          <Typography variant="body1" color="success.main" gutterBottom>
            {message}
          </Typography>
        )}

        {renderStepContent()}

        <Button
          fullWidth
          variant="contained"
          onClick={handleSubmit}
          disabled={loading}
          sx={{ mt: 5, bgcolor: '#963F2B', color: '#F6FAF6' }}
        >
          {loading ? (step === 1 ? 'Setting Password...' : 'Verifying Code...') : (step === 1 ? 'Set Password' : 'Verify Code')}
        </Button>
      </Box>
    </AuthCard>
  );
};

export default RequestPasswordReset;

