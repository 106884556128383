import Grid from '@mui/material/Grid2';
import { CommitmentData, EnterpriseCommit } from '@flywl/blocks';

interface EnterpriseCommitmentCardsProps {
  awsData: CommitmentData ;
  gcpData: CommitmentData ;
  azureData: CommitmentData  ;
  onAwsChange: (data: Partial<CommitmentData>) => void;
  onGcpChange: (data: Partial<CommitmentData>) => void;
  onAzureChange: (data: Partial<CommitmentData>) => void;
}

export const EnterpriseCommitmentCards = ({
  awsData,
  gcpData,
  azureData,

  onAwsChange,
  onGcpChange,
  onAzureChange,
}: EnterpriseCommitmentCardsProps) => {
  console.log('awsData:',awsData);
  return (
    <Grid
      container
      spacing={'2rem'}
      sx={{
        justifyContent: { xs: 'center', md: 'flex-start' },
      }}
    >
      <Grid size={4}>
        <EnterpriseCommit.AWS
          data={awsData}
          onChange={onAwsChange}
          included={awsData.data.included}
        />
      </Grid>
      <Grid size={4}>
        <EnterpriseCommit.GCP
          data={gcpData}
          onChange={onGcpChange}
          included={gcpData.data.included}
        />
      </Grid>
      <Grid size={4}>
        <EnterpriseCommit.Azure
          data={azureData}
          onChange={onAzureChange}
          included={azureData.data.included}
        />
      </Grid>
    </Grid>
  );
};
