import React from 'react';
import {
  Divider as MuiDivider,
  DividerProps as MuiDividerProps,
  Typography,
} from '@mui/material';
import '../styles/variables.scss';

export interface DividerProps extends MuiDividerProps {
  text?: string; // Nueva prop para el texto
}

export const Divider: React.FC<DividerProps> = ({ text, ...props }) => {
  return (
    <MuiDivider
      {...props}
      textAlign={text ? 'center' : undefined} // Alineación del texto si está presente
    >
      {text && (
        <Typography
          variant="body1" // O elige otra variante según tu diseño
          sx={{ color: 'inherit' }} // O ajusta los estilos si lo necesitas
        >
          {text}
        </Typography>
      )}
    </MuiDivider>
  );
};

export default Divider;
