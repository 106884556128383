import React from 'react';
import { Card, CardContent, Box, Typography } from '@mui/material';
import Cloud from '../../assets/Cloud sync.svg';

export const ComingSoonBilling: React.FC = () => {
  return (
    <Card
      elevation={0}
      sx={{
        backgroundColor: '#fef5e9',
        borderRadius: 2,
        border: '1px solid #faab50',
        padding: 2,
        flex: 1,
      }}
    >
      <CardContent
        sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
          <img src={Cloud} alt="Cloud Logo" width={48} height={48} />
          <Typography variant="h3">Cloud billing</Typography>
        </Box>
        <Typography variant="body1">
          You’ll be able to easily connect your billing system to our cloud for
          a seamless experience.
        </Typography>
      </CardContent>
    </Card>
  );
};
