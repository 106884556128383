import React from 'react';
import { Card as MuiCard, CardProps as MuiCardProps } from '@mui/material';



export const Card: React.FC<MuiCardProps> = ({ children, sx, ...props }) => {
  return (
    <MuiCard
      {...props}
      sx={{
        borderRadius: '12px',
        border: '1px solid',
        borderColor: '#727073',
        backgroundColor: '#FDFEFD',
        boxShadow: 'none',
        padding: 2,
        marginBottom: 5,
        ...sx, 
      }}
    >
      {children}
    </MuiCard>
  );
};
