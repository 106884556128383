import { useState } from 'react';
import { TableComponent, ProductType, HeadCell } from '@flywl/blocks';
import { Skeleton } from '@mui/material';
import { date } from '@flywl/utils-data';
import { useInventoryLineItemQuery } from '@flywl/provider';

function calculateInventoryStatus(startDate?: string, renewalDate?: string) {
  if (renewalDate && date.isBefore(renewalDate, date.now())) {
    // Expired = renewal date < today
    return { name: 'Expired Renewal', colorType: 'red' };
  } else if (
    startDate &&
    date.isBetween(date.addDays(-60), date.now(), startDate)
  ) {
    // Recent = -60 days < start date > today
    return { name: 'Recent Renewal', colorType: 'green' };
  } else if (
    renewalDate &&
    date.isBetween(date.now(), date.addDays(60), renewalDate)
  ) {
    // Upcoming = renewal date < +60 days
    return { name: 'Upcoming Renewal', colorType: 'blue' };
  } else if (renewalDate && date.isAfter(renewalDate, date.now())) {
    return { name: 'Future', colorType: 'blue' };
  } else {
    return { name: 'None', colorType: 'gray' };
  }
}

type SortOptions = keyof Omit<Omit<Omit<ProductType, 'tags'>, 'icon'>, 'cost'>;

export const RenewalsTable = () => {
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<SortOptions>('product');
  const [filter, setFilter] = useState<string>('All');

  const { loading, data } = useInventoryLineItemQuery();

  if (!data || loading) return <Skeleton />;

  const formattedData = data.inventory_line_item
    .map((item) => {
      return {
        icon: item.matching.find(({ logo }) => !!logo)?.logo,
        product: item.solution_name || '',
        vendor: item.vendor || '',
        tags: [
          calculateInventoryStatus(item.contract_start_date, item.renewal_date),
        ],
        id: item.id,
      } as ProductType;
    })
    .filter(
      ({ tags }) =>
        tags?.[0]?.name !== 'Future' &&
        (filter === 'All' || filter === tags?.[0]?.name)
    )
    .sort((a, b) => a[orderBy]?.localeCompare(b[orderBy] || '') || 0);

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property as SortOptions);
  };

  const headCells: HeadCell[] = [
    { id: 'product', label: 'Product ', sortable: true },
    { id: 'vendor', label: 'Vendor', sortable: true },
    { id: 'tags', label: 'State', sortable: false },
  ];

  return (
    <TableComponent
      name="renewals_table"
      orderBy={orderBy}
      order={order}
      filterOptions={[
        'All',
        'Expired Renewal',
        'Recent Renewal',
        'Upcoming Renewal',
      ]}
      handleRequestSort={handleRequestSort}
      filteredData={formattedData}
      filter={filter}
      setFilter={setFilter}
      isLoading={loading}
      headCells={headCells}
    />
  );
};
