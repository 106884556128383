import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import flywlogo from '../../assets/flywl-logotype.svg';
import { useNavigate } from 'react-router-dom';
interface ResetCardProps {
  title: string;
  subtitle?: string;
  pharagraph?: string;
  email?: string;
  showResetLink?: boolean;
  onButtonClick: () => void;
  buttonText?: string;
  children?: React.ReactNode;
}

export const ResetCard: React.FC<ResetCardProps> = ({
  title,
  subtitle,
  pharagraph,
  email,
  showResetLink = false,
  onButtonClick,
  buttonText,
  children,
}) => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        width: 484,
        bgcolor: '#FAFAFA',
        borderRadius: 0,
        pt: '6.25rem',
        textAlign: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          mb: '2rem',
        }}
      >
        <img src={flywlogo} alt="Flywl Logo" width={136} height={44} />
      </Box>

      <Typography variant="h1" sx={{ mb: 5 }}>
        {title}
      </Typography>

      {subtitle && (
        <Typography variant="body1" sx={{ mb: 5 }}>
          {subtitle}{' '}
          {email && (
            <Typography component="span" variant="body2">
              {email}
            </Typography>
          )}
        </Typography>
      )}

      {pharagraph && (
        <Box display="flex" justifyContent="center" alignItems="center" sx={{ mb: 5 }}>
          <Typography variant="body1">
            {pharagraph}
          </Typography>
          {showResetLink && (
            <Typography
              component="span"
              variant="body2"
              sx={{ color: '#963F2B', cursor: 'pointer', ml: 1 }}
              onClick={() => navigate('/reset-password')}
            >
              Resend link
            </Typography>
          )}
        </Box>
      )}

      {children}

      {buttonText && (
        <Box
            sx={{
              display: 'flex',
              width: '180px',
              padding: '8px 24px',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '4px',
              backgroundColor: '#963F2B',
              color: '#fff',
              boxShadow: '0px 1px 1px 0px rgba(18, 17, 19, 0.02), 0px 2px 4px 0px rgba(18, 17, 19, 0.04)',
              margin: '0 auto',
            }}
            onClick={onButtonClick}
          >
            <Typography variant="body1">
            {buttonText}
            </Typography>
          </Box>
      )}
    </Box>
  );
};
