import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { BaseInputProps } from '../Input';

export interface DateProps extends BaseInputProps {
  value: Date | null;
  onChange: (date: Date | null) => void;
  onBlur?: () => void;
}

const DateField = ({
  label,
  value,
  onChange,
  onBlur,
  error,
  errorMessage,
  required,
  ...props
}: DateProps) => (
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <DatePicker
      disabled={props.disabled}
      label={label}
      value={value}
      onChange={(newValue) => {
        if (newValue instanceof Date && !isNaN(newValue.getTime())) {
          onChange(newValue);
        } else {
          onChange(null);
        }
        onBlur?.();
      }}
      format="MM/dd/yyyy"
      onOpen={() => {
        onBlur?.();
      }}
      slotProps={{
        textField: {
          variant: 'standard',
          color: 'secondary',
          required: required,
          error: error,
          helperText: error ? errorMessage : props.helperText,
          fullWidth: true,
          placeholder: 'MM/DD/YYYY',
          onBlur: () => {
            onBlur?.();
          },
          sx: {
            '& .MuiInputBase-input': {
              color: 'text.secondary',
            },
          },
        },
      }}
    />
  </LocalizationProvider>
);

export { DateField };
