import React from 'react';
import { Box, Skeleton } from '@mui/material';
import { Field, FormatType } from '@flywl/core';

const MetricsDetailItem: React.FC<{
  name: string;
  value: string | number;
  loading: boolean;
  format?: FormatType;
}> = ({ name, value, loading, format }) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
    <Field.Label name={name} variant="subtitle1" />
    {loading ? (
      <Skeleton variant="rectangular" width={328} height={24} />
    ) : (
      <div>
        {value !== '-' ? (
          <Field.Value value={value} variant="body2" format={format} />
        ) : (
          value
        )}
      </div>
    )}
  </Box>
);

export default MetricsDetailItem;
