import {
  MetricsQueryResponse,
  OverallResultQueryResponse,
} from '@flywl/data-acess/graphql';
import { Column } from '../types/ComparitionData';
import { calculationsFromMetricsQueryResponse } from './calculationsFromMetricsQueryResponse';

export const dataToColumns = (
  req: OverallResultQueryResponse,
  req2: MetricsQueryResponse
): Column[] => {

  const costIsvCmpBefore = req.metrics_cost_isv_bought_through_the_cmp_before?.reduce((acc, item) => {
    return acc + item.total_cost;
  }, 0)


  const isvDrawdownAfter = req.metrics_total_spend_after?.reduce((acc, item) => {
    const cleanAmount = item.cost_isv_after_total?.replace(/[$,]/g, '')?.split('.')[0];
    return acc + parseInt(cleanAmount);
  }, 0);

  const privateISVResult = req.metrics_cost_isv_bought_without_a_cmp?.reduce(
    (acc, item) => {
      return acc + item.total_cost;
    },
    0
  );

  const totalSpendBefore = req.metrics_total_spend_before?.reduce((acc, item) => {
    const cleanAmount = item.result?.replace(/[$,]/g, '')?.split('.')[0];
    return acc + parseInt(cleanAmount);
  }, 0);

  const totalSpendAfter = req.metrics_total_spend_after?.reduce((acc, item) => {
    const cleanAmount = item.result?.replace(/[$,]/g, '')?.split('.')[0];
    return acc + parseInt(cleanAmount);
  }, 0);

  const totalEnterpriseCommit = req.edp_commitment?.reduce((acc, item) => {
    const cleanAmount = item.amount?.replace(/[$,]/g, '')?.split('.')[0];

    return acc + parseInt(cleanAmount);
  }, 0);

  const { Amount } = calculationsFromMetricsQueryResponse(req2);

  const { nttcmpAfter } = Amount;


  const overageBefore = req.metrics_overage_before?.reduce((acc, item) => {
    const cleanAmount = item.result?.replace(/[$,]/g, '')?.split('.')[0];
    return acc + parseInt(cleanAmount);
  }, 0);

  const overageAfter = req.metrics_overage_after?.reduce((acc, item) => {
    const cleanAmount = item.result?.replace(/[$,]/g, '')?.split('.')[0];
    return acc + parseInt(cleanAmount);
  }, 0);


  const result: Column[] = [
    {
      title: 'Before',
      fields: [
        {
          name: 'total_commitment',
          value: totalEnterpriseCommit,
          isHighlighted: false,
          format: 'currency',
        },
        {
          name: 'private_isv',
          value: privateISVResult,
          format: 'currency',
        },
        {
          name: 'isv_drawdown',
          value: costIsvCmpBefore,
          format: 'currency',
        },
        {
          name: 'drawdown_overage',
          value: overageBefore,
          format: 'currency',
        }
      ],
      summary: {
        name: 'total_spend',
        value: totalSpendBefore,
        format: 'currency',
      },
    },
    {
      title: "After",
      fields: [

        {
          name: '',
          isHighlighted: true,
          value: totalEnterpriseCommit,
          format: 'currency',
        },
        {
          name: ' ',
          value: nttcmpAfter,
          format: 'currency',
        },
        {
          name: ' ',
          value: isvDrawdownAfter,
          format: 'currency',
        },
        {
          name: ' ',
          value: overageAfter,
          format: 'currency',
        }
      ],
      summary: {
        name: '',
        value: totalSpendAfter,
        format: 'currency',
      },
    },
  ];

  return result;
};
