  import React from 'react';
  import { Button as MuiButton } from '@mui/material';
  import { ButtonProps as MuiButtonProps } from '@mui/material';

  export interface ButtonProps extends MuiButtonProps {
    color?: 'primary' | 'secondary' | 'success' | 'error' | 'warning' | 'info';
  }

  export const Button: React.FC<ButtonProps> = ({
    variant = 'contained',
    color = 'secondary',
    children,
    ...props
  }) => {

    return (
      <MuiButton 
        sx={{

        }}
        variant={variant}
        {...props}
      >
        {children}
      </MuiButton>
    );
  };
