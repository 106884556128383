import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      50: '#fef5e9',
      100: '#fddebb',
      200: '#fccf9a',
      300: '#fbb96c',
      400: '#faab50',
      500: '#f99624',
      600: '#e38921',
      700: '#b16b1a',
      800: '#895314',
      900: '#693f0f',
      main: '#f99624',
      contrastText: '#2c292e',
    },
    secondary: {
      50: '#f6ecea',
      100: '#e3c5bf',
      200: '#d6a99f',
      300: '#c38274',
      400: '#b76a59',
      500: '#a5452f',
      600: '#963f2b',
      700: '#753121',
      800: '#5b261a',
      900: '#451d14',
      main: '#a5452f',
      contrastText: '#ecf4ec',
    },
    success: {
      50: '#ebf7f3',
      100: '#c2e7db',
      200: '#a4dcc9',
      300: '#7bcbb1',
      400: '#61c1a1',
      500: '#3ab28a',
      600: '#35a27e',
      700: '#297e62',
      800: '#20624c',
      900: '#184b3a',
      main: '#3ab28a',
      contrastText: '#ffffff',
    },
    info: {
      50: '#e6f3f9',
      100: '#b0d9eb',
      200: '#8ac7e2',
      300: '#55aed5',
      400: '#349ecd',
      500: '#0186c0',
      600: '#017aaf',
      700: '#015f88',
      800: '#014a6a',
      900: '#003851',
      main: '#0186c0',
      contrastText: '#ffffff',
    },
    warning: {
      50: '#fef9ec',
      100: '#fcecc5',
      200: '#fae3a9',
      300: '#f8d681',
      400: '#f7ce69',
      500: '#f5c243',
      600: '#dfb13d',
      700: '#ae8a30',
      800: '#876b25',
      900: '#67511c',
      main: '#f5c243',
      contrastText: '#000000',
    },
    error: {
      50: '#f8e6e6',
      100: '#ebb0b0',
      200: '#e18a8a',
      300: '#d35454',
      400: '#ca3333',
      500: '#bd0000',
      600: '#ac0000',
      700: '#860000',
      800: '#680000',
      900: '#4f0000',
      main: '#bd0000',
      contrastText: '#ffffff',
    },
  },
  typography: {
    fontFamily: ['Inter', 'Sunflower', 'sans-serif'].join(','),

    h1: {
      fontFamily: 'Inter ',
      fontSize: '32px',
      lineHeight: '40px',
      fontWeight: 700,
    },
    h2: {
      fontFamily: 'Inter',
      fontSize: '24px',
      lineHeight: '28px',
      fontWeight: 700,
      letterspacing: '-0.36px',
    },
    h3: {
      fontFamily: 'Inter',
      fontSize: '20px',
      lineHeight: '24px',
      fontWeight: 700,
    },
    h4: {
      fontFamily: 'Inter',
      fontSize: '16px',
      lineHeight: '20px',
      fontWeight: 700,
    },
    h5: {
      fontFamily: 'Inter',
      fontSize: '14px',
      lineHeight: '16px',
      fontWeight: 700,
    },
    h6: {
      fontFamily: 'Inter',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '22px',
    },

    body1: {
      fontFamily: 'Inter',
      fontSize: '16px',
      lineHeight: '24px',
    },
    body2: {
      fontFamily: 'Inter ',
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 700,
    },
    subtitle1: {
      fontFamily: 'Inter ',
      fontSize: '14px',
      lineHeight: '22px',
    },
    subtitle2: {
      fontFamily: 'Inter ',
      fontSize: '14px',
      lineHeight: '22px',
      fontWeight: 700,
    },
    caption: {
      fontFamily: 'Inter ',
      fontSize: '12px',
      lineHeight: '16px',
    },
  },

  spacing: 4,// [0, 4, 8, 12, 16, 20, 24, 28, 32, 40, 48, 56, 64, 72, 80],

  shape: {
    borderRadius: 4,
  },
});
