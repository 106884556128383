import React from 'react';
import { Card, FormatType } from '@flywl/core';
import AwsIcon from '../../assets/aws-logo.svg';
import gcpIcon from '../../assets/gcp-logo.svg';
import azureIcon from '../../assets/azure-logo.svg';
import { TitleSection } from './TitleSection';
import { MetricsSection } from './MetricsSection';

type MetricsCardType = {
  name?: string;
  metrics: {format:FormatType, name:string, value: string | number  }[];
};

export const MetricsCard: React.FC<MetricsCardType> = ({ name, metrics }) => {
  let selectedLogo;

  if (name?.includes('GCP')) {
    selectedLogo = gcpIcon;
  } else if (name?.includes('AWS')) {
    selectedLogo = AwsIcon;
  } else {
    selectedLogo = azureIcon;
  }

  return (
    <Card
      sx={{
        borderColor: '#EAEAEA',
        width: '100%',
        padding: 7,
      }}
    >
      <TitleSection name={name} logo={selectedLogo} />
      <MetricsSection metrics={metrics} />
    </Card>
  );
};
