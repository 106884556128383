import React from 'react';
import Box from '@mui/material/Box';
import LoginContainer from '../../containers/LoginContainer/LoginContainer';

const LoginPage: React.FC = () => {
  return (
    <Box
      sx={{
        minHeight: '100vh',
        bgcolor: '#FAFAFA',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        maxWidth: '100vw',
      }}
    >
      <LoginContainer />
    </Box>
  );
};

export default LoginPage;
