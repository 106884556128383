import { Modal, Box, CircularProgress, Skeleton } from '@mui/material';
import { CommitmentData } from '@flywl/blocks';
import { useEffect, useState } from 'react';
import { TitleAndExit } from './components/TitleAndExit';
import { EnterpriseCommitmentCards } from './components/EnterpriseCommitmentCards';
import { SaveAndCancelButtons } from './components/SaveAndCancelButtons';
import { defaultCommitmentData } from './default/defaultValues';
import { useMutation, useQuery } from '@apollo/client';
import {
  DeleteEdpCommitmentDocument,
  useEdpCommitmentQuery,
} from '@flywl/provider';
import { GET_PROVIDERS, ProvidersData } from '@flywl/data-acess/graphql';

interface EnterpriseCommitEditProps {
  open: boolean;
  onClose: () => void;
}

const defaultStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  width: '100vw',
  height: '100vh',
  bgcolor: 'background.paper',
};

export const EnterpriseCommitEdit = ({
  open,
  onClose,
}: EnterpriseCommitEditProps) => {
  const [awsData, setAwsData] = useState<CommitmentData>({
    ...defaultCommitmentData,
  });
  const [gcpData, setGcpData] = useState<CommitmentData>({
    ...defaultCommitmentData,
  });
  const [azureData, setAzureData] = useState<CommitmentData>({
    ...defaultCommitmentData,
  });

  const resetStatusAndClose = () => {
    onClose();
  };

  const { loading, data: providersData } = useQuery<ProvidersData>(
    GET_PROVIDERS,
    {
      fetchPolicy: 'cache-first',
    }
  );
  const {
    data: edpData,
    loading: edploading,
    error,
  } = useEdpCommitmentQuery({ fetchPolicy: 'network-only' });

  useEffect(() => {
    if (providersData && providersData.provider) {
      const awsProvider = providersData.provider.find(
        (p) => p.name.toLowerCase() === 'aws'
      );
      const gcpProvider = providersData.provider.find(
        (p) => p.name.toLowerCase() === 'gcp'
      );
      const azureProvider = providersData.provider.find(
        (p) => p.name.toLowerCase() === 'azure'
      );

      if (awsProvider) {
        setAwsData((prev) => ({
          ...prev,
          providerId: awsProvider.id,
        }));
      }
      if (gcpProvider) {
        setGcpData((prev) => ({
          ...prev,
          providerId: gcpProvider.id,
        }));
      }
      if (azureProvider) {
        setAzureData((prev) => ({
          ...prev,
          providerId: azureProvider.id,
        }));
      }
    }
  }, [providersData]);

  useEffect(() => {
    if (edpData && edpData.edp_commitment && !loading && !edploading) {
      edpData.edp_commitment.forEach((commitment) => {
        const providerName = commitment.provider?.name?.toUpperCase();

        const commitmentData: CommitmentData = {
          isValid: true,
          data: {
            commitmentValue: parseFloat(
              commitment.amount.replace(/[^0-9.-]+/g, '')
            ),
            nativeSpend: parseFloat(
              commitment.direct_spend.replace(/[^0-9.-]+/g, '')
            ),
            percentageDiscount: commitment.discount,
            percentageDrawdown: commitment.custom_drawdown,
            startDate: new Date(commitment.contract_start_date),
            endDate: new Date(commitment.contract_end_date),
            included: true,
          },
          providerId: commitment.provider_id,
        };

        switch (providerName) {
          case 'AWS':
            setAwsData(commitmentData);
            break;
          case 'GCP':
            setGcpData(commitmentData);
            break;
          case 'AZURE':
            setAzureData(commitmentData);
            break;
          default:
            console.warn(`Unknown provider: ${providerName}`);
        }
      });
    }
  }, [edpData, loading, edploading]);

  if (loading || edploading) {
    return <Skeleton />;
  }

  return (
    <Modal open={open} onClose={resetStatusAndClose}>
      <Box
        sx={{
          ...defaultStyle,
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            paddingY: '2.5rem',
            paddingX: '2.5rem',
            backgroundColor: '#fff',
            gap: '2rem',
            overflowY: 'auto',
          }}
        >
          <TitleAndExit onClose={resetStatusAndClose} />

          <EnterpriseCommitmentCards
            awsData={awsData}
            gcpData={gcpData}
            azureData={azureData}
            onAwsChange={(data) => setAwsData({ ...awsData, ...data })}
            onGcpChange={(data) => setGcpData({ ...gcpData, ...data })}
            onAzureChange={(data) => setAzureData({ ...azureData, ...data })}
          />

          <SaveAndCancelButtons
            awsData={awsData}
            gcpData={gcpData}
            azureData={azureData}
            close={resetStatusAndClose}
          />
        </Box>
      </Box>
    </Modal>
  );
};
