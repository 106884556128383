// src/components/CheckboxField/CheckboxField.tsx
import React from 'react';
import {
  FormControl,
  FormControlLabel,
  Checkbox,
  FormHelperText,
} from '@mui/material';

export interface CheckboxFieldProps {
  name: string;
  label: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error?: string;
}

export const CheckboxField: React.FC<CheckboxFieldProps> = ({
  name,
  label,
  checked,
  onChange,
  error,
}) => (
  <FormControl error={Boolean(error)} component="fieldset" sx={{ mb: 2 }}>
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          onChange={onChange}
          name={name}
          color="secondary"
        />
      }
      label={label}
    />
    {error && <FormHelperText>{error}</FormHelperText>}
  </FormControl>
);

export default CheckboxField;
