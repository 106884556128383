import React from 'react';
import { Box } from '@mui/material';
import { MetricsCard } from '../MetricsCard/MetricsCard';
import { FormatType } from '@flywl/core';

export type MarketplaceMetricsType = {
  marketplaceData: {
    name?: string;
    logo?: string;
    metrics: {format:FormatType, name:string, value: string | number  }[];
  }[];
};

export const MarketplaceMetrics: React.FC<MarketplaceMetricsType> = ({
  marketplaceData,
}) => (
  <Box
    display={'flex'}
    justifyContent={'space-around'}
    gap={7}
    sx={{ width: 'auto' }}
  >
    {marketplaceData.map((data, index) => (
      <MetricsCard key={index} name={data.name} metrics={data.metrics} />
    ))}
  </Box>
);
