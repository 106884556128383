import { theme } from '@flywl/core';
import { Box, SvgIconProps, Typography } from '@mui/material';
import React from 'react';

interface TitleBoxProps {
  icon: string | React.ComponentType<SvgIconProps>;
  title: string;
}

export const TitleBox: React.FC<TitleBoxProps> = ({
  icon,
  title,
}: TitleBoxProps) => {
  const style = { width: '40px', height: '48px' };

  const iconElement =
    typeof icon === 'string' ? (
      <img src={icon} alt="icon" style={style} />
    ) : (
      React.createElement(icon, { style })
    );

  return (
    <Box display={'flex'} alignItems="flex-start" gap={'0.5rem'}>
      {iconElement}
      <Box display={'flex'} flexDirection={'column'} gap={1}>
        <Typography
          fontSize={'1.25rem'}
          fontWeight={700}
          color={'#000000'}
          fontFamily={theme.typography.fontFamily}
        >
          {title}
        </Typography>
        <Typography
          fontSize={14}
          fontWeight={600}
          fontStyle={'0.875rem'}
          fontFamily={'Inter'}
          color={'rgba(44, 41, 46, 0.60)'}
        >
          Marketplace
        </Typography>
      </Box>
    </Box>
  );
};
