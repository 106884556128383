import React from 'react';
import { ResetCard } from '@flywl/blocks';
import { useNavigate } from 'react-router-dom';

const ResetPasswordSuccessfully: React.FC = () => {
  const navigate = useNavigate();

  const handleBackToLogin = () => {
    navigate('/');
  };

  return (
    <ResetCard
      title="Your password has been successfully updated"
      subtitle="If you didn't make the change, please don't hesitate to contact us at flywl@support.com. Thank you so much for keeping your account safe!"
     
      showResetLink={false} 
      onButtonClick={handleBackToLogin}
      buttonText="Back to login"
    >

    </ResetCard>
  );
};

export default ResetPasswordSuccessfully;
