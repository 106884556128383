import { gql } from '@apollo/client';

export const INSERT_EDP_COMMITMENT = gql`
  mutation InsertEDPCommitment(
    $amount: money!
    $direct_spend: money!
    $provider_id: uuid!
    $contract_start_date: date!
    $contract_end_date: date!
    $discount: numeric
    $custom_drawdown: numeric
  ) {
    insert_edp_commitment(
      objects: {
        amount: $amount
        direct_spend: $direct_spend
        provider_id: $provider_id
        contract_start_date: $contract_start_date
        contract_end_date: $contract_end_date
        discount: $discount
        custom_drawdown: $custom_drawdown
      }
    on_conflict: {
      constraint: unique_edp_by_provider
      update_columns: [
        amount
        direct_spend
        contract_start_date
        contract_end_date
        discount
        custom_drawdown
      ]
    }
    ) {
      affected_rows
      returning {
        id
        created_at
      }
    }
  }
`;
export interface EDPCommitmentInput {
  account_id: string;
  contract_start_date: string;
  contract_end_date: string;
  amount: string;
  custom_drawdown: string;
  direct_spend: string;
  discount: string;
  providerId: string;
}

export const ADD_ASSIGNMENT_MUTATION = gql`
  mutation AddAssignment(
    $inventoryId: uuid!
    $matchId: uuid
    $origin: String!
    $providerId: uuid!
  ) {
    insert_inventory_assignment(
      objects: {
        inventory_id: $inventoryId
        match_id: $matchId
        origin: $origin
        provider_id: $providerId
      }
      on_conflict: {
        constraint: inventory_assignment_pkey
        update_columns: [match_id, provider_id, origin]
      }
    ) {
      affected_rows
    }
  }
`;

export const REMOVE_ASSIGNMENT_MUTATION = gql`
  mutation deleteAssignment(
    $inventoryId: uuid!
    $matchId: uuid
    $origin: String!
  ) {
    delete_inventory_assignment(
      where: {
        inventory_id: { _eq: $inventoryId }
        origin: { _eq: $origin }
      }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_INVENTORY_LINE_ITEM = gql`
  mutation DeleteInventoryLineItem($id: String!) {
    delete_inventory_line_item(where: { created_by: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const ADD_MULTIPLE_INVENTORY_LINE_ITEMS = gql`
mutation AddMultipleInventoryLineItems($items: [inventory_assignment_insert_input!]!) {
  insert_inventory_assignment(objects: $items) {
    affected_rows
    returning {
      id
      inventory_line_item {
        assignment_id
        id
        matches(persist_data: true) {
          created_at
        }
      }
    }
    __typename
  }
}`;



export type AddMultipleInventoryLineItemsResponse = {
  insert_inventory_line_item: {
    affected_rows: number;
    returning: InventoryLineItemReturnedRows[];
  };
};

export type InventoryLineItemReturnedRows = {
  cloud_provider: string;
  id: string;
};

export const REGISTER_FILE = gql`
  mutation register_file($s3_route: String!, $is_valid: Boolean!) {
    insert_inventory_files(
      objects: { s3_route: $s3_route, is_valid: $is_valid }
      on_conflict: { constraint: inventory_files_pkey }
    ) {
      affected_rows
    }
  }
`;

export const ADD_IMPORT_FROM_INVENTORY_ASSIGNMENT = gql`
  mutation add_import_from_inventory_assignment(
    $items: [inventory_assignment_insert_input!]!
  ) {
    insert_inventory_assignment(objects: $items, on_conflict:{}) {
      affected_rows
      matches(persist_data: true) {
        id
      }
    }
  }
`;
