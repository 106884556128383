import { useQuery } from '@apollo/client';
import { SummaryTable } from '@flywl/blocks';
import { EDO_METRICS_QUERY, EdpMetricsData } from '@flywl/data-acess/graphql';
import { CircularProgress } from '@mui/material';
import { transformData } from '../utils/transformData';

export const BeforeAndAfterTable = ({
  provider,
}: {
  provider: 'AWS' | 'GCP' | 'AZURE';
}) => {
  const {
    data: edp_data,
    loading: edp_loading,
    error: edp_error,
  } = useQuery<EdpMetricsData>(EDO_METRICS_QUERY, {
    variables: { provider },
  });

  if (edp_loading) return <CircularProgress />;
  if (edp_error) return <h2> ERROR</h2>;

  if (edp_data === undefined) return <h2> ERROR</h2>;

  return <SummaryTable columns={transformData(edp_data)} />;
};
