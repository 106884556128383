import React from 'react';
import { ApplicationLayout } from '@flywl/modules';
import { Route, Routes } from 'react-router-dom';
import { MatchingResults } from '@flywl/modules';
import { UploadFile, Opportunities, Report } from '@flywl/flows';

const CompassPage: React.FC = () => {
  return (
    <ApplicationLayout>
      <Routes>
        <Route path="data-uploading" element={<UploadFile />} />
        <Route path="matching-results" element={<MatchingResults />} />
        <Route path="opportunities" element={<Opportunities />} />
        <Route path="reports" element={<Report />} />
      </Routes>
    </ApplicationLayout>
  );
};

export default CompassPage;
