import { InMemoryCache } from '@apollo/client/cache'
import { StrictTypedTypePolicies } from './cache-policies'

export type CacheTypePolicies = StrictTypedTypePolicies;

const typePolicies: StrictTypedTypePolicies = {
    // Keys in this object will be validated against the types on your schema
    auth_users: {
        keyFields: ['user_id'],
    },
}

export function getApolloCache(existingState = {}) {
    const isServer = typeof window === "undefined";
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const windowApolloState =
        !isServer && (window as any).__NEXT_DATA__?.apolloState;
    return new InMemoryCache({ typePolicies }).restore(
        existingState || windowApolloState || {}
    );
}
