import { CommitmentData } from '@flywl/blocks';

export interface PreparedCommitmentData {
  provider_id: string;
  contract_start_date: string;
  contract_end_date: string;
  amount: number;
  custom_drawdown: number;
  direct_spend: number;
  discount: number;
}

export interface CommitmentResult {
  affected_rows: number;
  returning: {
    id: string;
    created_at: string;
  }[];
}

export interface SaveCommitmentResponse {
  success: boolean;
  results: CommitmentResult[];
}

export const formatDateForPostgres = (date: Date): string => {
  return date.toISOString().split('T')[0];
};

export const prepareCommitmentData = (
  commitmentData: CommitmentData,
  providerId: string
): PreparedCommitmentData | null => {
  if (!commitmentData.data.startDate || !commitmentData.data.endDate) {
    console.error('Missing dates:', {
      startDate: commitmentData.data.startDate,
      endDate: commitmentData.data.endDate,
    });

    return null;
  }

  if (
    commitmentData.data.nativeSpend === 0 ||
    commitmentData.data.commitmentValue === 0
  ) {
    console.error('Missing values:', {
      nativeSpend: commitmentData.data.nativeSpend,
      commitmentValue: commitmentData.data.commitmentValue,
    });
    return null;
  }

  const startDate = new Date(commitmentData.data.startDate);
  const endDate = new Date(commitmentData.data.endDate);

  return {
    provider_id: providerId,
    contract_start_date: formatDateForPostgres(startDate),
    contract_end_date: formatDateForPostgres(endDate),
    amount: Number(commitmentData.data.commitmentValue),
    custom_drawdown: Number(commitmentData.data.percentageDrawdown),
    direct_spend: Number(commitmentData.data.nativeSpend),
    discount: Number(commitmentData.data.percentageDiscount),
  };
};
