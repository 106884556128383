import React from 'react';
import { Modal, Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { RenewalsTable } from './renewals-table';

type RenewalsTableModalProps = {
  openModal: boolean;
  setOpenModal: (value: React.SetStateAction<boolean>) => void;
};

export const RenewalsTableModal: React.FC<RenewalsTableModalProps> = ({
  openModal,
  setOpenModal,
}) => {
  return (
    <Modal
      open={openModal}
      onClose={() => {
        setOpenModal(false);
      }}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          backgroundColor: '#FFFFFF',
          borderRadius: '24px',
          padding: '32px',
          maxWidth: '80%',
          width: '100%',
          maxHeight: '90vh',
          overflow: 'auto',
          outline: 'none',
          position: 'relative',
        }}
      >
        <IconButton
          onClick={() => setOpenModal(false)}
          sx={{
            position: 'absolute',
            top: '16px',
            right: '16px',
            color: '#333333',
          }}
        >
          <CloseIcon />
        </IconButton>

        <Typography
          variant="h2"
          sx={{
            marginBottom: '32px',
          }}
        >
          Renewals
        </Typography>
        <RenewalsTable />
      </Box>
    </Modal>
  );
};
