import { CommitmentData } from '../EnterpriseCommit';

export type FieldName =
  | 'commitmentValue'
  | 'nativeSpend'
  // | 'percentageDiscount'
  | 'percentageDrawdown'
  | 'startDate'
  | 'endDate';

export type TouchedFields = Record<FieldName, boolean>;

export interface ValidationError {
  hasError: boolean;
  message: string;
}

export const getError = (
  fieldName: FieldName,
  data: CommitmentData['data'],
  touchedFields: Record<FieldName, boolean>
): ValidationError => {
  if (!touchedFields[fieldName]) {
    return { hasError: false, message: '' };
  }

  const value = data[fieldName];

  switch (fieldName) {
    case 'commitmentValue':
    case 'nativeSpend':
      return {
        hasError: value === 0,
        message: value === 0 ? 'This field is required' : '',
      };
    // case 'percentageDiscount':
    case 'percentageDrawdown': {
      const numValue = Number(value);
      return {
        hasError: isNaN(numValue) || numValue < 0 || numValue > 100 ,
        message:
          isNaN(numValue) || numValue < 0 || numValue > 100
            ? 'Must be between 0 and 100'
            : '',
      };
    }
    case 'startDate':
    case 'endDate':
      return {
        hasError: value === null,
        message: value === null ? 'Date is required' : '',
      };
    default:
      return { hasError: false, message: '' };
  }
};

// Verifica si algún campo tiene error
export const hasAnyError = (
  data: CommitmentData['data'],
  touchedFields: TouchedFields
): boolean => {
  const fieldNames: FieldName[] = [
    'commitmentValue',
    'nativeSpend',
    // 'percentageDiscount',
    'percentageDrawdown',
    'startDate',
    'endDate',
  ];

  return fieldNames.some(
    (fieldName) => getError(fieldName, data, touchedFields).hasError
  );
};

// Verifica si al menos un campo está lleno
export const hasAnyFieldFilled = (data: CommitmentData['data']): boolean => {
  const numberFields: FieldName[] = [
    'commitmentValue',
    'nativeSpend',
    // 'percentageDiscount',
    'percentageDrawdown',
  ];

  const dateFields: FieldName[] = ['startDate', 'endDate'];

  const hasFilledNumberField = numberFields.some(
    (field) =>
      data[field] !== 0 && data[field] !== null && data[field] !== undefined
  );

  const hasFilledDateField = dateFields.some(
    (field) => data[field] !== null && data[field] !== undefined
  );

  return hasFilledNumberField || hasFilledDateField;
};

// Verifies if the form is valid in general
export const isFormValid = (
  data: CommitmentData['data'],
  touchedFields: TouchedFields
): boolean => {
  // If there is no field filled the form is not valid
  if (!hasAnyFieldFilled(data)) {
    return false;
  }

  // If there is any error the form is not valid
  if (hasAnyError(data, touchedFields)) {
    return false;
  }

  return true;
};
