import { getSession } from '@flywl/provider';
import { uploadData } from 'aws-amplify/storage';
import { ApolloClient } from '@apollo/client';
import { REGISTER_FILE } from '@flywl/data-acess/graphql';

export const uploadFileToS3 = async (
  file: File,
  isValid: boolean,
  client: ApolloClient<object>
) => {
  const { userSub } = getSession();
  const path = `${userSub}/${file.name}`;
  try {
    client.mutate({
      mutation: REGISTER_FILE,
      variables: {
        s3_route: path,
        is_valid: isValid,
      },
    });

    await uploadData({
      data: file,
      path: path,
      options: {
        bucket: {
          bucketName: import.meta.env.VITE_BUCKET_NAME,
          region: import.meta.env.VITE_BUCKET_REGION,
        },
      },
    }).result;
  } catch (e) {
    console.error('error uploading', e);
  }
};
