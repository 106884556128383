import { createContext, useContext, useState, ReactNode } from 'react';
import {
  signUp as amplifySignUp,
  confirmSignUp as amplifyConfirmSignUp,
  signIn as amplifySignIn,
  confirmSignIn as amplifyConfirmSignIn,
  signOut as amplifySignOut,
  updateUserAttributes as amplifyUpdateUserAttributes,
  SignUpInput,
  resendSignUpCode,
  resetPassword as amplifyResetPassword,
  confirmResetPassword as amplifyConfirmResetPassword,
  updatePassword as amplifyUpdatePassword,
  getCurrentUser as amplifyGetCurrentUser,
  fetchUserAttributes as amplifyFetchUserAttributes,
  fetchAuthSession,
} from 'aws-amplify/auth';

import { Amplify } from 'aws-amplify';
import awsconfig from '../../config/amplify_outputs.json';

type UserInput = {
  password: string;
  email: string;
  phoneNumber?: string;
};
type SessionState = {
  token?: RawCredentials;
  userSub?: string;
  isLoggedIn: boolean;
  expired: boolean;
};
interface AuthContextType {
  signUp: (user: UserInput) => Promise<void>;
  confirmSignUp: (username: string, confirmationCode: string) => Promise<void>;
  signIn: (username: string, password: string) => Promise<void>;
  confirmSignIn: (challengeResponse: string) => Promise<void>;
  signOut: () => Promise<void>;
  getCurrentSession: () => Promise<SessionState>;
  isAuthenticated: boolean;
  user: string | null;
  resendCode: (username: string) => Promise<void>;
  updateUserAttributes: (attributes: {
    [key: string]: string;
  }) => Promise<void>;
  resetPassword: (username: string) => Promise<void>;
  confirmResetPassword: (
    username: string,
    confirmationCode: string,
    newPassword: string
  ) => Promise<void>;
  updatePassword: (oldPassword: string, newPassword: string) => Promise<void>;
  getCurrentUserDetails: () => Promise<{
    username: string;
    userId: string;
    signInDetails: any;
  } | null>;
  fetchUserAttributes: () => Promise<UserAttributes | null>;
}

type UserAttributes = {
  email?: string;
  name?: string;
  family_name?: string;
  company?: string;
  companyType?: string;
  termsAccepted?: boolean;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);
export const AmplifyConfig = awsconfig as any;

Amplify.configure(AmplifyConfig);

type RawCredentials = {
  idToken?: string;
  accessToken?: string;
  signInDetails?: string;
};

export const CognitoSessionConfig = {
  fields: ['idToken', 'accessToken', 'signInDetails'],
  prefix: `CognitoIdentityServiceProvider.${AmplifyConfig.Auth.Cognito.userPoolClientId}`,
};

const isTokenExpired = (token?: string) => {
  if (!token) return true;
  try {
    const decodedToken = JSON.parse(atob(token.split('.')[1]));
    const currentTime = Date.now() / 1000;
    return decodedToken.exp < currentTime;
  } catch (error) {
    console.error('Error decoding token:', error);
    return true;
  }
};

export const getSession = () => {
  const userSub =
    window.localStorage[`${CognitoSessionConfig.prefix}.LastAuthUser`];

  const tokens = CognitoSessionConfig.fields.reduce(
    (res, field) => ({
      ...res,
      [field]:
        localStorage[`${CognitoSessionConfig.prefix}.${userSub}.${field}`],
    }),
    {} as RawCredentials
  );
  return {
    tokens,
    userSub,
    isLoggedIn: !!tokens.idToken,
    expired: isTokenExpired(tokens?.idToken),
  };
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within a CognitoProvider');
  }
  return context;
};

export const CognitoProvider = ({ children }: { children: ReactNode }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [, setSession] = useState(getSession());
  const [user, setUser] = useState<string | null>(null);

  const signUp = async (userInput: UserInput) => {
    try {
      const signupData: SignUpInput = {
        username: userInput.email,
        password: userInput.password,
        options: {
          userAttributes: {
            email: userInput.email,
          },
        },
      };
      const { userId } = await amplifySignUp(signupData);
      if (userId) setUser(userId);
    } catch (error) {
      console.error('Error signing up:', error);
      throw error;
    }
  };

  const confirmSignUp = async (username: string, confirmationCode: string) => {
    try {
      await amplifyConfirmSignUp({ username, confirmationCode });
      setIsAuthenticated(true);
      console.log('Sign up confirmed successfully');
    } catch (error) {
      console.error('Error confirming sign up:', error);
      throw error;
    }
  };

  const resendCode = async (username: string) => {
    try {
      await resendSignUpCode({ username });
    } catch (error) {
      console.error('Error resending OTP:', error);
      throw error;
    }
  };

  const signIn = async (username: string, password: string) => {
    try {
      await amplifySignIn({ username, password });
      setUser(username);
      setIsAuthenticated(true);
    } catch (error) {
      console.error('Error signing in:', error);
      throw error;
    }
  };

  const confirmSignIn = async (challengeResponse: string) => {
    try {
      await amplifyConfirmSignIn({ challengeResponse });
      setSession(getSession);
      setIsAuthenticated(true);
    } catch (error) {
      console.error('Error confirming sign in:', error);
      throw error;
    }
  };

  const signOut = async () => {
    try {
      await amplifySignOut();
      setUser(null);
      setIsAuthenticated(false);
    } catch (error) {
      console.error('Error signing out:', error);
      throw error;
    }
  };

  const getCurrentSession = async () => {
    let session = getSession();
    try {
      const { tokens } = await fetchAuthSession({
        forceRefresh: session.expired,
      });
      session = getSession();
      console.log({ session, tokens });
    } catch (err) {
      console.log(err);
    }
    return session;
  };

  const updateUserAttributes = async (attributes: UserAttributes) => {
    try {
      const userAttributes: { [key: string]: string } = {};

      Object.entries(attributes).forEach(([key, value]) => {
        if (value !== undefined && value !== null) {
          let attributeKey = '';

          switch (key) {
            case 'email':
            case 'given_name':
            case 'family_name':
              attributeKey = key;
              break;
            default:
              attributeKey = `${key}`;
              break;
          }

          const attributeValue =
            typeof value === 'boolean' ? value.toString() : value;

          userAttributes[attributeKey] = attributeValue;
        }
      });
      await amplifyUpdateUserAttributes({
        userAttributes: userAttributes,
      });

      console.log('User attributes updated successfully');
    } catch (error) {
      console.error('Error updating user attributes:', error);
      let errorMessage =
        'An unexpected error occurred while updating user attributes. Please try again.';
      if (error instanceof Error) {
        errorMessage = error.message || errorMessage;
      }
      throw new Error(errorMessage);
    }
  };
  const resetPassword = async (username: string) => {
    try {
      const output = await amplifyResetPassword({ username });
      const { nextStep } = output;
      switch (nextStep.resetPasswordStep) {
        case 'CONFIRM_RESET_PASSWORD_WITH_CODE': {
          const codeDeliveryDetails = nextStep.codeDeliveryDetails;
          console.log(
            `Confirmation code was sent via ${codeDeliveryDetails.deliveryMedium}`
          );
          break;
        }
        case 'DONE':
          console.log('Password has been successfully reset.');
          break;
        default:
          console.log('Unknown step in the password reset process.');
          break;
      }
    } catch (error) {
      console.error('Error resetting password:', error);
      throw error;
    }
  };

  const confirmResetPassword = async (
    username: string,
    confirmationCode: string,
    newPassword: string
  ) => {
    try {
      await amplifyConfirmResetPassword({
        username,
        confirmationCode,
        newPassword,
      });
      console.log('Password has been successfully confirmed and updated.');
    } catch (error) {
      console.error('Error confirming password reset:', error);
      throw error;
    }
  };

  const updatePassword = async (oldPassword: string, newPassword: string) => {
    try {
      await amplifyUpdatePassword({
        oldPassword,
        newPassword,
      });
      console.log('Password has been successfully updated.');
    } catch (error) {
      console.error('Error updating password:', error);
      let errorMessage =
        'An unexpected error occurred while updating the password. Please try again.';
      if (error instanceof Error) {
        errorMessage = error.message || errorMessage;
      }
      throw new Error(errorMessage);
    }
  };

  const getCurrentUserDetails = async () => {
    try {
      const user = await amplifyGetCurrentUser();
      return user
        ? {
            username: user.username,
            userId: user.userId,
            signInDetails: user.signInDetails,
          }
        : null;
    } catch (error) {
      console.error('Error fetching current user:', error);
      return null;
    }
  };

  const fetchUserAttributes = async (): Promise<UserAttributes | null> => {
    try {
      const attributes = await amplifyFetchUserAttributes();
      return {
        company: attributes['custom:company'] || 'Your Company',
        name: attributes['given_name'] || 'User',
        family_name: attributes['family_name'] || '',
        email: attributes['email'] || '',
        companyType: attributes['companyType'] || '',
      };
    } catch (error) {
      console.error('Error fetching user attributes:', error);
      return null;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        signUp,
        confirmSignUp,
        signIn,
        confirmSignIn,
        signOut,
        getCurrentSession,
        isAuthenticated,
        user,
        getCurrentUserDetails,
        fetchUserAttributes,
        resendCode,
        updateUserAttributes,
        resetPassword,
        confirmResetPassword,
        updatePassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
