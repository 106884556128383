import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Typography, { TypographyProps } from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { Box, SxProps, Theme } from '@mui/material';
import { fields } from '@flywl/copy';

interface FieldLabelProps {
  name: string;
  tooltipPosition?: 'top' | 'bottom';
  variant?: TypographyProps['variant'];
  sx?: SxProps<Theme>;
  spaceBetween?: boolean;
}

export const Label: React.FC<FieldLabelProps> = ({
  name,
  tooltipPosition = 'top',
  variant,
  sx,
  spaceBetween = false,
}) => {
  const labelData = fields[name];
  const title = labelData?.label || '';
  const tooltip = labelData?.tooltip || '';

  return (
    <Box
      component={'div'}
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
        justifyContent: spaceBetween ? 'space-between' : 'normal',
      }}
    >
      <Typography sx={{ ...sx }} variant={variant}>
        {title}
      </Typography>
      {tooltip && (
        <Tooltip
          title={
            <Typography
              sx={{
                color: '#FFFFFF',
                fontSize: '14px',
                lineHeight: '22px',
                padding: '8px',
                backgroundColor: '#2C292E',
                borderRadius: '4px',
                width: '234px',
              }}
            >
              {tooltip}
            </Typography>
          }
          placement={tooltipPosition}
          arrow
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: '#2C292E',
                borderRadius: '4px',
                padding: 0,
                width: '234px',
              },
            },
            arrow: {
              sx: {
                color: '#2C292E',
              },
            },
          }}
        >
          <InfoIcon
            sx={{ width: 16, height: 16, color: '#727073', cursor: 'pointer' }}
          />
        </Tooltip>
      )}
    </Box>
  );
};
