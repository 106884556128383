import { gql } from '@apollo/client';

export const ISV_BEFORE_AND_AFTER_QUERY = gql`
  query ISV_BEFORE_AND_AFTER_QUERY {
    metrics_cost_isv_bought_without_a_cmp {
      number_of_items
      total_cost
    }
    metrics_cost_isv_bought_through_the_cmp_before {
      number_of_items
      total_cost
    }
    metrics_cost_isv_bought_through_the_cmp_after {
      number_of_items
      total_cost
    }
    metrics_total_spend_after {
     result 
     cost_isv_after_total
    }
     metrics_total_spend_before{
      result
     }

  }
`;

export const TALBLE_METRICS_QUERY = gql`
  query metrics {
    edp_commitment {
      amount
    }
    metrics_cost_isv_bought_through_the_cmp_before {
      total_cost
    }
    metrics_cost_isv_bought_without_a_cmp {
      total_cost
    }
    metrics_total_spend_before {
      result
    }
    metrics_total_spend_after {
      result
    }
    metrics_savings {
      result
    }
    metrics_savings_percentage {
      result
    }
  }
`;

interface MetricsItem {
  number_of_items: number;
  total_cost: number;
}

export interface MetricsQueryResponse {
  metrics_cost_isv_bought_without_a_cmp: MetricsItem[];
  metrics_cost_isv_bought_through_the_cmp_before: MetricsItem[];
  metrics_cost_isv_bought_through_the_cmp_after: MetricsItem[];
}

export const OVERALL_RESULTS_QUERY = gql`
  query overallMetricsQuery {
    edp_commitment {
      amount
    }
    metrics_cost_isv_bought_without_a_cmp {
      total_cost
    }
    metrics_cost_isv_bought_through_the_cmp_before {
      total_cost
    }
    metrics_total_spend_after {
      result
      cost_isv_after_total
    }
    metrics_total_spend_before {
      result
    }
    metrics_overage_after {
    result
    }
    metrics_overage_before {
    result
    }

  }
`;

export interface OverallResultQueryResponse {
  edp_commitment: {
    amount: string;
  }[];
  metrics_cost_isv_bought_without_a_cmp: {
    total_cost: number;
  }[];
  metrics_cost_isv_bought_through_the_cmp_before: {
    total_cost: number;
  }[];
  metrics_total_spend_after: {
    result: string;
    cost_isv_after_total: string;
  }[];
  metrics_total_spend_before: {
    result: string;
  }[];
  metrics_overage_after: {
    result: string;
  }[];
  metrics_overage_before: {
    result: string;
  }[];
}

export const SAVINGS_QUERY = gql`
  query savingsQuery {
    metrics_savings {
      result
    }
    metrics_savings_percentage {
      result
    }
  }
`;

export interface SavingsQueryResponse {
  metrics_savings: {
    result: number;
  }[];
  metrics_savings_percentage: {
    result: number;
  }[];
}
