import { Box } from '@mui/material';
import { Field, FormatType } from '@flywl/core';

export const TablePoint = ({
  name,
  value,
  format,
}: {
  name: string;
  value: string | number;
  format?: FormatType;
}) => {
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      gap={'0.25rem'}
      width={'50%'}
    >
      <Field.Label name={name} variant="h6" />
      <Field.Value value={value} format={format} variant="h4" />
    </Box>
  );
};
