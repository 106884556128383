import React from 'react';
import { Box, Typography } from '@mui/material';
import MetricsDetailItem from './MetricsDetailItem';
import { getPlatformLogo } from './utils/MetricsFuction';

interface MarketplaceBlockProps {
  platform: string;
  totalDrawdown: string;
  drawdownOutrage: string;
  migratableSpend: string;
  productsMoved: number | string;
  loading: boolean;
}

export const MetricsEnterpriseCommitment: React.FC<MarketplaceBlockProps> = ({
  platform,
  totalDrawdown,
  drawdownOutrage,
  migratableSpend,
  productsMoved,
  loading,
}) => {
  const logo = getPlatformLogo(platform);

  return (
    <Box sx={{ padding: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
        {logo}
        <Typography variant="h6" sx={{ marginLeft: 1 }}>
          {platform} Marketplace
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <MetricsDetailItem
          name="total_drawdown_amount"
          value={totalDrawdown}
          loading={loading}
          format="currency"
        />
        <MetricsDetailItem
          name="migratable_spend"
          value={migratableSpend}
          loading={loading}
          format="currency"
        />
        <MetricsDetailItem
          name="number_of_products_moved"
          value={productsMoved}
          loading={loading}
          format="int"
        />
        <MetricsDetailItem
          name="drawdown_overage"
          value={drawdownOutrage}
          loading={loading}
          format="currency"
        />
      </Box>
    </Box>
  );
};
