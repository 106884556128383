import AwsIcon from '../../assets/aws-logo.svg';
import AzureIcon from '../../assets/azure-logo.svg';
import GcpIcon from '../../assets/gcp-logo.svg';
import { Base, EnterpriseCommitBaseProps } from './EnterpriseCommitBase';

type data = {
  commitmentValue: number;
  nativeSpend: number;
  percentageDiscount: number;
  percentageDrawdown: number;
  startDate: Date | null;
  endDate: Date | null;
  included: boolean;
};

export interface CommitmentData {
  isValid: boolean;
  data: data;
  providerId:string;
}

type EnterpriseCommitProps = Omit<
  EnterpriseCommitBaseProps,
  'providerLogo' | 'providerName'
>;

const EnterpriseCommitComponent = {
  Base,
  AWS: function AWS(props: EnterpriseCommitProps) {
    return <Base {...props} providerLogo={AwsIcon} providerName="AWS" />;
  },
  GCP: function GCP(props: EnterpriseCommitProps) {
    return <Base {...props} providerLogo={GcpIcon} providerName="GCP" />;
  },
  Azure: function Azure(props: EnterpriseCommitProps) {
    return <Base {...props} providerLogo={AzureIcon} providerName="AZURE" />;
  },
} as const;

export { EnterpriseCommitComponent as EnterpriseCommit };
