import { useQuery, useMutation } from '@apollo/client';
import { CommitmentData } from '@flywl/blocks';
import {
  INSERT_EDP_COMMITMENT,
  GET_PROVIDERS,
  ProvidersData,
  REFETCH_ON_INV_ASSIGN_OR_EDP_CHANGE,
} from '@flywl/data-acess/graphql';

import { prepareCommitmentData } from './utils';

export const useSaveAllCommitments = () => {
  const { data: providersData } = useQuery<ProvidersData>(GET_PROVIDERS);

  const [saveCommitment, { loading }] = useMutation(INSERT_EDP_COMMITMENT);

  const saveAllCommitments = async (
    awsData: CommitmentData,
    gcpData: CommitmentData,
    azureData: CommitmentData
  ) => {
    try {
      if (!providersData?.provider) {
        throw new Error('Could not find providers');
      }

      // Find providers
      const awsProvider = providersData.provider.find(
        (p) => p.name.toLowerCase() === 'aws'
      );
      const gcpProvider = providersData.provider.find(
        (p) => p.name.toLowerCase() === 'gcp'
      );
      const azureProvider = providersData.provider.find(
        (p) => p.name.toLowerCase() === 'azure'
      );

      // Check if  ALL providers are found
      if (!awsProvider || !gcpProvider || !azureProvider) {
        throw new Error('One or more providers not found');
      }

      const savePromises = [];

      if (awsData.data.included) {
        const preparedData = prepareCommitmentData(awsData, awsProvider.id);
        if (preparedData) {
          savePromises.push(
            saveCommitment({
              variables: preparedData,
              refetchQueries: REFETCH_ON_INV_ASSIGN_OR_EDP_CHANGE
            })
          );
        }
      }

      if (gcpData.data.included) {
        const preparedData = prepareCommitmentData(gcpData, gcpProvider.id);
        if (preparedData) {
          savePromises.push(
            saveCommitment({
              variables: preparedData,
              refetchQueries: REFETCH_ON_INV_ASSIGN_OR_EDP_CHANGE
            })
          );
        }
      }

      if (azureData.data.included) {
        const preparedData = prepareCommitmentData(azureData, azureProvider.id);
        if (preparedData) {
          savePromises.push(
            saveCommitment({
              variables: preparedData,
              refetchQueries: REFETCH_ON_INV_ASSIGN_OR_EDP_CHANGE
            })
          );
        }
      }

      const results = await Promise.all(savePromises);
      return {
        success: true,
        results,
      };
    } catch (error) {
      console.error('Error saving commitment:', error);
      throw error;
    }
  };

  return {
    saveAllCommitments,
    loading,
  };
};
