import { signOut } from 'aws-amplify/auth';
import { redirect } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { Box, CircularProgress, Typography } from '@mui/material';
import { getSession, useAuthUsersQuery } from '@flywl/provider';

const MY_QUERY = gql`
  query MyQuery {
    auth_users {
      company
      email
      family_name
      given_name
    }
  }
`;

interface auth_users {
  company: string;
  email: string;
  family_name: string;
  given_name: string;
}

interface auth_users_result {
  auth_users: auth_users[];
}

export const useInventoryLineItems = () => {
  const { loading, error, data } = useQuery<auth_users_result>(MY_QUERY);
  const { tokens } = getSession();

  console.log(tokens);

  return {
    loading,
    error,
    authUsers: data?.auth_users || [],
  };
};

const LoginSuccessfully = () => {
  const { authUsers, loading, error } = useInventoryLineItems();
  const { client } = useAuthUsersQuery();

  const handleOnClick = async () => {
    await signOut().then(() => {
      client.resetStore();
      redirect('/');
    });
  };

  if (loading) return <CircularProgress />;
  if (error) {
    console.log('The eror is', error);

    return (
      <div>
        <button onClick={handleOnClick}>Log out</button>
        <div>Error</div>
      </div>
    );
  }

  return (
    <>
      <button onClick={handleOnClick}>Log out</button>
      <Box display={'flex'} flexDirection={'column'} gap={'1rem'}>
        {authUsers.map((user) => (
          <Typography key={user.email}>
            You name is {user.given_name} {user.family_name} and your email is{' '}
            {user.email}
          </Typography>
        ))}
      </Box>
    </>
  );
};

export default LoginSuccessfully;
