import { Accordion, Card } from '@flywl/core';
import { BeforeAndAfterTable } from './BeforeAndAfterTable';
import { ECTable } from './ECTable';
import { accordionStyle } from '../constant/styles';
import { ISVTable } from './ISVTable';
import { Typography, Box } from '@mui/material';
import { getPlatformLogo } from '@flywl/blocks';

export const MarkteplaceCard = ({
  marketplace,
}: {
  marketplace: 'AWS' | 'GCP' | 'AZURE';
}) => {
  const logo = getPlatformLogo(marketplace.toLowerCase());

  return (
    <Card
      sx={{
        borderColor: '#D7DED7',
        padding: '24px',
        display: 'flex',
        flexDirection: 'column',
        gap: '1.25rem',
        borderRadius: '12px',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
        {logo}
        <Typography variant="h4" sx={{ marginLeft: 1 }}>
          {marketplace}
        </Typography>
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 600,
            marginLeft: 1,
            color: '#2C292E99',
          }}
        >
          Marketplace
        </Typography>
      </Box>
      <Accordion
        accordionProps={accordionStyle}
        items={[
          {
            id: `${marketplace}-before-after`,
            summary: 'Before and after',
            details: <BeforeAndAfterTable provider={marketplace} />,
          },
          {
            id: `${marketplace}-enterprise-commitment`,
            summary: 'Enterprise Commitment',
            details: <ECTable provider={marketplace} />,
          },
          {
            id: `${marketplace}-table`,
            summary: 'ISV through the marketplace',
            details: <ISVTable provider={marketplace} />,
          },
        ]}
      />
    </Card>
  );
};
