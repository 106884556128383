import { useEffect, useState } from 'react';
import { AuthCard } from '@flywl/blocks';
import { Button, TermsContent } from '@flywl/core';
import { userStatusCopy } from '@flywl/copy';
import { useNavigate, redirect } from 'react-router-dom';
import { useAuth, useAuthUsersQuery } from '@flywl/provider';
import { signOut } from 'aws-amplify/auth';
import { Box } from '@mui/material';

type UserStatus = 'onHold' | 'notApproved';

export const UserStatusContainer = () => {
  const { data, client, loading } = useAuthUsersQuery();
  const [currentStatus, setCurrentStatus] = useState<UserStatus | ''>('');
  const userStatus = data?.auth_users[0]?.status;
  const userNameFromHasura = data?.auth_users[0]?.given_name;
  const [attributesUserName, setAttributesUserName] = useState<
    string | undefined
  >('');
  const userName =
    userNameFromHasura === 'default_given_name'
      ? attributesUserName
      : userNameFromHasura;
  const navigate = useNavigate();
  const { fetchUserAttributes } = useAuth();

  const content = currentStatus ? userStatusCopy[currentStatus] : undefined;

  const handleOnClick = async () => {
    await signOut().then(() => {
      client.resetStore();
      redirect('/');
    });
  };

  useEffect(() => {
    if (userStatus === 1) {
      navigate('/compass/data-uploading');
    } else if (userStatus === 0) {
      setCurrentStatus('onHold');
    } else if (userStatus === 2) {
      setCurrentStatus('notApproved');
    }
  }, [userStatus, navigate]);

  useEffect(() => {
    const fetchUserInfo = async () => {
      const attributes = await fetchUserAttributes();
      setAttributesUserName(attributes?.name);
    };
    fetchUserInfo();
  }, [fetchUserAttributes]);

  return (
    <Box sx={{ height: '100vw', backgroundColor: '#FAFAFA' }}>
      {currentStatus && (
        <AuthCard
          title={content?.title || ''}
          titleVariant={'h2'}
          subtitle={
            currentStatus === 'onHold' ? `${loading || userName},` : `Hi ${loading || userName},`
          }
          subtitleVariant={'subtitle2'}
          showBackButton={false}
          centerAllTheContent={currentStatus === 'notApproved'}
        >
          <TermsContent content={content?.content || []} />
          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              handleOnClick();
            }}
            sx={{ mt: 5, bgcolor: '#963F2B', color: '#F6FAF6' }}
          >
            Go back to our website
          </Button>
        </AuthCard>
      )}
    </Box>
  );
};
