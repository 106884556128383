export const cardStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  width: '100%',
  padding: '1.5rem',
  gap: '1.5rem',
  margin: 2,
  borderColor: '#D7DED7',
};

export const boxStyle = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '0.1rem',
};
