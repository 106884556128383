import { EnterpriseCommitmentData } from '@flywl/data-acess/graphql';

export const generateMetricsEnterpriseCommitment = (
  data: EnterpriseCommitmentData
) =>
  ['AWS', 'GCP', 'AZURE'].map((provider) => {
    const availableDrawdown = data?.metrics_available_drawdown_by_provider.find(
      (item) => item.name === provider
    )?.available_drawdown;
    const drawdownOutrage = data?.metrics_drawdown_overage.find(
      (item) => item.provider_name === provider
    )?.result;
    const migratableSpend =
      data?.metrics_cost_isv_bought_through_the_cmp_after_by_provider.find(
        (item) => item.provider_name === provider
      )?.total_cost;
    const numberOfProductsMoved =
      data?.metrics_cost_isv_bought_through_the_cmp_after_by_provider.find(
        (item) => item.provider_name === provider
      )?.number_of_items;

    return {
      platform: provider,
      totalDrawdown: availableDrawdown ? availableDrawdown : '-',
      drawdownOutrage: drawdownOutrage ? drawdownOutrage : '-',
      migratableSpend: migratableSpend ? migratableSpend : '-',
      productsMoved: numberOfProductsMoved ? numberOfProductsMoved : '-',
    };
  });
