import { Box, Typography } from '@mui/material';
import React from 'react';
import { Field } from '@flywl/core';

type TitleSectionType = {
  name?: string;
  logo: string;
};

export const TitleSection: React.FC<TitleSectionType> = ({ name, logo }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      marginBottom: '12px',
      gap: '8px',
    }}
  >
    <img src={logo} alt="Cloud Provider Logo" width={'48px'} height={'48px'} />
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Field.Label name={name || ''} variant="h3" spaceBetween />
      <Typography variant="subtitle1" color="#2C292E99">
        Marketplace
      </Typography>
    </Box>
  </Box>
);
