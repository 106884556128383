import React from 'react';
import { AuthCard } from '@flywl/blocks';
import { Button } from '@flywl/core';
import { useNavigate, useLocation } from 'react-router-dom';
import { Typography } from '@mui/material';

const ForgotPasswordSent: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Extract email from navigation state
  const email = (location.state as { email: string })?.email || '';

  const handleBackToLogin = () => {
    navigate('/sign-in'); // Ensure this route points to your login page
  };

  const handleResendCode = () => {
    // Navigate to RequestPasswordReset, passing the email
    navigate('/request-password-reset', { state: { email } });
  };

  return (
    <AuthCard
      title="Reset Your Password"
      subtitle={`We sent an email to ${email} to reset your password.`}
      pharagraph="Didn’t receive the email?"
      showBackButton={false}
      onBackClick={handleBackToLogin}
      titleVariant="h2"
      header="If you didn't receive the email, you can request a new one."
      backButton={true} // Ensure the back button is enabled
    >
      <Typography variant="body1" sx={{ mb: 2 }}>
        {email}
      </Typography>
      <Button
        fullWidth
        variant="contained"
        onClick={handleBackToLogin}
        sx={{ mt: 2, mb: 2, bgcolor: '#963F2B', color: '#F6FAF6' }}
      >
        Back to Login
      </Button>
      <Button
        fullWidth
        variant="text"
        onClick={handleResendCode}
        sx={{ mt: 2, color: '#963F2B' }}
      >
        Resend Code
      </Button>
    </AuthCard>
  );
};

export default ForgotPasswordSent;
