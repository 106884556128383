import { UploadCard } from '@flywl/blocks';
import { ComingSoonIntegrations } from '@flywl/blocks';
import { ComingSoonBilling } from '@flywl/blocks';
import { InventoryUpload } from '@flywl/modules';
import { Box, Container, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const UploadFile: React.FC = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const onComplete = () => {
    navigate('/compass/matching-results');
  };

  return (
    <Container
      sx={{
        paddingY: '24px',
        maxWidth: '1080px',
        width: '100%',
      }}
      maxWidth={false}
    >
      <Typography variant="h2" sx={{ marginBottom: '24px' }}>
        Data Uploading
      </Typography>
      <UploadCard handleOpen={handleOpen} />
      <Typography variant="h2" sx={{ marginBottom: '24px' }}>
        Coming soon...
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          gap: 3,
          marginBottom: 4,
        }}
      >
        <ComingSoonIntegrations />
        <ComingSoonBilling />
      </Box>
      <InventoryUpload
        open={open}
        onClose={handleClose}
        onComplete={onComplete}
        activeStep={() => console.log('activeStep')}
      />
    </Container>
  );
};
