import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  Box,
  Typography,
  Link,
  Button,
} from '@mui/material';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';

type UploadCardProps = {
  handleOpen: () => void;
};

export const UploadCard: React.FC<UploadCardProps> = ({ handleOpen }) => {
  const [downloadLink, setDownloadLink] = useState<string | null>(null);
  const [fileName, setFileName] = useState<string>('');

  useEffect(() => {
    const csvContent = `Product,Vendor,Cloud Provider,Contract Start Date,End Date,Cost\nName of product or solution,Vendor,"Hyperscaler or on-premise (AWS, GCP, Azure, ISV)",Date of contract starts,End of contract,Cost of solution\n`;
    const generatedFileName = `CompanyName_ddmmyyyy.csv`;
    setFileName(generatedFileName);
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const blobURL = window.URL.createObjectURL(blob);
    setDownloadLink(blobURL);

    return () => {
      if (blobURL) {
        window.URL.revokeObjectURL(blobURL);
      }
    };
  }, []);

  return (
    <Card
      elevation={0}
      sx={{
        backgroundColor: '#fdfefd',
        borderRadius: 2,
        border: '1px solid #EAEAEA',
        padding: 2,
        marginBottom: 4,
      }}
    >
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              color: '#2c292e',
            }}
          >
            <Typography variant="body1">
              Please upload file containing the details below:
            </Typography>

            <Box
              sx={{
                fontWeight: 700,
                fontSize: 16,
                lineHeight: '24px',
                marginTop: 2,
              }}
            >
              <Typography variant="h4">Mandatory</Typography>
              <ul style={{ padding: 0, paddingLeft: '28px', marginTop: 0 }}>
                <li>
                  <Typography variant="body1">Product</Typography>
                </li>
                <li>
                  <Typography variant="body1">Vendor</Typography>
                </li>
              </ul>
            </Box>

            <Box
              sx={{
                fontWeight: 700,
                fontSize: 16,
                lineHeight: '24px',
                marginTop: 2,
              }}
            >
              <Typography variant="h4">Optional</Typography>
              <ul style={{ padding: 0, paddingLeft: '28px', marginTop: 0 }}>
                <li>
                  <Typography variant="body1">
                    Cloud Provider (AWS, GCP, Azure, or Other)
                  </Typography>
                </li>
                <li>
                  <Typography variant="body1">
                    Contract Start Date (mm/dd/yyyy)
                  </Typography>
                </li>
                <li>
                  <Typography variant="body1">End Date (mm/dd/yyyy)</Typography>
                </li>
                <li>
                  <Typography variant="body1">Cost (USD)</Typography>
                </li>
              </ul>
            </Box>
          </Box>

          <Box
            sx={{
              width: '1px',
              backgroundColor: '#d7ded7',
              display: { xs: 'none', md: 'block' },
            }}
          />

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              marginLeft: '24px',
              flex: 1,
              gap: '12px',
            }}
          >
            <CloudUploadOutlinedIcon sx={{ fontSize: 48, color: '#963f2b' }} />
            <Typography variant="h4">Upload your CSV file</Typography>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: 16,
                lineHeight: '24px',
                marginY: 2,
              }}
            >
              You can use the file from your SAM / ERP system or{' '}
              {downloadLink && (
                <Link
                  color="secondary.main"
                  href={downloadLink}
                  download={fileName}
                >
                  Download our CSV template.
                </Link>
              )}
            </Typography>
            <Button
              onClick={handleOpen}
              variant="contained"
              sx={{
                backgroundColor: '#963f2b',
                color: '#f6faf6',
                borderRadius: '8px',
                fontSize: '16px',
                fontWeight: 400,
                padding: '8px 24px',
                width: '140px',
                textTransform: 'none',
              }}
            >
              Upload File
            </Button>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
