import React from 'react';
import Box from '@mui/material/Box';
import ForgotPasswordSent from '../../containers/ForgotPasswordSent/ForgotPasswordSent'; 

const ForgotPasswordSentPage: React.FC = () => {
  return (
    <Box
      sx={{
        minHeight: '100vh',
        bgcolor: '#FAFAFA', 
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start', 
        maxWidth:'100vw'
      }}
    >
      <ForgotPasswordSent   />
    </Box>
  );
};

export default ForgotPasswordSentPage;
