const CHOP_SIZE = 20;

export function chopData<T>(data: T[]): T[][] {
  const chopedData: T[][] = [];
  let currentData: T[] = [];

  data.forEach((item) => {
    currentData.push(item);

    if (currentData.length === CHOP_SIZE) {
      chopedData.push(currentData);
      currentData = [];
    }
  });

  if (currentData.length > 0) {
    chopedData.push(currentData);
  }

  return chopedData;
}
