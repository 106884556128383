import {
    ApolloClient,
    ApolloClientOptions,
    NormalizedCacheObject,
} from '@apollo/client';

import { createLinks, LinkOptions } from './links';
import { getApolloCache } from './cache';

const isServer = typeof window === "undefined";


type ClientOverrides = Partial<ApolloClientOptions<NormalizedCacheObject>>;

export type GraphqlProviderProps = {
    cacheState?: NormalizedCacheObject;
    linkOptions: LinkOptions;
};

export function getGraphqlClient({
    cacheState = {},
    linkOptions,
    ...overrides
}: GraphqlProviderProps & ClientOverrides) {
    console.log({
        config: {
            linkOptions,
            ...overrides,
        },
    });

    return new ApolloClient({
        ssrMode: isServer,
        cache: getApolloCache(cacheState),
        link: createLinks(linkOptions),
        connectToDevTools: true,
        ...(overrides || {}),
    });
}

