import React from 'react';
import Box from '@mui/material/Box';
import ForgotPasswordContainer from '../../containers/ForgotPasswordContainer/ForgotPasswordContainer'; 

const ForgotPasswordContainerPage: React.FC = () => {
  return (
    <Box
      sx={{
        minHeight: '100vh',
        bgcolor: '#FAFAFA', 
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start', 
        maxWidth:'100vw'
      }}
    >
      <ForgotPasswordContainer   />
    </Box>
  );
};

export default ForgotPasswordContainerPage;
